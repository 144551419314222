/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { IntegrationDetailsGeneralTabProps } from "./types";
import { SettingsCard, SettingsCardContent } from "features/Settings";

interface BullhornDatacenter {
  apiHost: string;
  authHost: string;
  name: string;
}

export function IntegrationDetailsGeneralTab({
  initialIntegrationValues,
  integrationState,
  setUpdatedIntegrationData,
  updatedIntegrationData,
}: IntegrationDetailsGeneralTabProps) {
  const [showSecretKey, setShowSecretKey] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const largeScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("lg");
  });

  const { config: initialValues } = initialIntegrationValues ?? {};
  const { config: authenticationState } = integrationState ?? {};

  // Bullhorn specific logic
  const handleBullhornDatacenterChange = (event: any, options: any[]) => {
    const index = Number(event.target.value);
    setUpdatedIntegrationData({
      ...updatedIntegrationData,
      config: {
        ...updatedIntegrationData.config,
        apiHost: options[index].apiHost,
        authHost: options[index].authHost,
      },
    });
  };

  const initialBullhornDatacenterValue = (options: any[]) => {
    const initialDatacenter = initialValues?.apiHost ?? "";
    return options.findIndex((option: BullhornDatacenter) => {
      return option.apiHost === initialDatacenter;
    });
  };

  const renderBullhornDatacenterOptions = (options: any[]) => {
    return options.map((option: BullhornDatacenter, i: number) => {
      return (
        <option key={option.apiHost} value={i}>
          {option.name}
        </option>
      );
    });
  };

  // Greenhouse specific logic
  const renderGreenhouseSubdomainText = () => {
    return (
      <>
        <Typography variant="body2">
          To use candidate IDs in TextUs as hyperlinks, please enter your
          Greenhouse URL subdomain. You can determine what your subdomain is by
          signing into Greenhouse and looking at your browser's address bar.
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "italic",
          }}
        >
          Example: The subdomain in https://app4.greenhouse.io/ would be{" "}
          <b>app4</b>
        </Typography>
      </>
    );
  };

  const renderGreenhouseWebhookSection = () => {
    return (
      <SettingsCardContent
        sx={{
          "&:last-child": {
            paddingY: "16px",
          },
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ paddingBottom: "24px" }}>
          Incoming webhooks
        </Typography>
        <Typography
          variant="body2"
          sx={{
            paddingBottom: "16px",
          }}
        >
          Use these credentials to keep TextUs up to date with Greenhouse.
        </Typography>
        <TextField
          key="endpointUrl"
          fullWidth
          label="Endpoint URL"
          onChange={(event) => {
            setUpdatedIntegrationData({
              ...updatedIntegrationData,
              config: {
                ...updatedIntegrationData.config,
                endpointUrl: event.target.value,
              },
            });
          }}
          sx={{
            paddingBottom: "8px",
          }}
          type="text"
          defaultValue={initialValues?.endpointUrl ?? ""}
          variant="outlined"
        />
        <Box sx={{ display: "flex", paddingBottom: "16px" }}>
          <Button
            color="primary"
            variant="text"
            sx={{
              marginLeft: "auto",
            }}
            onClick={() => {
              navigator.clipboard.writeText(
                updatedIntegrationData?.config?.endpointUrl,
              );
              enqueueSnackbar("Endpoint URL copied.", {
                variant: "info",
              });
            }}
          >
            <ContentCopy
              sx={{
                marginRight: "8px",
              }}
            />
            Copy URL
          </Button>
        </Box>
        <TextField
          key="endpointSecretKey"
          fullWidth
          label="Secret key"
          onChange={(event) => {
            setUpdatedIntegrationData({
              ...updatedIntegrationData,
              config: {
                ...updatedIntegrationData.config,
                endpointSecretKey: event.target.value,
              },
            });
          }}
          sx={{
            paddingBottom: "8px",
          }}
          type={showSecretKey ? "text" : "password"}
          defaultValue={initialValues?.endpointSecretKey ?? ""}
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowSecretKey(!showSecretKey);
                  }}
                  edge="end"
                >
                  {showSecretKey ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex" }}>
          <Button
            color="primary"
            variant="text"
            sx={{
              marginLeft: "auto",
            }}
            onClick={() => {
              navigator.clipboard.writeText(
                updatedIntegrationData?.config?.endpointSecretKey,
              );
              enqueueSnackbar("Secret key copied.", {
                variant: "info",
              });
            }}
          >
            <ContentCopy
              sx={{
                marginRight: "8px",
              }}
            />
            Copy Secret
          </Button>
        </Box>
      </SettingsCardContent>
    );
  };

  // All "custom" strategy integrations logic: this also includes Bullhorn and Greenhouse
  const renderAuthenticationFields = () => {
    return Object.entries(authenticationState).map((field) => {
      const dataKey = field[0];
      const { type, label } = field[1];
      if (type === "text") {
        return (
          <TextField
            key={dataKey}
            fullWidth
            InputProps={{
              readOnly: dataKey === "webhookSecret",
            }}
            label={label}
            onChange={(event) => {
              setUpdatedIntegrationData({
                ...updatedIntegrationData,
                config: {
                  ...updatedIntegrationData.config,
                  [dataKey]: event.target.value,
                },
              });
            }}
            sx={{
              paddingBottom: "16px",
            }}
            type="text"
            defaultValue={initialValues[dataKey]}
            variant="outlined"
          />
        );
      }
      if (type === "select") {
        const { options } = field[1];
        return (
          <TextField
            fullWidth
            key={dataKey}
            label={label}
            onChange={(event) => {
              if (
                dataKey === "datacenter" &&
                integrationState?.provider === "bullhorn"
              ) {
                handleBullhornDatacenterChange(event, options);
              }
            }}
            select
            SelectProps={{
              native: true,
              inputProps: { "aria-label": "Datacenter" },
            }}
            sx={{
              paddingBottom: "16px",
            }}
            defaultValue={
              dataKey === "datacenter" &&
              integrationState?.provider === "bullhorn"
                ? initialBullhornDatacenterValue(options)
                : undefined
            }
            variant="outlined"
          >
            <option key="placeholder" aria-label="Empty" value="" />
            {dataKey === "datacenter" &&
              integrationState?.provider === "bullhorn" &&
              renderBullhornDatacenterOptions(options)}
          </TextField>
        );
      }
      return null;
    });
  };

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: largeScreen ? "row" : "column",
        width: "100%",
      }}
    >
      {integrationState?.strategy === "custom" && (
        <>
          <Box
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              minWidth: largeScreen ? "18rem" : "0rem",
            }}
          >
            <Typography variant="h4" gutterBottom>
              Authentication
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <SettingsCard sx={{ width: "100%", minWidth: "288px" }}>
              <SettingsCardContent
                sx={{
                  "&:last-child": {
                    paddingY: "16px",
                  },
                }}
              >
                {integrationState?.provider === "greenhouse" && (
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      paddingBottom: "24px",
                    }}
                  >
                    Configuration
                  </Typography>
                )}
                {renderAuthenticationFields()}
                {integrationState?.provider === "greenhouse" &&
                  renderGreenhouseSubdomainText()}
              </SettingsCardContent>
            </SettingsCard>
            {integrationState?.provider === "greenhouse" && (
              <SettingsCard
                sx={{
                  marginBottom: "48px",
                  marginTop: "16px",
                }}
              >
                {renderGreenhouseWebhookSection()}
              </SettingsCard>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
