import { Typography, Chip, Tooltip, Grid2 as Grid } from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { styled } from "@mui/material/styles";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { PremiumFeatures } from "../models/BillingAnalyticsModels";

type Props = {
  premiumFeatures: PremiumFeatures;
};

function AnalyticsPremiumFeatures({ premiumFeatures }: Props) {
  const { automation, campaignPro, keywords, sequences } =
    premiumFeatures ?? {};

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
    return <Tooltip {...props} classes={{ popper: className }} />;
  })(({ theme }) => {
    return {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    };
  });

  return (
    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
      <Typography
        fontFamily="Helvetica Neue"
        fontSize="14px"
        fontWeight="500"
        lineHeight="168%"
        letterSpacing="0.1px"
        color="text.primary"
      >
        Contract premium features
      </Typography>
      {campaignPro ? (
        <Chip
          icon={<WorkspacePremiumIcon />}
          label="Campaigns Pro"
          color="primary"
          data-testid="campaigns-pro-active"
        />
      ) : (
        <LightTooltip title="Click to learn more">
          <Chip
            icon={<WorkspacePremiumIcon />}
            label="Campaigns Pro"
            data-testid="campaigns-pro-default"
          />
        </LightTooltip>
      )}

      {(() => {
        if (automation || (keywords && sequences)) {
          return (
            <Chip
              icon={<WorkspacePremiumIcon />}
              label="Automation"
              color="primary"
              data-testid="automation-active"
            />
          );
        }

        if (keywords && !sequences) {
          return (
            <>
              <LightTooltip title="Click to learn more">
                <Chip
                  icon={<WorkspacePremiumIcon />}
                  label="Sequences"
                  data-testid="sequences-default"
                />
              </LightTooltip>
              <Chip
                icon={<WorkspacePremiumIcon />}
                label="Keywords"
                color="primary"
                data-testid="keywords-active"
              />
            </>
          );
        }

        if (sequences && !keywords) {
          return (
            <>
              <Chip
                icon={<WorkspacePremiumIcon />}
                label="Sequences"
                color="primary"
                data-testid="sequences-active"
              />
              <LightTooltip title="Click to learn more">
                <Chip
                  icon={<WorkspacePremiumIcon />}
                  label="Keywords"
                  data-testid="keywords-default"
                />
              </LightTooltip>
            </>
          );
        }

        return (
          <LightTooltip title="Click to learn more">
            <Chip
              icon={<WorkspacePremiumIcon />}
              label="Automation"
              data-testid="automation-default"
            />
          </LightTooltip>
        );
      })()}
    </Grid>
  );
}

export default AnalyticsPremiumFeatures;
