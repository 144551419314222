import { dir } from "console";
import { ReactNode } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { useMediaQuery, Box, Grid2 as Grid, Typography } from "@mui/material";

import { AccountPlansSettingsProps } from "../models/AccountPlanModels";
import { useAccountPlans } from "../hooks/useAccountPlans";
import { HeaderText } from "../styles/styles";
import { selectAccountPlans } from "features/EntryPoint/containers/App/selectors";

function AccountPlansSettings({
  accountPlans = [],
}: AccountPlansSettingsProps) {
  const { formattedAccountPlans } = useAccountPlans({ accountPlans });

  const smScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const mdScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("md");
  });

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box mb={4}>
        <Typography align="center" color="textPrimary" variant="h4">
          Licenses
        </Typography>
        <HeaderText align="center" color="textPrimary">
          Assign licenses to your messaging accounts.
        </HeaderText>
      </Box>
      <Box
        aria-label="Account Plans"
        sx={() => {
          return {
            display: "flex",
            flexDirection: mdScreen ? "column" : "row",
            justifyContent: "space-between",
            gap: "32px",
          };
        }}
      >
        {formattedAccountPlans.map((accountPlan: ReactNode) => {
          return accountPlan;
        })}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: never, props: any) => {
  return {
    accountPlans: selectAccountPlans(state),
  };
};

const withConnect = connect(() => {
  return mapStateToProps;
}, {});

export default compose(withConnect)(AccountPlansSettings);
