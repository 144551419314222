/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { IntegrationCard } from "../IntegrationCard";
import { getConnectedIntegrations } from "../../api/getConnectedIntegrations";
import { updateIntegration } from "../../api/updateIntegration";
import { ConnectedIntegrationsProps } from "./types";
import { IntegrationProvider } from "models/IntegrationProvider";

export function ConnectedIntegrations({
  allIntegrations,
  connectedIntegrations,
  currentAccount,
  selectedIntegration,
  setConnectedIntegrations,
  setSelectedIntegration,
}: ConnectedIntegrationsProps) {
  const { enqueueSnackbar } = useSnackbar();

  const appendFormattedNameAndStrategy = (data: IntegrationProvider[]) => {
    const integrationsToAppend = data;
    integrationsToAppend.forEach((integration) => {
      // eslint-disable-next-line unicorn/no-array-reduce
      const indices = allIntegrations.reduce(
        (acc: any[], allIntegration: IntegrationProvider, index: number) => {
          if (allIntegration.provider === integration.provider) {
            acc.push(index);
          }
          return acc;
        },
        [],
      );
      /* 
      Appends providerName and strategy to merge integrations that belong to more 
      than one categroy (ATS, CRM, HRIS). Since they belong to multiple categroies,
      there are multiple elements with the same `provider` porpoerty w/in the `allIntegrations` array.
      An example is SAP Successfactors.
      */
      if (indices.length > 1) {
        indices.forEach((index: number) => {
          if (allIntegrations[index].category === integration.category) {
            integration.providerName = allIntegrations[index].providerName;
            integration.strategy = allIntegrations[index].strategy;
          }
        });
      }
      if (indices.length === 1 && indices[0] !== -1) {
        integration.providerName = allIntegrations[indices[0]].providerName;
        integration.strategy = allIntegrations[indices[0]].strategy;
      }
      if (integration.provider === "webhook") {
        integration.providerName = "Webhook";
        integration.strategy = "custom";
      }
    });
    setConnectedIntegrations(integrationsToAppend);
  };

  const handleGetConnectedIntegrations = async () => {
    try {
      const response = await getConnectedIntegrations(currentAccount.slug);
      if (!response.ok) {
        throw new Error("Failed to get connected integrations.");
      }
      const data = await response.json();
      if (data.members && data.members.length > 0) {
        appendFormattedNameAndStrategy(data.members);
      }
    } catch {
      enqueueSnackbar("Failed to get connected integrations.", {
        variant: "error",
      });
    }
  };

  const handleToggleIntegration = async (integration: IntegrationProvider) => {
    try {
      const response = await updateIntegration(integration?.id ?? "", {
        active: !integration.active,
      });
      if (!response.ok) {
        throw new Error("Integration update failed.");
      }
      enqueueSnackbar(
        integration.active
          ? "Integration activity paused."
          : "Integration activity resumed.",
        {
          variant: "info",
        },
      );
      handleGetConnectedIntegrations();
    } catch {
      enqueueSnackbar("Integration update failed.", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (!selectedIntegration && allIntegrations.length > 0) {
      handleGetConnectedIntegrations();
    }
  }, [allIntegrations, selectedIntegration]);

  return (
    <Box>
      <Typography variant="h4">Connected</Typography>
      {connectedIntegrations.length > 0 ? (
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "24px",
            marginBottom: "32px",
          }}
        >
          {connectedIntegrations.map((integration) => {
            return (
              <IntegrationCard
                handleToggleIntegration={handleToggleIntegration}
                integration={integration}
                key={integration.providerName}
                setSelectedIntegration={setSelectedIntegration}
              />
            );
          })}
        </Grid>
      ) : (
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            marginTop: "32px",
            marginBottom: "32px",
            textAlign: "center",
          }}
        >
          No integrations currently connected.
        </Typography>
      )}
    </Box>
  );
}
