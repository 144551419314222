import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Search from "@mui/icons-material/Search";
import {
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  styled as muiStyled,
} from "@mui/material";

import Avatar from "components/Avatar";

const SearchSection = styled.div`
  padding: 10px;
`;

const AssigneeSearch = muiStyled(TextField)({});

const StyledListItemAvatar = styled(ListItemAvatar)`
  position: relative;
`;

const StyledCheckCircle = styled(CheckCircle)`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  transform: translate(-13px);
  color: ${(props) => {
    return props.theme.colors.accent.main;
  }} !important;
`;

const StyledListItemText = styled(ListItemText)`
  span {
    font-weight: 600;
  }
`;

const HighlightedItem = muiStyled(ListItem)(({ theme }) => {
  return {
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    transition: "none",
  };
});

export default class Root extends Component {
  static propTypes = {
    userCollection: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    conversationAssignments: PropTypes.array,
    conversationAssignmentsCollectionId: PropTypes.string.isRequired,
    createConversationAssignmentRequest: PropTypes.func.isRequired,
    deleteConversationAssignmentRequest: PropTypes.func.isRequired,
    fetchConversationAssignmentsCollectionRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayedUsers: get(props, ["userCollection", "members"], []),
    };
  }

  handleChange = (event) => {
    const { userCollection } = this.props;
    const users = get(userCollection, ["members"], []);
    const displayedUsers =
      event.target.value === ""
        ? users
        : users.filter((user) => {
            const loweredUser = user.name.toLowerCase();
            const term = event.target.value.toLowerCase();
            return loweredUser.includes(term);
          });
    this.setState({ displayedUsers });
  };

  handleClick = (user) => {
    return () => {
      const {
        conversationAssignmentsCollectionId,
        conversationAssignments,
        createConversationAssignmentRequest,
        deleteConversationAssignmentRequest,
      } = this.props;
      const conversationAssignment = conversationAssignments.find((asgmnt) => {
        return asgmnt.assignee.id === user.id;
      });
      if (conversationAssignment) {
        return deleteConversationAssignmentRequest(
          conversationAssignment.id,
          null,
          {
            successCallback: this.successCallback,
          },
        );
      }
      return createConversationAssignmentRequest(
        conversationAssignmentsCollectionId,
        { assignee: user.id },
        {
          successCallback: this.successCallback,
        },
      );
    };
  };

  successCallback = () => {
    const {
      conversationAssignmentsCollectionId,
      fetchConversationAssignmentsCollectionRequest,
    } = this.props;
    fetchConversationAssignmentsCollectionRequest(
      conversationAssignmentsCollectionId,
    );
  };

  render() {
    const { conversationAssignments, currentUser } = this.props;
    const assignedUsers = conversationAssignments.map(
      (conversationAssignment) => {
        return conversationAssignment.assignee;
      },
    );
    const { displayedUsers } = this.state;
    const displayedAssignees = displayedUsers.filter((user) => {
      return assignedUsers
        .map((assignee) => {
          return assignee.id;
        })
        .includes(user.id);
    });
    const displayedOthers = displayedUsers.filter((user) => {
      return (
        !assignedUsers
          .map((assignee) => {
            return assignee.id;
          })
          .includes(user.id) && user.id !== currentUser.id
      );
    });
    const isCurrentUserAssigned = assignedUsers
      .map((assignee) => {
        return assignee.id;
      })
      .includes(currentUser.id);
    return (
      <>
        <SearchSection>
          <AssigneeSearch
            label="Select Assignees"
            type="search"
            onChange={this.handleChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </SearchSection>
        {displayedAssignees.length > 0 && (
          <>
            <List>
              {sortBy(displayedAssignees, ["name"]).map((user) => {
                return (
                  <HighlightedItem
                    data-testid="assigned-user"
                    key={user.id}
                    button
                    onClick={this.handleClick(user)}
                  >
                    <StyledListItemAvatar>
                      <>
                        <Avatar subject={user} />
                        <StyledCheckCircle />
                      </>
                    </StyledListItemAvatar>
                    <StyledListItemText
                      primary={user.name}
                      data-testid="assigned-user-name"
                    />
                  </HighlightedItem>
                );
              })}
            </List>
            <Divider />
          </>
        )}
        <List>
          {!isCurrentUserAssigned && (
            <ListItem
              key={currentUser.id}
              button
              onClick={this.handleClick(currentUser)}
            >
              <ListItemAvatar>
                <Avatar subject={currentUser} />
              </ListItemAvatar>
              <ListItemText
                primary={currentUser.name}
                data-testid="unassigned-user"
              />
            </ListItem>
          )}
          {sortBy(displayedOthers, ["name"]).map((user) => {
            return (
              <ListItemButton key={user.id} onClick={this.handleClick(user)}>
                <ListItemAvatar>
                  <Avatar subject={user} />
                </ListItemAvatar>
                <ListItemText
                  primary={user.name}
                  data-testid="unassigned-user"
                />
              </ListItemButton>
            );
          })}
        </List>
      </>
    );
  }
}
