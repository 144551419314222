import {
  Button,
  Grid2 as Grid,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Portal,
  Stack,
  FormHelperText,
  PaperProps,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import GroupAdd from "@mui/icons-material/GroupAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Add from "@mui/icons-material/Add";
import {
  useEffect,
  useMemo,
  useCallback,
  ForwardedRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Empty } from "../Empty";
import { isOdd, s } from "../../utils";
import { Tooltip } from "../Tooltip";
import type { UsersFormErrors } from "../../../../Users";
import type { Account } from "../../../types";
import type { Props, FormRef } from "./types";
import { phoneTypes } from "./constants";
import { UsersDrawer } from "features/Users";
import { Input } from "components/Input";
import { Phone } from "components/Phone";
import { useAccounts } from "features/Accounts/state/useAccounts";
import { Dialog, useDialog } from "components/Dialog";
import { Drawer, useDrawer } from "components/Drawer";
import { Select } from "components/Select";
import { createBlankRecords } from "utils/createBlankRecords";

/**
 * <Form /> component for creating accounts
 *
 * @param props - <Form /> props
 * @param ref — forwarded ref
 * @returns React element
 */
// eslint-disable-next-line no-underscore-dangle
function _Form(
  {
    currentAccount,
    values,
    setValues,
    setFieldValue,
    errors,
    serverErrors,
    isSubmitting,
  }: Props,
  ref: ForwardedRef<FormRef>,
) {
  /**
   * Confirm delete row dialog
   */
  const { ref: confirmDeleteRef, open: confirmDeleteRow } = useDialog<
    [account: Partial<Account> | undefined, index: number],
    boolean
  >();

  /**
   * Users drawer
   */
  const { ref: usersDrawerRef, open: openUsersDrawer } = useDrawer<
    [string, Partial<Account> | undefined] | undefined,
    Account["users"] | false
  >();

  /**
   * Provide handle to open users drawer
   */
  useImperativeHandle(ref, () => {
    return {
      openUsersDrawer: (
        parameters: [string, Partial<Account> | undefined] | undefined,
      ) => {
        const [id, account] = parameters ?? [];
        openUsersDrawer(parameters, (users) => {
          /**
           * Update accounts when drawer is closing
           *
           * If `users` is `false`, the user clicked cancel
           */
          if (account && users) {
            setFieldValue(`accounts[${id}].users`, users, false);
          }
        })();
      },
    };
  });

  /**
   * Search for parent accounts
   */
  const {
    accounts: parentAccounts,
    isLoading: isAccountsLoading,
    fetchNextPage,
    view,
  } = useAccounts(currentAccount, {
    type: "branch",
    items: 100,
  });

  /**
   * Set accounts helper
   * @param {Record<string, Account> | undefined} - account records
   */
  const setAccounts = useCallback(
    (accounts: typeof values.accounts) => {
      const { accounts: accountsBefore, ...rest } = values;

      setValues({ accounts, ...rest });
    },
    [setValues, values],
  );

  /**
   * Ensure we all ways have at least one row
   *
   * When accounts all accounts are deleted, wait for timeout, then generate a new blank row.
   */
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (Object.keys(values.accounts).length === 0) {
      /**
       * Create blank row after 500ms
       */
      timeout = setTimeout(() => {
        setAccounts(createBlankRecords(1));
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [setAccounts, values.accounts]);

  /**
   * Open users drawer on server error
   */
  useEffect(() => {
    const accountWithError = Object.entries(serverErrors?.accounts ?? {}).find(
      ([, account]) => {
        // using `as any` to avoid Formik type error
        return Boolean((account as any)?.users); // https://github.com/jaredpalmer/formik/issues/3355
      },
    );

    const [id] = accountWithError ?? [];

    if (id && values.accounts[id]) {
      openUsersDrawer([id, values.accounts[id]], (users) => {
        /**
         * Update accounts when drawer is closing
         *
         * If `users` is `false`, the user clicked cancel
         */
        if (values.accounts[id] && users) {
          setFieldValue(`accounts[${id}].users`, users, false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openUsersDrawer, serverErrors?.accounts, setFieldValue, usersDrawerRef]);

  /**
   * Convert empty object to undefined
   */
  useEffect(() => {
    const emptyAccounts = Object.entries(values.accounts).filter(
      ([, account]) => {
        return account !== undefined && Object.keys(account).length === 0;
      },
    );
    if (emptyAccounts.length > 0) {
      const { accounts } = values;

      emptyAccounts.forEach(([id]) => {
        accounts[id] = undefined;
      });

      setAccounts(accounts);
    }
  }, [setAccounts, values]);

  /**
   * Handle changes to accounts
   */
  const handle = useMemo(() => {
    return {
      /**
       * Create new row
       */
      createRow: () => {
        const [[key, value]] = Object.entries(createBlankRecords(1));

        const { accounts } = values;

        accounts[key] = value;

        setAccounts(accounts);
      },
      /**
       * Create function to delete row by identifier
       *
       * @param {string} id - identifier
       */
      deleteRow: (id: keyof typeof values.accounts) => {
        /**
         * Delete row
         */
        return (): void => {
          const { [id]: deleted, ...rest } = values.accounts;

          setAccounts(rest);
        };
      },
    };
  }, [setAccounts, values]);

  /**
   * Load more accounts on scroll event
   */

  const loadMoreItems: PaperProps["onScroll"] = (event) => {
    const isLastPage = view?.pageUrl === view?.lastUrl;
    if (
      Math.abs(
        event.currentTarget.scrollHeight -
          event.currentTarget.clientHeight -
          event.currentTarget.scrollTop,
      ) < 1 &&
      fetchNextPage &&
      !isLastPage
    ) {
      fetchNextPage();
    }
  };

  const parentAccountOptions = useMemo(() => {
    return [
      {
        value: currentAccount.slug,
        label: currentAccount.name,
      },
      ...Object.entries(parentAccounts ?? {}).map(
        ([__, { slug: value, name: label }]) => {
          return {
            value,
            label,
          };
        },
      ),
    ];
  }, [currentAccount, parentAccounts]);

  return (
    <>
      <Grid
        container
        display="grid"
        alignItems="center"
        gridTemplateColumns="repeat(4, 1fr) auto auto"
      >
        <Grid display="contents">
          <Grid sx={{ px: "10px", py: "12px", pl: 2 }}>
            <Typography component="h3" variant="subtitle2">
              Parent Account
              <Tooltip
                title={
                  <>
                    The non-messaging organization or subaccount your messaging
                    account belongs to.
                  </>
                }
              >
                <IconButton size="small">
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid sx={{ px: "10px", py: "12px" }}>
            <Typography component="h3" variant="subtitle2">
              Account Name
              <Tooltip
                title={
                  <>
                    Please enter an account name with less than 70 characters
                    and no special characters.
                  </>
                }
              >
                <IconButton size="small">
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid sx={{ px: "10px", py: "12px" }}>
            <Typography component="h3" variant="subtitle2">
              Phone Type
              <Tooltip
                title={
                  <>
                    If TextUs usually provides you with a number for your
                    account, please select virtual. The landline option can be
                    used if you have a VOIP or desk phone that has SMS released.
                  </>
                }
              >
                <IconButton size="small">
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid sx={{ px: "10px", py: "12px" }}>
            <Typography component="h3" variant="subtitle2">
              Phone Number
              <Tooltip
                title={
                  <>
                    If you have picked virtual, this field will only accept a 3
                    digit area code.
                  </>
                }
              >
                <IconButton size="small">
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid sx={{ px: "10px", pl: 3, pr: 1 }} gridColumn="span 2">
            <Typography component="h3" variant="subtitle2">
              Add Users
              <Tooltip
                title={
                  <>
                    Add at least one new or existing user to an account to
                    complete the creation process.
                  </>
                }
              >
                <IconButton size="small">
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
        {Object.keys(values.accounts).length > 0 ? (
          Object.entries(values.accounts).map(([id, _], index) => {
            if (isAccountsLoading) {
              return <Empty key={id} />;
            }

            return (
              <Grid
                key={id}
                display="contents"
                sx={(theme) => {
                  const backgroundColor = isOdd(index)
                    ? theme.palette.grey[100]
                    : undefined;

                  return {
                    backgroundColor,
                  };
                }}
              >
                <Grid
                  sx={{
                    backgroundColor: "inherit",
                    minHeight: "100%",
                    px: "10px",
                    py: "12px",
                    pl: 2,
                  }}
                >
                  <Select
                    MenuProps={{
                      PaperProps: {
                        onScroll: loadMoreItems,
                      },
                    }}
                    required={Object.keys(values.accounts[id] ?? {}).length > 0}
                    fullWidth
                    label="Parent Account"
                    name={`accounts[${id}].parent`}
                    size="small"
                    disabled={isSubmitting}
                    options={parentAccountOptions}
                  />
                </Grid>
                <Grid
                  sx={{
                    backgroundColor: "inherit",
                    minHeight: "100%",
                    px: "10px",
                    py: "12px",
                  }}
                >
                  <Input
                    inputProps={{
                      minLength: 3,
                      maxLength: 70,
                    }}
                    required={Object.keys(values.accounts[id] ?? {}).length > 0}
                    fullWidth
                    disabled={isSubmitting}
                    label="Account Name"
                    size="small"
                    variant="outlined"
                    name={`accounts[${id}].name`}
                    error={
                      Boolean((errors?.accounts?.[id] as any)?.name) ||
                      Boolean((serverErrors?.accounts?.[id] as any)?.name)
                    }
                  />
                  {Boolean((serverErrors?.accounts?.[id] as any)?.name) && (
                    <FormHelperText error>
                      {(serverErrors?.accounts?.[id] as any)?.name}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid
                  sx={{
                    backgroundColor: "inherit",
                    minHeight: "100%",
                    px: "10px",
                    py: "12px",
                  }}
                >
                  <Select
                    required={Object.keys(values.accounts[id] ?? {}).length > 0}
                    fullWidth
                    label="Phone Type"
                    disabled={isSubmitting}
                    name={`accounts[${id}].phoneType`}
                    size="small"
                    options={[...phoneTypes]}
                  />
                </Grid>
                <Grid
                  sx={{
                    backgroundColor: "inherit",
                    minHeight: "100%",
                    px: "10px",
                    py: "12px",
                  }}
                >
                  {values.accounts[id]?.phoneType === "virtual" ? (
                    <Input
                      disabled={isSubmitting}
                      fullWidth
                      inputProps={{
                        pattern: "^[0-9]*$",
                        maxLength: 3,
                        minLength: 3,
                      }}
                      label="Phone Number"
                      name={`accounts[${id}].phone`}
                      placeholder="555"
                      required={
                        Object.keys(values.accounts[id] ?? {}).length > 0
                      }
                      error={Boolean(
                        (serverErrors?.accounts?.[id] as any)?.phone,
                      )}
                      size="small"
                      variant="outlined"
                    />
                  ) : (
                    <Phone
                      disabled={isSubmitting}
                      fullWidth
                      label="Phone Number"
                      name={`accounts[${id}].phone`}
                      required={
                        Object.keys(values.accounts[id] ?? {}).length > 0
                      }
                      error={Boolean(
                        (serverErrors?.accounts?.[id] as any)?.phone,
                      )}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  {Boolean((serverErrors?.accounts?.[id] as any)?.phone) && (
                    <FormHelperText error>
                      {(serverErrors?.accounts?.[id] as any)?.phone}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid
                  sx={{
                    backgroundColor: "inherit",
                    minHeight: "100%",
                    px: "10px",
                    py: "12px",
                  }}
                >
                  <Button
                    aria-label="Click to add users to account"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={openUsersDrawer(
                      [id, values.accounts[id]],
                      (users) => {
                        if (users) {
                          setFieldValue(`accounts[${id}].users`, users, false);
                        }
                      },
                    )}
                  >
                    <GroupAdd />
                    <Typography
                      aria-label={`There ${
                        Object.keys(values.accounts[id]?.users ?? {})
                          ?.length === 1
                          ? "is"
                          : "are"
                      } currently ${
                        Object.keys(values.accounts[id]?.users ?? {})?.length ??
                        0
                      } account${s(
                        Object.keys(values.accounts[id]?.users ?? {})?.length ??
                          0,
                      )} associated with ${
                        values.accounts[id]?.name ?? `row ${index + 1}`
                      }`}
                    >
                      <span aria-hidden>
                        &nbsp;
                        {Object.keys(values.accounts[id]?.users ?? {})
                          ?.length ?? 0}
                      </span>
                    </Typography>
                  </Button>
                </Grid>
                <Grid
                  alignContent="center"
                  sx={{
                    backgroundColor: "inherit",
                    minHeight: "100%",
                    px: "10px",
                    py: "8px",
                    pr: 1,
                  }}
                >
                  <IconButton
                    aria-label={`Delete ${
                      values.accounts[id]?.name ?? `row ${index}`
                    }`}
                    color="primary"
                    onClick={
                      Object.keys(values.accounts[id] ?? {}).length > 0
                        ? confirmDeleteRow(
                            [values.accounts[id], index],
                            (result) => {
                              if (result) {
                                handle.deleteRow(id)();
                              }
                            },
                          )
                        : handle.deleteRow(id)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Empty />
        )}
      </Grid>
      <Grid container p={2}>
        {Object.keys(values.accounts).length >= 100 ? (
          <Tooltip
            title={
              <>
                Create up to 100 accounts at a time. If you need more than 100,
                please submit this page again with the additional accounts.
              </>
            }
          >
            <div>
              <Button disabled startIcon={<Add />}>
                Add row
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button startIcon={<Add />} onClick={handle.createRow}>
            Add row
          </Button>
        )}
      </Grid>
      <Portal>
        <Drawer<
          [string, Partial<Account> | undefined] | undefined,
          Account["users"] | false
        >
          ref={usersDrawerRef}
          anchor="right"
          sx={{
            width: "500px",
          }}
        >
          {({ close, parameters }) => {
            const [id] = parameters ?? [];
            return (
              <UsersDrawer
                serverErrors={
                  serverErrors?.accounts?.[id ?? ""] as
                    | UsersFormErrors
                    | undefined
                }
                close={close}
                parameters={parameters}
              />
            );
          }}
        </Drawer>
        <Dialog<[account: Partial<Account> | undefined, index: number], boolean>
          ref={confirmDeleteRef}
          defaultCloseValue={false}
        >
          {({ close, parameters: p }) => {
            const [account, index] = p ?? [];

            return (
              <Stack
                sx={{
                  minWidth: "400px",
                }}
              >
                <DialogTitle display="flex" gap={1} alignItems="center">
                  <ErrorOutlineIcon color="error" />
                  <Grid>Discard Account</Grid>
                </DialogTitle>
                <DialogContent>
                  <Typography>
                    <b>
                      {`Are you sure you want to discard ${
                        index !== undefined &&
                        (account?.name ?? `row ${index + 1}`)
                      }?`}
                    </b>
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={close(false)}>Cancel</Button>
                  <Button variant="contained" onClick={close(true)} autoFocus>
                    Discard
                  </Button>
                </DialogActions>
              </Stack>
            );
          }}
        </Dialog>
      </Portal>
    </>
  );
}

export const Form = forwardRef(_Form);
