import { ChangeEvent, useState, useCallback } from "react";
import { get } from "lodash";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import { formatInTimeZone } from "date-fns-tz";
import { Group } from "@tesseract/core";
import { CampaignCellProps, GroupTableProps } from "./types";
import withRecord from "higherOrderComponents/withRecord";
import Table from "components/Table";
import { NoResultsScreen } from "components/Search/NoResultsScreen";
import { TextUsTable } from "components/TextUsTable";
import { useTimeZones } from "hooks";
import { useTextUsTable } from "components/TextUsTable/useTextUsTable";
import { NoMembersScreen } from "components/BlankState/NoMembersScreen";

function GroupTable({
  groupCollection,
  groupFilter,
  setGroup,
  setGroupRecipients,
}: GroupTableProps) {
  // ==== HOOKS ==== //
  const [value, setValue] = useState("");

  const { accountTimeZone } = useTimeZones();

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  // ==== METHODS ==== //
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const id = target.value.split("-").pop();
    const selectedGroup = groupCollection.members.find((group: any) => {
      return group.id === id;
    });
    if (!selectedGroup || !id) return null;
    const { contacts, name } = selectedGroup;
    setGroupRecipients(contacts?.totalItems);
    setValue(target.value);
    return setGroup({ groupName: name, groupId: id });
  };

  const hasQuery = groupFilter?.groups.includes("?q");

  const renderCampaignCell = ({
    contacts: { totalItems },
    name,
    id,
  }: CampaignCellProps) => {
    const isGroupEmpty = totalItems === 0;

    return (
      <Box display="flex" alignItems="center" py="4px">
        <Tooltip
          title={totalItems === 0 ? "Group must have at least 1 contact" : ""}
        >
          <FormControlLabel
            value={`${name}-${id}`}
            control={<Radio color="secondary" />}
            label={
              <Typography
                variant="body2"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                maxWidth="14rem"
                sx={{
                  color: totalItems === 0 ? "text.disabled" : "text.primary",
                }}
              >
                {name}
              </Typography>
            }
            disabled={totalItems === 0}
          />
        </Tooltip>
      </Box>
    );
  };

  const renderMobileCampaignCell = ({
    contacts: { totalItems },
    createdAt,
    name,
    id,
  }: Omit<Group.Model, "slug" | "totalContacts">) => {
    const isGroupEmpty = totalItems === 0;

    const label = (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Typography
          variant="body2"
          width="80vw"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          sx={{ color: isGroupEmpty ? "text.disabled" : "text.primary" }}
        >
          {name}
        </Typography>
        <Box display="flex" width="100%">
          <Typography
            variant="caption"
            sx={{ color: isGroupEmpty ? "text.disabled" : "text.primary" }}
          >
            {totalItems} contacts
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: isGroupEmpty ? "text.disabled" : "text.primary" }}
            mx="0.25rem"
          >
            |
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: isGroupEmpty ? "text.disabled" : "text.primary" }}
          >
            {formatInTimeZone(
              new Date(createdAt),
              accountTimeZone,
              "L/d/yyyy h:mmaaa z",
            )}
          </Typography>
        </Box>
      </Box>
    );

    return (
      <Box
        display="flex"
        alignItems="center"
        py="4px"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        <Tooltip
          title={isGroupEmpty ? "Group must have at least 1 contact" : ""}
          placement="top-start"
        >
          <FormControlLabel
            value={`${name}-${id}`}
            control={<Radio color="secondary" />}
            label={label}
            disabled={isGroupEmpty}
          />
        </Tooltip>
      </Box>
    );
  };

  // ==== FIELDS ==== //
  const collaspedColumns = [
    {
      title: "Group",
      getTableCellContent: renderCampaignCell,
    },
  ];

  const columns = [
    {
      title: "Group",
      getTableCellContent: renderCampaignCell,
    },
    {
      title: "No. of contacts",
      align: "right",
      getTableCellContent: (group: any) => {
        return get(group, ["contacts", "totalItems"]);
      },
    },
    {
      title: "Created at",
      align: "right",
      getTableCellContent: (group: any) => {
        return formatInTimeZone(
          new Date(group.createdAt),
          accountTimeZone,
          "L/d/yyyy h:mmaaa z",
        );
      },
    },
  ];

  const redesignColumns: readonly GridColDef[] = [
    {
      field: "group",
      headerName: "Group",
      flex: 1,
      renderCell({ row }: GridCellParams) {
        return mobileScreen
          ? renderMobileCampaignCell({
              name: row.name,
              id: row.id,
              contacts: row.contacts,
              createdAt: row.createdAt,
            })
          : renderCampaignCell({
              contacts: row.contacts,
              name: row.name,
              id: row.id,
            });
      },
    },
    {
      field: "numContacts",
      headerName: "No. of contacts",
      flex: 1,
      align: "right",
      headerAlign: "right",
      valueGetter({ row }: GridCellParams) {
        return get(row, ["contacts", "totalItems"]);
      },
      renderCell({
        row: {
          contacts: { totalItems },
        },
      }: GridCellParams) {
        return (
          <Typography
            sx={{
              color: totalItems === 0 ? "text.disabled" : "text.primary",
            }}
          >
            {totalItems}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
      align: "right",
      headerAlign: "right",
      valueGetter({ row }: GridCellParams) {
        return formatInTimeZone(
          new Date(row.createdAt),
          accountTimeZone,
          "L/d/yyyy h:mmaaa z",
        );
      },
      renderCell({
        row: {
          contacts: { totalItems },
        },
        value: formattedDate,
      }: GridCellParams) {
        return (
          <Typography
            sx={{
              color: totalItems === 0 ? "text.disabled" : "text.primary",
            }}
          >
            {formattedDate as string}
          </Typography>
        );
      },
    },
  ];

  // ==== RENDER ==== //
  return (
    <RadioGroup
      aria-label="Group"
      name="group"
      value={value}
      onChange={handleChange}
    >
      {groupCollection.members.length === 0 ? (
        hasQuery ? (
          <NoResultsScreen source="groups" active />
        ) : (
          <NoMembersScreen source="groups" />
        )
      ) : (
        <TextUsTable
          className="group-table"
          checkboxSelection={false}
          collection={groupCollection}
          columns={redesignColumns}
          hideColumnsOnMobile={["numContacts", "createdAt"]}
          hideColumnHeaders={mobileScreen}
        />
      )}
    </RadioGroup>
  );
}

export default withRecord({
  actions: ["fetch"],
  container:
    "features/Campaigns/containers/SendCampaign/NewCampaign/components/GroupTable",
  shape: { members: [{ account: {}, user: {}, contacts: { members: [] } }] },
  type: "groupCollection",
})(GroupTable);
