import { useState } from "react";
import { GridRowId, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { KeywordResponse } from "models/Keyword";

const useGridActionToolbar = () => {
  const [allSelected, setAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

  const selectedCount = selectedRows.length;

  const handleRowSelection = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedRows(rowSelectionModel);
  };

  return {
    allSelected,
    selectedRows,
    selectedCount,
    handleRowSelection,
  };
};

export { useGridActionToolbar };
