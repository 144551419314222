/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getMergeLinkToken } from "../../api/getMergeLinkToken";
import { authorizeIntegration } from "../../api/authorizeIntegration";
import { ConnectMergeIntegrationButtonProps } from "./types";
import { useCurrentAccount } from "hooks";

export function ConnectMergeIntegrationButton({
  integration,
  setSelectedIntegration,
}: ConnectMergeIntegrationButtonProps) {
  const [linkToken, setLinkToken] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();
  const currentAccount = useCurrentAccount();

  const handleGetMergeLinkToken = async () => {
    try {
      const response = await getMergeLinkToken(
        currentAccount.slug,
        integration.provider,
        integration?.category ?? "",
      );
      if (!response.ok) {
        throw new Error("Cannot fetch merge link token");
      }
      const data = await response.json();
      setLinkToken(data.link_token);
    } catch {
      enqueueSnackbar("Error fetching auth token.", {
        variant: "error",
      });
    }
  };

  const handleAuthorizeIntegration = async (publicToken: string) => {
    try {
      const response = await authorizeIntegration(currentAccount.slug, {
        provider: integration.provider,
        active: true,
        config: { publicToken },
      });
      const data = await response.json();
      // Append providerName and strategy to the data object for consistency.
      const formattedData = {
        ...data,
        providerName: integration?.providerName,
        strategy: integration?.strategy,
      };
      setSelectedIntegration(formattedData);
      if (!response.ok) {
        throw new Error("Cannot authorize integration");
      }
      enqueueSnackbar("Integration authorized successfully", {
        variant: "info",
      });
    } catch {
      enqueueSnackbar("Error fetching auth token.", {
        variant: "error",
      });
    }
  };

  const onSuccess = useCallback((public_token: string) => {
    handleAuthorizeIntegration(public_token);
  }, []);

  const { open, isReady, error } = useMergeLink({
    linkToken,
    onSuccess,
  });

  useEffect(() => {
    if (linkToken && isReady) {
      open();
    }
  }, [linkToken, isReady]);

  return (
    <Button
      onClick={() => {
        setSelectedIntegration(integration);
        handleGetMergeLinkToken();
      }}
      sx={{ color: "primary", paddingX: "16px", paddingY: "6px" }}
      variant="outlined"
    >
      {" "}
      CONNECT{" "}
    </Button>
  );
}
