import { ReactNode } from "react";

import Typography from "@mui/material/Typography";

import { AccountPlan, AccountPlanCards } from "../models/AccountPlanModels";
import {
  HeaderText,
  LinkText,
  StyledCard,
  SubHeaderText,
} from "../styles/styles";

function HeroText({ children }: { children: ReactNode }) {
  return (
    <SubHeaderText
      sx={(theme) => {
        return {
          color: theme.palette.primary.light,
          fontSize: theme.typography.h3.fontSize,
          fontWeight: "normal",
          padding: ".5rem",
        };
      }}
    >
      {children}
    </SubHeaderText>
  );
}

const getAccountPlanValues = (accountPlan: AccountPlan): ReactNode => {
  const { assignments, shared, type, quantity } = accountPlan;
  const remainingLicenses = quantity - assignments.length || 0;
  const accountPlanValues: AccountPlanCards = {
    campaigns: {
      text: <HeroText>{remainingLicenses} available</HeroText>,
      title: "Campaigns Pro",
    },
    support: {
      text: (
        <Typography
          color="textPrimary"
          sx={{ fontSize: ".75rem", lineHeight: ".85rem" }}
        >
          Contact you account manager or email{" "}
          <LinkText href="mailto:support@textus.com">
            support@textus.com
          </LinkText>{" "}
          to enable
        </Typography>
      ),
      title: "Premium Support",
    },
    analytics: {
      text: <HeroText>{shared ? "Enabled" : "Not enabled"}</HeroText>,
      title: "Analytics Pro",
    },
    keywords: {
      text: <HeroText>{remainingLicenses} available</HeroText>,
      title: "Keywords",
    },
    sequences: {
      text: <HeroText>{remainingLicenses} available</HeroText>,
      title: "Sequences",
    },
  };

  const { title, text, total = quantity } = accountPlanValues[type] ?? {};

  return (
    <StyledCard
      aria-label={`${title?.toLowerCase()}-card`}
      sx={(theme) => {
        return {
          textAlign: "center",
          padding: ".5rem",
          height: "auto",
          flexGrow: 1,
        };
      }}
    >
      <HeaderText
        sx={(theme) => {
          return {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.body1,
            fontWeight: "bold",
            textTransform: "uppercase",
          };
        }}
      >
        {title}
      </HeaderText>

      {text}

      <Typography
        color="textSecondary"
        padding="0.1rem"
      >{`${assignments.length} of ${total} used`}</Typography>
    </StyledCard>
  );
};

export default getAccountPlanValues;
