/* eslint-disable react-hooks/exhaustive-deps */
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import { useEffect } from "react";
import { Attachment } from "@tesseract/core";
import DeleteModal from "../components/DeleteModal";
import KeywordModal from "../components/KeywordModal";
import KeywordTable from "../components/KeywordTable";

import { KeywordAutomationProps } from "../models/AutomationModels";

import { StyledAccordion } from "../components/styles";
import { useKeywordAutomation } from "../hooks/useKeywordAutomation";
import { SearchBox } from "components/SearchBox";
import PageContent from "components/Page/PageContent";
import PageHeader from "components/Page/PageHeader";
import { useDebouncedSearch } from "hooks";

function KeywordAutomation({
  currentAccount,
  isSinglePanel,
  keywords,
  showSidebar,
  setKeywords,
  toggleSidebar,
}: KeywordAutomationProps) {
  // ==== HOOKS ==== //
  const {
    editModal,
    expanded,
    inactiveKeywords,
    inactiveKeywordsPage,
    isSignatureActive,
    keywordsPage,
    keywordValues,
    openDeleteModal,
    openModal,
    signature,
    handleClose,
    handleCreateAutomationKeyword,
    handleDeleteAutomationKeyword,
    handleFetchAutomationKeywords,
    handleToggleModal,
    handleToggleSignature,
    handleReimplementAutomationKeyword,
    handleUpdateAutomationKeyword,
    setExpanded,
    setInactiveKeywordsPage,
    setKeywordsPage,
    toggleDeleteModal,
    toggleEditModal,
  } = useKeywordAutomation(keywords, setKeywords);

  const { searchTerm, debouncedSearchTerm, handleClear, handleSearch } =
    useDebouncedSearch();

  useEffect(() => {
    setKeywordsPage(1);
  }, [debouncedSearchTerm]);

  // ==== METHODS ==== //
  const submitKeywords = (
    {
      keyword,
      autoResponse,
      attachments,
    }: {
      keyword: string;
      autoResponse: string;
      attachments: Attachment.Model[];
    },
    actions?: any,
  ) => {
    const requestBody = {
      attachments: attachments ?? [],
      keyword,
      responseBody: isSignatureActive
        ? `${autoResponse}\n\n${signature?.content}`
        : autoResponse,
      createdBy: currentAccount.name,
    };

    if (editModal) {
      const { id: keywordId = "" } = keywordValues;
      handleUpdateAutomationKeyword(keywordId, requestBody, actions).catch(
        (error) => {
          console.error(error);
        },
      );
    } else {
      handleCreateAutomationKeyword(requestBody, actions).catch((error) => {
        console.error(error);
      });
    }
  };

  const handleDeleteKeyword = () => {
    const { id: keywordId = "" } = keywordValues;
    handleDeleteAutomationKeyword(keywordId).catch((error) => {
      console.error(error);
    });
  };

  const reimplementKeyword = ({ id: keywordId }: { id: string }) => {
    const requestBody = {
      active: true,
    };
    handleReimplementAutomationKeyword(keywordId, requestBody).catch(
      (error) => {
        console.error(error);
      },
    );
  };

  const handleExpand = () => {
    return setExpanded(!expanded);
  };

  // ==== RENDER ==== //
  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader
        aria-label="Keywords"
        title="Keywords"
        toggleSidebar={toggleSidebar}
      />
      <Box
        display="flex"
        flex="1 1 auto"
        flexDirection="column"
        m="0 auto"
        maxWidth="1280px"
        minHeight="0px"
        overflow="auto"
        padding={4}
        width="100%"
      >
        <Grid container alignItems="center" spacing={4}>
          <Grid size={{ xs: 8 }}>
            <Typography fontWeight={400}>
              Keywords allow you to automate text responses to be sent when a
              specific keyword is texted to your TextUs account. Use this page
              to create and manage keywords for your account. Any accounts under
              your account with access to Keywords will also inherit any
              keywords you create.
            </Typography>
          </Grid>
          <Grid size={{ xs: 4 }}>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip
                title={
                  (keywords?.items?.length ?? 0) >= 100
                    ? "You already have 100 keywords set up. To create a new keyword, one must be deleted."
                    : "Create a new keyword"
                }
              >
                <Button
                  data-testid="create-keyword-button"
                  size="large"
                  variant="contained"
                  onClick={handleToggleModal}
                  disabled={(keywords?.items?.length ?? 0) >= 100}
                >
                  Create New
                </Button>
              </Tooltip>
            </Box>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Box display="flex" justifyContent="flex-end">
              <Box width="35%" margin={1}>
                <SearchBox
                  value={searchTerm}
                  onClear={handleClear}
                  onChange={handleSearch}
                  placeholder="Search Keyword"
                />
              </Box>
            </Box>
            <KeywordTable
              page={keywordsPage}
              searchTerm={debouncedSearchTerm}
              setPage={setKeywordsPage}
              keywords={keywords}
              handleFetchAutomationKeywords={handleFetchAutomationKeywords}
              toggleDeleteModal={toggleDeleteModal}
              toggleEditModal={toggleEditModal}
            />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <StyledAccordion expanded={expanded} onChange={handleExpand}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand Deleted Keyword Table"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="span">Deleted Keywords</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <KeywordTable
                data-testid="discarded-keyword-table"
                discardedKeywords
                activeKeywords={keywords?.items.map(({ keyword }) => {
                  return keyword;
                })}
                page={inactiveKeywordsPage}
                setPage={setInactiveKeywordsPage}
                keywords={inactiveKeywords}
                handleFetchAutomationKeywords={handleFetchAutomationKeywords}
                reimplementKeyword={reimplementKeyword}
                toggleEditModal={toggleEditModal}
              />
            </AccordionDetails>
          </StyledAccordion>
        </Grid>

        <KeywordModal
          editModal={editModal}
          handleClose={handleClose}
          handleToggleSignature={handleToggleSignature}
          isSignatureActive={isSignatureActive}
          keywordValues={keywordValues}
          open={openModal}
          signature={signature}
          submitKeywords={submitKeywords}
        />

        <DeleteModal
          handleClose={handleClose}
          keywordValues={keywordValues}
          open={openDeleteModal}
          handleDeleteKeyword={handleDeleteKeyword}
        />
      </Box>
    </PageContent>
  );
}

export default KeywordAutomation;
