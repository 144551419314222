import { Grid2 as Grid, Skeleton } from "@mui/material";
import { Props } from "./types";

export function Empty({ height = "37.13px" }: Props) {
  return (
    <>
      <Grid
        sx={{
          backgroundColor: "inherit",
          px: "10px",
          py: "12px",
          pl: 2,
        }}
      >
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid
        sx={{
          backgroundColor: "inherit",
          px: "10px",
          py: "12px",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid
        sx={{
          backgroundColor: "inherit",
          px: "10px",
          py: "12px",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid
        sx={{
          backgroundColor: "inherit",
          px: "10px",
          py: "12px",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid
        sx={{
          backgroundColor: "inherit",
          height: "100%",
          px: "10px",
          py: "12px",
        }}
      >
        <Skeleton variant="rectangular" width={64} height={height} />
      </Grid>
      <Grid
        container
        alignContent="center"
        sx={{
          height: "100%",
          backgroundColor: "inherit",
          px: "10px",
          py: "8px",
          pr: 1,
        }}
      >
        <Skeleton variant="circular" width={40} height={40} />
      </Grid>
    </>
  );
}
