import {
  useRouteMatch,
  useLocation,
  Link as RouterLink,
} from "react-router-dom";
import { Stack, Link } from "@mui/material";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { User } from "@tesseract/core";
import MySettingsModule from "./MySettingsModule";
import { SettingsSectionList } from "./SettingsSectionList";
import { SettingsSectionWithSubSections } from "models/SettingsSection";
import { Account } from "models/Account";

export default function AccountSettingsSidebar({
  account,
  currentUser,
  sections,
}: {
  account: Account;
  currentUser: User.Raw;
  sections: SettingsSectionWithSubSections[];
}) {
  const location = useLocation();
  const activeParentRoute = location.pathname.split("/")[3];
  const subRoute = location.pathname.split("/")[4];
  const match = useRouteMatch();
  const memberships = get(currentUser, ["memberships", "members"], []);
  const navMembership = memberships.find((membership) => {
    return get(membership, ["account", "id"]) === account.id;
  });
  const membershipRole = get(navMembership, ["role"]);

  const mainSections = sections.filter((section) => {
    return !section.mySettingsModule;
  });
  const mySettingsSections = sections.filter((section) => {
    return section.mySettingsModule;
  });

  const typeOrNumber =
    account.phoneNumbers.length === 0
      ? "Non-messaging"
      : account.phoneNumbers.length > 1
        ? "Multiple Numbers"
        : account.phoneNumbers[0];

  return (
    <nav
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      aria-label="Account settings menu"
    >
      <Stack
        role="navigation"
        aria-label="Account settings menu"
        component="ul"
        sx={(theme) => {
          return {
            listStyleType: "none",
            marginBottom: "10px",
            margin: 0,
            padding: 0,
            borderBottom: `1px solid ${theme.palette.divider}`,
            overflowY: "scroll",
            flexGrow: 1,
            flexShrink: 1,
          };
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #e0e0e0",
            marginBottom: "10px",
          }}
        >
          <Link
            component={RouterLink}
            to={`${match.url}/account-details`}
            sx={(theme) => {
              return {
                alignItems: "center",
                color: theme.palette.text.primary,
                display: "flex",
                fontSize: theme.typography.body2.fontSize,
                justifyContent: "space-between",
                textDecoration: "none",
                padding: "12px 30px 12px 20px",
                width: "100%",
                "&:hover": {
                  background: theme.palette.action.hover,
                },
              };
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>Account Details</div>
              <Typography
                sx={(theme) => {
                  return {
                    color: theme.palette.text.secondary,
                    fontSize: theme.typography.body2.fontSize,
                  };
                }}
              >
                {typeOrNumber}
              </Typography>
            </div>
            <Chip size="small" label={capitalize(membershipRole)} />
          </Link>
        </div>
        <SettingsSectionList
          sections={mainSections}
          activeParentRoute={activeParentRoute}
          subRoute={subRoute}
          match={match}
        />
      </Stack>
      <MySettingsModule me={currentUser}>
        <SettingsSectionList
          sections={mySettingsSections}
          activeParentRoute={activeParentRoute}
          subRoute={subRoute}
          match={match}
          hideTitle
        />
      </MySettingsModule>
    </nav>
  );
}
