import { MouseEvent, useContext, useEffect } from "react";
import {
  Box,
  Card,
  Chip,
  Grid2 as Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { capitalize } from "lodash";
import { BlueprintsContext } from "../../context";
import { Blueprint } from "../../types";
import { BlueprintsFilters } from "../BlueprintsTemplatesFilter/types";
import { BlueprintsTemplatesCardsProps } from "./types";
import { SCHEDULE_TYPES } from "features/Sequences/screens/IndividualSequenceOverview/constants";

function BlueprintsTemplatesCards({ filters }: BlueprintsTemplatesCardsProps) {
  const { areBlueprintsLoading, blueprints, selectedCard, setSelectedCard } =
    useContext(BlueprintsContext);

  const CARD_WIDTHS = Object.freeze({
    small: "22.5rem",
    medium: "16.5rem",
    xlarge: "15rem",
  });

  const filterBlueprints = (
    allBlueprints: Blueprint[],
    selectedFilters: BlueprintsFilters,
  ) => {
    return allBlueprints.filter((blueprint) => {
      return selectedFilters.useCase === "all"
        ? blueprint.scheduleType === selectedFilters.scheduleType
        : blueprint.scheduleType === selectedFilters.scheduleType &&
            blueprint.useCase === selectedFilters.useCase;
    });
  };

  const filteredBlueprints = filterBlueprints(blueprints, filters);

  useEffect(() => {
    const match = filteredBlueprints.find((blueprint) => {
      return blueprint.id === selectedCard?.id;
    });

    if (!match && setSelectedCard) {
      setSelectedCard(filteredBlueprints[0]);
    }
  }, [filteredBlueprints, selectedCard, setSelectedCard]);

  const renderLoader = () => {
    return Array.from({ length: 8 }, (_, index) => {
      return (
        <Grid key={index}>
          <Skeleton
            variant="rectangular"
            height="8rem"
            data-testid="blueprints-loading-skeleton"
            sx={(theme) => {
              return {
                [theme.breakpoints.down("md")]: {
                  width: CARD_WIDTHS.small,
                },
                [theme.breakpoints.between("md", "xl")]: {
                  width: CARD_WIDTHS.medium,
                },
                [theme.breakpoints.up("xl")]: {
                  width: CARD_WIDTHS.xlarge,
                },
              };
            }}
          />
        </Grid>
      );
    });
  };

  const handleCardClick = (id: string) => {
    return (event: MouseEvent<HTMLElement>) => {
      if (!setSelectedCard) return undefined;
      const match = filteredBlueprints.find((card) => {
        return card.id === id;
      });
      return match ? setSelectedCard(match) : setSelectedCard(undefined);
    };
  };

  const renderCards = (cards: Blueprint[]) => {
    return cards.map(
      ({ daySpan, id, name, scheduleType, steps, stepCount, useCase }) => {
        return (
          <Grid key={id}>
            <Card
              sx={(theme) => {
                const selected = selectedCard?.id === id;
                return {
                  backgroundColor: selected ? theme.palette.selected.light : "",
                  border: selected
                    ? `2px solid ${theme.palette.primary.main}`
                    : `2px solid transparent`,
                  ":hover": {
                    backgroundColor: selected
                      ? theme.palette.selected.light
                      : theme.palette.action.hover,
                  },
                };
              }}
              id={id}
              onClick={handleCardClick(id)}
            >
              <Box
                display="flex"
                flexDirection="column"
                height="8rem"
                padding="1rem"
                sx={(theme) => {
                  return {
                    [theme.breakpoints.down("md")]: {
                      width: CARD_WIDTHS.small,
                    },
                    [theme.breakpoints.between("md", "xl")]: {
                      width: CARD_WIDTHS.medium,
                    },
                    [theme.breakpoints.up("xl")]: {
                      width: CARD_WIDTHS.xlarge,
                    },
                  };
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Chip
                    label={capitalize(useCase)}
                    variant="outlined"
                    size="small"
                    sx={(theme) => {
                      return {
                        color: theme.palette.text.primary,
                        margin: "0rem 0rem 0.25rem 0rem",
                      };
                    }}
                  />
                  <Box display="flex" height="100%" alignItems="flex-start">
                    <Typography
                      fontSize="10px"
                      fontWeight={400}
                      sx={(theme) => {
                        return {
                          color: theme.palette.text.primary,
                        };
                      }}
                    >{`${stepCount} steps ${
                      scheduleType === SCHEDULE_TYPES.relative
                        ? `| ${daySpan} days`
                        : ""
                    }`}</Typography>
                  </Box>
                </Box>
                <Box overflow="hidden">
                  <Typography variant="subtitle2" gutterBottom>
                    {name}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box", // Required for multiline ellipsis.
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {steps[0].body}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        );
      },
    );
  };

  return (
    <Box
      height={{
        xs: "21rem",
        sm: "21rem",
        md: "29rem",
        lg: "29rem",
        xl: "32.5rem",
      }}
      sx={{ overflowY: "scroll" }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-start"
        marginLeft="0rem"
        width="100%"
      >
        {areBlueprintsLoading
          ? renderLoader()
          : renderCards(filteredBlueprints)}
      </Grid>
    </Box>
  );
}

export { BlueprintsTemplatesCards };
