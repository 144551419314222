import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import { Redirect } from "react-router";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { EditIcon, SmsIcon } from "icons";
import DeliverabilityStatus from "components/DeliverabilityStatus";
import List from "components/List";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import { IntegrationIdLink } from "features/ContactSync/IntegrationIdLink/IntegrationIdLink";
import { getIntegrationDataKeys } from "features/ContactSync/utils/getIntegrationDataKeys";

const Wrapper = styled.div`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: 1rem;
`;

const CardWrapper = styled.div`
  padding: 15px 10px 15px 20px;
`;

const ContactRow = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const ContactValue = styled.div`
  flex: 1 1 auto;
  width: 85%;
`;

const ContactCreatedAt = styled.p`
  font-size: 12px;
  color: ${(props) => {
    return props.theme.colors.text.secondary;
  }};
  display: flex;
  justify-content: center;
`;

const PhoneList = styled(List)``;

const PhoneRow = styled(ContactRow)``;

const NumberContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
`;

const NumberActions = styled.div`
  flex: 0 0 auto;
`;

const PhoneNumberContent = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 10px;
`;

const PhoneNumber = styled.div`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  flex: 0 0 auto;
`;

const LogoIcon = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
`;

class ContactCard extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    conversation: PropTypes.object,
    contact: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    handleConversationClick: PropTypes.func.isRequired,
    handleEditClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      nextLocation: null,
    };
  }

  handleText = (number) => {
    return () => {
      this.props.handleConversationClick(number);
    };
  };

  handleClick = (businessName) => {
    return () => {
      const { closeModal } = this.props;
      const businessLink = businessName.includes(" ")
        ? `q=business:${fixedEncodeURIComponent(`"${businessName}"`)}`
        : `q=business:${fixedEncodeURIComponent(`${businessName}`)}`;
      this.setState({ nextLocation: businessLink });
      if (closeModal) closeModal();
    };
  };

  render() {
    const { conversation, contact, currentAccount, handleEditClick } =
      this.props;
    const businessName = get(contact, ["data", "business"]);
    const messagingEnabled = currentAccount.phoneNumbers.length > 0;
    const integrationNames = getIntegrationDataKeys(contact);
    const { nextLocation } = this.state;
    if (nextLocation)
      return (
        <Redirect
          push
          to={{
            pathname: `/${currentAccount.slug}/contacts/search`,
            search: nextLocation,
          }}
        />
      );

    return (
      <Wrapper>
        <CardWrapper>
          <ContactRow>
            <ContactValue>
              <Typography variant="h5" mb={0.5}>
                {contact.name || "Unknown Name"}
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={0.5}
              >
                {integrationNames.length > 0
                  ? integrationNames.map((integrationName) => {
                      return (
                          <IntegrationIdLink
                            key={integrationName}
                            integrationData={contact?.data[integrationName]}
                            integrationName={integrationName}
                          />
                      );
                    })
                  : null}
                {businessName ? (
                  <Typography
                    variant="caption"
                    onClick={this.handleClick(businessName)}
                    color="text.secondary"
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {businessName}
                  </Typography>
                ) : null}
              </Box>
            </ContactValue>
            {handleEditClick && (
              <Tooltip title="Edit Contact">
                <IconButton onClick={handleEditClick}>
                  <EditIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            )}
          </ContactRow>
          <PhoneList>
            {(contact.phones.members ?? [])
              .sort((phone) => {
                return phone.phoneNumber === get(conversation, ["phoneNumber"])
                  ? -1
                  : undefined;
              })
              .map((phone) => {
                return (
                  <PhoneRow key={phone.id}>
                    <NumberContent>
                      <DeliverabilityStatus
                        deliverabilityStatus={phone.deliverabilityStatus}
                      />
                      <PhoneNumberContent>
                        <PhoneNumber>{phone.formattedPhoneNumber}</PhoneNumber>
                        <Typography variant="caption" color="text.secondary">
                          {capitalize(phone.type)}
                        </Typography>
                      </PhoneNumberContent>
                    </NumberContent>
                    {messagingEnabled && (
                      <NumberActions>
                        <Tooltip title={`Text ${phone.formattedPhoneNumber}`}>
                          <IconButton
                            onClick={this.handleText(phone.phoneNumber)}
                            disabled={
                              phone.deliverabilityStatus === "unknown" ||
                              phone.deliverabilityStatus === "undeliverable"
                            }
                            size="large"
                          >
                            <SmsIcon />
                          </IconButton>
                        </Tooltip>
                      </NumberActions>
                    )}
                  </PhoneRow>
                );
              })}
          </PhoneList>
          <ContactCreatedAt>
            Added {format(new Date(contact.createdAt), "LLL do, yyyy")}
          </ContactCreatedAt>
        </CardWrapper>
      </Wrapper>
    );
  }
}

export default ContactCard;
