import { ReactNode } from "react";
import Button from "@mui/material/Button";
import Tooltip10dlcNotice from "components/Tooltip10dlcNotice";

const defaultClickHandler = () => {};

interface Props {
  clickHandler: () => void;
  className: string;
  dataTag: string;
  dataTestId: string;
  ariaLabel: string;
  disabled: boolean;
  icon: ReactNode;
  primary?: boolean;
  text: string;
  tooltipText?: string;
}

function SidebarHeaderButton({
  clickHandler = defaultClickHandler,
  dataTag,
  ariaLabel,
  dataTestId,
  disabled = false,
  icon,
  primary,
  text,
  tooltipText = "",
}: Props) {
  const variant = primary ? "contained" : "text";
  const visibility = disabled ? "visible" : "hidden";

  return (
    <Tooltip10dlcNotice text={tooltipText} visibility={visibility}>
      {/* Extra div required to get Tooltip to display on a disabled Button */}
      <div style={{ width: "100%" }}>
        <Button
          // className={className}
          color="primary"
          fullWidth
          // disabled={disabled}
          onClick={clickHandler}
          variant={variant}
          {...(dataTag && { "data-product-tour": dataTag })}
          {...(ariaLabel && { "aria-label": ariaLabel })}
          {...(dataTestId && { "data-testid": dataTestId })}
        >
          {icon}
          <span style={{ marginLeft: "10px" }}>{text}</span>
        </Button>
      </div>
    </Tooltip10dlcNotice>
  );
}

export default SidebarHeaderButton;
