import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  ListItem,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { DeleteIcon, AttachFileIcon } from "icons";
import { fetchSavedReplyCollection } from "features/SavedReplies/api";
import { useCurrentAccount, useUserData } from "hooks";
import UserAvatar from "components/Avatar";
import { Snackbar } from "components/Snackbar";
import SnackbarCloseButton from "containers/SnackbarCloseButton";

export default function Root({
  clickHandler,
  currentUser,
  disableTemplateAttachments,
  deleteSavedReplyRequest,
  savedReplyCollectionId,
  isActive,
  mobileManager = false,
  mobileSettingsManager = false,
  savedReply,
  setActiveSavedReply,
  setSavedReplies,
}) {
  const [hovering, setHovering] = useState(false);

  const currentAccount = useCurrentAccount();
  const { featureFlags } = currentAccount;
  const templatesMaintenance = featureFlags?.templatesMaintenance;
  const templateOwner = useUserData(savedReply.user);
  const { enqueueSnackbar } = useSnackbar();

  const fileName = savedReply.attachments?.members[0]?.originalFilename;
  const hasAttachment = savedReply.attachments?.members?.length > 0;
  const disableListItem =
    savedReply?.attachments?.members.length > 0 && disableTemplateAttachments;

  const mobile = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    deleteSavedReplyRequest(savedReply.id, null, {
      successCallback: async () => {
        const response = await fetchSavedReplyCollection(
          savedReplyCollectionId,
        );
        const newSavedReplyCollection = await response.json();
        setSavedReplies(newSavedReplyCollection);
        const nonAttachmentSavedReply = newSavedReplyCollection?.members?.find(
          (member) => {
            return member?.attachments?.members?.length === 0;
          },
        );
        setActiveSavedReply(
          disableTemplateAttachments
            ? nonAttachmentSavedReply
            : newSavedReplyCollection?.members[0],
        );

        enqueueSnackbar(
          templatesMaintenance
            ? "Template deleted."
            : "You successfully deleted a template.",
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            content: (key, message) => {
              return (
                <Snackbar
                  id={key}
                  message={message}
                  variant="info"
                  width="auto"
                  actionButton={
                    <SnackbarCloseButton snackbarKey={key} padding="0px" />
                  }
                />
              );
            },
          },
        );
      },
    });
  };

  const { content, title } = savedReply;

  const showDeleteIcon = mobile
    ? (mobileManager || mobileSettingsManager) && isActive
    : isActive;

  return !templatesMaintenance || templateOwner ? (
    <Tooltip
      title={
        savedReply?.attachments?.members.length > 0 &&
        disableTemplateAttachments
          ? "Unable to send campaign attachments without a Campaigns Pro license."
          : ""
      }
      placement="bottom"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -60],
              },
            },
          ],
        },
        tooltip: {
          sx: {
            fontSize: "0.70rem",
          },
        },
      }}
    >
      <span style={{ pointerEvents: "auto" }}>
        <ListItem
          disabled
          data-testid="created-template"
          className={`${isActive ? "isActive" : ""}`}
          onClick={() => {
            return clickHandler && clickHandler(savedReply);
          }}
          onMouseEnter={() => {
            return setHovering(true);
          }}
          onMouseLeave={() => {
            return setHovering(false);
          }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            pointerEvents:
              hasAttachment && disableTemplateAttachments ? "none" : "auto",
            background: (theme) => {
              return isActive && templatesMaintenance
                ? theme.palette.action.hover
                : theme.palette.background.paper;
            },
            "&:hover": {
              background: (theme) => {
                return theme.palette.action.hover;
              },
              cursor: "pointer",
              p: {
                color: (theme) => {
                  return theme.palette.text.primary;
                },
              },
            },
          }}
        >
          <Box
            sx={{ overflowWrap: "break-word", flex: "1 1 auto", maxWidth: 320 }}
          >
            <Box display="flex">
              {templatesMaintenance ? (
                <Box
                  sx={{
                    opacity: disableListItem ? 0.5 : 1,
                    filter: disableListItem ? "grayscale(100%)" : "none",
                    pointerEvents: disableListItem ? "none" : "auto",
                  }}
                >
                  <UserAvatar
                    subject={templateOwner}
                    size={32}
                    tooltip={templateOwner.name}
                  />
                </Box>
              ) : null}
              <Typography
                data-testid="template-title"
                variant="body2"
                sx={{
                  fontWeight: 700,
                  margin: "0 0 0.5rem 0",
                  color: disableListItem ? "text.disabled" : "text.primary",
                  paddingLeft: templatesMaintenance ? 1 : 0,
                  paddingTop: templatesMaintenance ? 0.5 : 0,
                }}
              >
                {title}
              </Typography>
            </Box>
            {hasAttachment && templatesMaintenance ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AttachFileIcon
                  sx={{
                    fontSize: 16,
                    color: disableListItem ? "text.disabled" : "text.secondary",
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    color: disableListItem ? "text.disabled" : "text.secondary",
                  }}
                >
                  {fileName}
                </Typography>{" "}
              </Box>
            ) : null}
            <Typography
              data-testid="template-content"
              component="p"
              variant="body2"
              sx={{
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                display: "-webkit-box",
                color: disableListItem ? "text.disabled" : "text.primary",
                overflow: "hidden",
                margin: 0,
                padding: 0,
                paddingTop: 0,
                textOverflow: "ellipsis",
              }}
            >
              {content}
            </Typography>
          </Box>
          {savedReply.user === currentUser.id && (
            <Box
              sx={{
                display: hovering || showDeleteIcon ? "flex" : "none",
                flex: "0 0 33px",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "14px",
              }}
            >
              <Tooltip title="Delete template">
                <IconButton
                  data-testid="delete-template"
                  onClick={handleDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </ListItem>
      </span>
    </Tooltip>
  ) : null;
}

Root.propTypes = {
  clickHandler: PropTypes.func, // passed
  currentUser: PropTypes.object.isRequired, // withConnect
  disableTemplateAttachments: PropTypes.bool, // passed
  deleteSavedReplyRequest: PropTypes.func.isRequired, // withRecord
  savedReplyCollectionId: PropTypes.string.isRequired, // passed
  isActive: PropTypes.bool, // passed
  mobileManager: PropTypes.bool, // passed
  mobileSettingsManager: PropTypes.bool, // passed
  savedReply: PropTypes.object.isRequired, // passed
  setActiveSavedReply: PropTypes.func.isRequired, // passed
  setSavedReplies: PropTypes.func.isRequired, // passed
};
