/* eslint-disable no-restricted-syntax */
import { Box, Typography } from "@mui/material";
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import { get } from "lodash";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { useMemo, useState, useEffect, useRef } from "react";
import { Contact } from "@tesseract/core/src/models";
import { ContactButton, MissingName } from "./Root";
import { OverflowChipsMenu } from "./OverflowChipsMenu";
import DeliverabilityStatus from "components/DeliverabilityStatus";
import { useResize } from "features/Sequences/hooks/useResize";
import { ContactSyncIcon } from "features/ContactSync/ContactSyncIcon";

interface Props {
  contactCollection: any;
  handleClick: (props: any) => any;
  selectedContacts: string[];
  selectedRecords: Contact.Raw[];
  setContactModal: (props: any) => any;
  setSelectedContacts: (props: any) => any;
  setSelectedRecords: (props: any) => any;
}

export function ContactCollectionDataGrid({
  contactCollection,
  handleClick,
  selectedContacts,
  selectedRecords,
  setContactModal,
  setSelectedContacts,
  setSelectedRecords,
}: Props) {
  // ==== HOOKS ==== //
  const history = useHistory();
  const location = useLocation();
  const prevLocationRef = useRef(location);
  const { windowWidth } = useResize();

  useEffect(() => {
    if (location.pathname !== prevLocationRef.current.pathname) {
      setSelectedContacts([]);
      setSelectedRecords([]);
    }
    prevLocationRef.current = location;
  }, [location, setSelectedContacts, setSelectedRecords]);

  const [paginationModel, setPaginationModel] = useState({
    page: Number(new URLSearchParams(window.location.search).get("page")) || 0,
    pageSize: 30,
  });

  const columnVisibilityModel = useMemo(() => {
    if (windowWidth >= 600) {
      return {
        name: true,
        primaryNumber: true,
        businessName: true,
        tags: true,
      };
    }

    return {
      name: true,
      primaryNumber: true,
      businessName: false,
      tags: false,
    };
  }, [windowWidth]);

  // ==== METHODS ==== //
  const onRowSelectionModelChange = (contactIds: GridRowSelectionModel) => {
    setSelectedContacts(contactIds);

    const contactRecords: Contact.Raw[] = [];
    const contacts = contactCollection.members;
    for (const contactId of contactIds) {
      const foundContact = contacts.find((c: any) => {
        return c.id === contactId;
      });

      // This contact must be from another page where contactCollection.members is different.
      // Get contact from selectedRecords array.
      if (foundContact === undefined) {
        const alreadySelectedContact = selectedRecords.find(
          (c: Contact.Raw) => {
            return c.id === contactId;
          },
        );

        if (alreadySelectedContact) {
          contactRecords.push(alreadySelectedContact);
        }
      } else {
        contactRecords.push(foundContact);
      }
    }

    setSelectedRecords(contactRecords);
  };

  const onPaginationModelChange = (model: GridPaginationModel) => {
    const collectionView = get(contactCollection, ["view"]);

    if (!collectionView) {
      return;
    }

    const updateUrl = (url: string): string => {
      const currentSearchParams = queryString.parse(history.location.search);

      const {
        query: { cursor },
      } = queryString.parseUrl(url);

      return queryString.stringify({
        ...currentSearchParams,
        cursor,
        page: model.page,
      });
    };

    // `collectionView.previous` will always be null in paginated search results
    // `collectionView.previous` will be available in paginated collection results
    const nextSearch =
      model.page < paginationModel.page
        ? updateUrl(collectionView.previous || collectionView.id)
        : updateUrl(collectionView.next);

    history.push({
      ...history.location,
      search: `?${nextSearch}`,
    });

    // Update pagination.
    setPaginationModel(model);
  };

  // ==== GRID DATA ==== //
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      menu: false,
      minWidth: 131,
      resizable: true,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
      ) => {
        const contact = params.value;

        return (
          <ContactButton
            onClick={() => {
              return setContactModal({ active: true, contact });
            }}
          >
            {contact.name ?? <MissingName>Unknown Name</MissingName>}
            <ContactSyncIcon contact={contact} />
          </ContactButton>
        );
      },
    },
    {
      field: "primaryNumber",
      headerName: "Primary number",
      flex: 1,
      minWidth: 131,
      resizable: true,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
      ) => {
        const contact = params.value;
        const primaryPhone = contact.phones.members[0];

        return primaryPhone ? (
          <Box width="100%">
            <Typography
              variant="body2"
              noWrap
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                return setContactModal({
                  active: true,
                  contact,
                });
              }}
            >
              {primaryPhone.formattedPhoneNumber}
            </Typography>

            {["unknown", "undeliverable"].includes(
              primaryPhone.deliverabilityStatus,
            ) && (
              <Typography variant="caption" noWrap>
                <DeliverabilityStatus
                  deliverabilityStatus={primaryPhone.deliverabilityStatus}
                  displayText
                />
              </Typography>
            )}
          </Box>
        ) : (
          ""
        );
      },
    },
    {
      field: "businessName",
      headerName: "Business name",
      flex: 1,
      minWidth: 131,
      resizable: true,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
      ) => {
        const contact = params.value;
        const business = get(contact, ["data", "business"], null);

        return (
          <ContactButton
            onClick={handleClick({
              type: "business",
              value: business,
            })}
          >
            {business}
          </ContactButton>
        );
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 1,
      minWidth: 220,
      resizable: true,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
      ) => {
        const contact = params.value;
        const tags = contact.data.tags || [];
        return <OverflowChipsMenu tags={tags} handleClick={handleClick} />;
      },
    },
  ];

  const rows =
    contactCollection.members?.map((contact: any) => {
      const { id } = contact;

      return {
        id,
        name: contact,
        primaryNumber: contact,
        businessName: contact,
        tags: contact,
      };
    }) || [];

  const initialState = {
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "name"],
    },
  };

  // ==== RENDER ==== //
  return (
    <Box
      className="contact-collection-data-grid-container"
      sx={{
        height: "100%",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <DataGridPro
        disableVirtualization
        checkboxSelection
        className="contact-collection-data-grid"
        columns={columns}
        columnBuffer={5}
        columnHeaderHeight={54}
        columnVisibilityModel={columnVisibilityModel}
        disableColumnMenu
        disableRowSelectionOnClick
        initialState={initialState}
        keepNonExistentRowsSelected
        loading={contactCollection.members === undefined}
        onPaginationModelChange={onPaginationModelChange}
        onRowSelectionModelChange={onRowSelectionModelChange}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={contactCollection.totalItems || 0}
        rows={rows}
        rowHeight={54}
        rowSelectionModel={selectedContacts}
        slotProps={{
          baseCheckbox: {
            color: "secondary",
          },
        }}
        sx={{
          borderTop: 0,
          "& .MuiDataGrid-pinnedColumnHeaders": {
            backgroundColor:
              selectedContacts.length > 0
                ? "customColors.outboundMessageColor"
                : "background.paper",
            boxShadow: "none",
          },
          "& .MuiDataGrid-pinnedColumns": {
            backgroundColor: "background.paper",
            boxShadow: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            minHeight: "48px",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "background.paper",
          },
          "& .MuiDataGrid-sortIcon": {
            fontSize: "medium",
          },
        }}
      />
    </Box>
  );
}
