import {
  Tooltip,
  CircularProgress,
  Box,
  IconButton,
  styled,
} from "@mui/material";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { useChromeExtension } from "hooks/useChromeExtension";
import { FindNumbersImportState as ImportState } from "hooks";
import { useAppContext } from "hooks/useAppContext";

const StyledIconButton = styled(IconButton)(({ theme }) => {
  return {
    height: 32,
    width: 32,
    color: "inherit",
    display: "flex",
    alignItems: "center",
    transition: "opacity 0.2s linear",
    "&:disabled": {
      color: "inherit",
      opacity: "0.75",
    },
  };
});

// ==== METHODS ==== //
const handleFindNumbers = () => {
  window.parent.postMessage({ type: "findNumbersRequested" }, "*");
};

export function FindNumbersButton() {
  // ==== HOOKS ==== //
  const { isExtension } = useChromeExtension();
  const { importState } = useAppContext().findNumbers;

  // ==== FIELDS ==== //
  const isImporting = importState === ImportState.Importing;

  const findNumbersTooltipText = isImporting
    ? "Find numbers import in progress"
    : "Find Numbers";

  // ==== RENDER ==== //
  if (!isExtension) {
    return null;
  }

  return (
    <Tooltip
      title={findNumbersTooltipText}
      data-testid="find-numbers-button-wrapper"
    >
      {isImporting ? (
        <CircularProgress
          color="secondary"
          size="1.5rem"
          sx={{ marginLeft: "0.5rem" }}
        />
      ) : (
        // Span wrapper needed for Tooltip on disabled Button.
        <span>
          <StyledIconButton
            onClick={handleFindNumbers}
            disabled={isImporting}
            size="large"
          >
            <Box display="flex" alignItems="center">
              <FindInPageIcon />
            </Box>
          </StyledIconButton>
        </span>
      )}
    </Tooltip>
  );
}
