import { supportedIntegrations } from "../constants/supportedIntegrations";
import { Contact } from "features/ContactSidebar/types";

// Used in ContactSyncHyperlink.tsx, ContactCard/index.js
export const getIntegrationDataKeys = (contact: Contact) => {
  return Object.keys(contact?.data ?? {}).filter((key) => {
    const connectedIntegration = supportedIntegrations.find((integration) => {
      return integration.provider === key;
    });
    return connectedIntegration?.provider;
  });
};
