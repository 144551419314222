/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { Dispatch, SetStateAction, useState } from "react";

import { useSnackbar } from "notistack";
import { Keyword } from "@tesseract/core";
import { Order, KeywordValues, ApiError } from "../models/AutomationModels";

import { createAutomationKeyword } from "../api/createAutomationKeyword";
import { updateAutomationKeyword } from "../api/updateAutomationKeyword";
import { deleteAutomationKeyword } from "../api/deleteAutomationKeyword";
import { fetchAutomationKeywords } from "../api/fetchAutomationKeywords";
import { useCurrentAccount, useCurrentUser } from "hooks";
import { Snackbar } from "components/Snackbar";
import { KeywordResponse } from "models/Keyword";
import { useLoading } from "hooks/useLoading";

const initialValues = {
  active: false,
  attachments: [],
  autoResponse: "",
  id: "",
  keyword: "",
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

const getComparator = <Key extends keyof Keyword.Raw>(
  order: Order,
  orderBy: Key,
): ((a: Keyword.Raw, b: Keyword.Raw) => number) => {
  return order === "desc"
    ? (a, b) => {
        return descendingComparator(a, b, orderBy);
      }
    : (a, b) => {
        return -descendingComparator(a, b, orderBy);
      };
};

const useKeywordAutomation = (
  keywords: KeywordResponse | null,
  setKeywords: Dispatch<SetStateAction<KeywordResponse | null>>,
) => {
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();
  const {
    startLoading,
    stopLoading,
    isLoading: isLoadingKeywords,
  } = useLoading();

  const { signature } = currentUser;
  const { keywordAnswers } = currentAccount.featureFlags;

  const signatureContent = signature?.content ?? "";

  const [keywordValues, setKeywordValues] =
    useState<KeywordValues>(initialValues);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [isSignatureActive, setIsSignatureActive] = useState(false);
  const [keywordsPage, setKeywordsPage] = useState<number>(1);
  // TODO: Can we delete inactiveKeywords altogether?
  const [inactiveKeywords, setInactiveKeywords] =
    useState<KeywordResponse | null>();
  const [inactiveKeywordsPage, setInactiveKeywordsPage] = useState<number>(1);

  const handleToggleModal = () => {
    return setOpenModal(!openModal);
  };

  const handleToggleDeleteModal = () => {
    return setOpenDeleteModal(!openDeleteModal);
  };

  const handleClose = () => {
    setOpenModal(false);
    setEditModal(false);
    setOpenDeleteModal(false);
    setKeywordValues(initialValues);
    setIsSignatureActive(false);
  };

  const handleToggleSignature = () => {
    return setIsSignatureActive(!isSignatureActive);
  };
  const handleFetchAutomationKeywords = async ({
    active = true,
    items = 10,
    keywordCont = "",
    mine = true,
  } = {}) => {
    try {
      // Fetch keywords.
      startLoading();
      const response = await fetchAutomationKeywords(
        currentAccount,
        `q[active]=${active}${keywordAnswers ? `&q[mine]=${mine}` : ""}&q[keywordCont]=${keywordCont}&items=${items}&page=${
          active ? keywordsPage : inactiveKeywordsPage
        }`,
      );

      if (response.ok) {
        const keywordsResponse = await response.json();
        if (active || keywordAnswers) {
          setKeywords(keywordsResponse);
        } else {
          setInactiveKeywords(keywordsResponse);
        }
      }
    } catch {
      enqueueSnackbar(
        "There was a problem fetching keywords. Please try again.",
        {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        },
      );
    } finally {
      stopLoading();
    }
  };

  const errorCallback = (errors: ApiError, actions?: any) => {
    const validationErrors =
      errors?.validationErrors ?? "Something went wrong. Please try again.";

    if (!actions) {
      enqueueSnackbar(validationErrors.name, { variant: "error" });
    }
    actions?.setSubmitting(false);
    actions?.setErrors(validationErrors);
  };

  const successCreateCallback = (response: any, actions?: any) => {
    handleClose();
    actions?.setSubmitting(false);
    handleFetchAutomationKeywords().catch((error) => {
      console.error(error);
    });
  };

  const successDeleteCallback = (response: any) => {
    handleClose();
    handleFetchAutomationKeywords().catch((error) => {
      console.error(error);
    });
    handleFetchAutomationKeywords({ active: false }).catch((error) => {
      console.error(error);
    });
  };

  const successEditCallback = (response: Keyword.Raw, actions?: any) => {
    handleClose();
    actions?.setSubmitting(false);
    const newItems =
      keywords?.items.map((keyword) => {
        if (keyword.id === response.id) {
          return response;
        }

        return keyword;
      }) ?? [];

    const newInactiveItems =
      inactiveKeywords?.items.map((keyword) => {
        if (keyword.id === response.id) {
          return response;
        }

        return keyword;
      }) ?? [];

    if (keywords) {
      setKeywords({ ...keywords, items: newItems });
    }

    if (inactiveKeywords) {
      setInactiveKeywords({
        ...inactiveKeywords,
        items: newInactiveItems,
      });
    }
  };

  const successReimplementCallback = (response: Keyword.Raw) => {
    handleFetchAutomationKeywords().catch((error) => {
      console.error(error);
    });
    handleFetchAutomationKeywords({ active: false }).catch((error) => {
      console.error(error);
    });
  };

  const toggleDeleteModal = (selectedKeyword: Keyword.Raw) => {
    const {
      active,
      id,
      keyword,
      attachments,
      responseBody: autoResponse,
    } = selectedKeyword;
    setKeywordValues({ active, autoResponse, keyword, attachments, id });
    handleToggleDeleteModal();
  };

  const toggleEditModal = (selectedKeyword: Keyword.Raw) => {
    const {
      active,
      id,
      keyword,
      attachments,
      responseBody: autoResponse,
    } = selectedKeyword;
    const autoResponseWithoutSignature = autoResponse
      .replace(signatureContent, "")
      .trim();
    const signatureActive =
      autoResponse.includes(signatureContent) && signatureContent !== "";
    setIsSignatureActive(signatureActive);
    setKeywordValues({
      active,
      autoResponse: autoResponseWithoutSignature,
      id,
      keyword,
      attachments,
    });
    setEditModal(true);
    handleToggleModal();
  };

  const handleCreateAutomationKeyword = async (body: any, actions?: any) => {
    try {
      const response = await createAutomationKeyword(currentAccount, body);
      const json = await response.json();

      // 422 error does not natively jump to catch, so let's catch it ourselves.
      if (!response.ok) {
        throw json || "";
      }

      successCreateCallback(json);

      enqueueSnackbar("Successfully created a new keyword.", {
        content: (key, message) => {
          return <Snackbar id={key} message={message} variant="success" />;
        },
      });
    } catch (error: any) {
      errorCallback(
        {
          validationErrors: {
            keyword: [...error.messages],
          },
          title: "There were errors when validating the request.",
        },
        actions,
      );

      enqueueSnackbar(
        "There was a problem creating a new keyword. Please try again.",
        {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        },
      );
    }
  };

  const handleUpdateAutomationKeyword = async (
    keywordId: string,
    body: any,
    actions?: any,
  ) => {
    try {
      const response = await updateAutomationKeyword(
        currentAccount,
        keywordId,
        body,
      );
      const json = await response.json();

      // 422 error does not natively jump to catch, so let's catch it ourselves.
      if (!response.ok) {
        throw json || "";
      }

      successEditCallback(json);

      enqueueSnackbar("Successfully updated keyword.", {
        content: (key, message) => {
          return <Snackbar id={key} message={message} variant="success" />;
        },
      });
    } catch (error: any) {
      errorCallback(
        {
          validationErrors: {
            keyword: [...error.messages],
          },
          title: "There were errors when validating the request.",
        },
        actions,
      );

      enqueueSnackbar(
        "There was a problem updating the keyword. Please try again.",
        {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        },
      );
    }
  };

  const handleReimplementAutomationKeyword = async (
    keywordId: string,
    body: any,
    actions?: any,
  ) => {
    try {
      const response = await updateAutomationKeyword(
        currentAccount,
        keywordId,
        body,
      );
      const json = await response.json();

      // 422 error does not natively jump to catch, so let's catch it ourselves.
      if (!response.ok) {
        throw json || "";
      }

      successReimplementCallback(json);

      enqueueSnackbar("Successfully reimplemented keyword.", {
        content: (key, message) => {
          return <Snackbar id={key} message={message} variant="success" />;
        },
      });
    } catch (error: any) {
      errorCallback(
        {
          validationErrors: {
            keyword: [...error.messages],
          },
          title: "There were errors when validating the request.",
        },
        actions,
      );

      enqueueSnackbar(
        "There was a problem reimplementing the keyword. Please try again.",
        {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        },
      );
    }
  };

  const handleDeleteAutomationKeyword = async (keywordId: string) => {
    try {
      const response = await deleteAutomationKeyword(currentAccount, keywordId);
      const json = await response.json();

      // 422 error does not natively jump to catch, so let's catch it ourselves.
      if (!response.ok) {
        throw json || "";
      }

      successDeleteCallback(json);

      enqueueSnackbar("Successfully deleted keyword.", {
        content: (key, message) => {
          return <Snackbar id={key} message={message} variant="success" />;
        },
      });
    } catch (error: any) {
      errorCallback({
        validationErrors: {
          keyword: [...error.messages],
        },
        title: "There were errors when validating the request.",
      });

      enqueueSnackbar(
        "There was a problem deleting the keyword. Please try again.",
        {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        },
      );
    }
  };

  return {
    editModal,
    expanded,
    inactiveKeywords,
    inactiveKeywordsPage,
    isLoadingKeywords,
    isSignatureActive,
    keywords,
    keywordsPage,
    keywordValues,
    openDeleteModal,
    openModal,
    signature,
    errorCallback,
    getComparator,
    handleClose,
    handleCreateAutomationKeyword,
    handleDeleteAutomationKeyword,
    handleFetchAutomationKeywords,
    handleReimplementAutomationKeyword,
    handleToggleDeleteModal,
    handleToggleModal,
    handleToggleSignature,
    handleUpdateAutomationKeyword,
    setEditModal,
    setExpanded,
    setInactiveKeywords,
    setInactiveKeywordsPage,
    setKeywords,
    setKeywordsPage,
    setKeywordValues,
    successCreateCallback,
    successDeleteCallback,
    successEditCallback,
    successReimplementCallback,
    toggleDeleteModal,
    toggleEditModal,
  };
};

export { useKeywordAutomation };
