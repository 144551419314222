import { Component } from "react";
import PropTypes from "prop-types";

import Root from "./components/Root";
import withRecord from "higherOrderComponents/withRecord";

class ContactFilter extends Component {
  static propTypes = {
    contactFilter: PropTypes.object.isRequired,
    contactFilterId: PropTypes.string.isRequired,
    setSavedSearchPath: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.contactFilterId !== this.props.contactFilterId) {
      this.props.setSavedSearchPath(this.props.contactFilter.path);
    }
  }

  render() {
    return <Root {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch", "subscribe", "unsubscribe", "block", "unblock", "delete"],
  container: "ContactFilter",
  shape: { contacts: {} },
  showLoader: () => {
    return false;
  },
  type: "contactFilter",
})(ContactFilter);
