import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import { useMediaQuery } from "@mui/material";

import SavedReplies from "features/SavedReplies";
import PageHeader from "components/Page/PageHeader";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import { useCurrentUser } from "hooks";
import { fetchSavedReplyCollection } from "features/SavedReplies/api";

function SavedRepliesSettings({ account, toggleSidebar }) {
  const [activeSavedReply, setActiveSavedReply] = useState(null);
  const [settingsCreateMode, setSettingsCreateMode] = useState(false);

  const user = useCurrentUser();
  const { featureFlags } = account;

  useEffect(() => {
    const getSavedReplies = async () => {
      const response = await fetchSavedReplyCollection(user.savedReplies);
      const allSavedReplies = await response.json().then((data) => {
        return data;
      });
      setActiveSavedReply(
        featureFlags.templatesMaintenance
          ? allSavedReplies.members[0]
          : undefined,
      );
      return allSavedReplies;
    };

    getSavedReplies().catch((error) => {
      throw new Error(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.savedReplies]);

  useEffect(() => {
    if (activeSavedReply) {
      setSettingsCreateMode(false);
    }
  }, [activeSavedReply]);

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  return (
    <SettingsPageWrapper>
      {mobileScreen && featureFlags?.templatesMaintenance ? null : (
        <PageHeader title="Templates" toggleSidebar={toggleSidebar}>
          <Button
            variant="outlined"
            style={{ marginRight: "1rem" }}
            onClick={() => {
              setSettingsCreateMode(true);
              return setActiveSavedReply(null);
            }}
          >
            Add New
          </Button>
        </PageHeader>
      )}

      <SavedReplies
        settings
        settingsAccount={account}
        showManager
        activeSavedReply={activeSavedReply}
        setActiveSavedReply={setActiveSavedReply}
        settingsCreateMode={settingsCreateMode}
        toggleSidebar={toggleSidebar}
      />
    </SettingsPageWrapper>
  );
}

SavedRepliesSettings.propTypes = {
  account: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default SavedRepliesSettings;
