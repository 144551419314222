import { connect } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@tesseract/theme";
import PropTypes from "prop-types";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { selectAppColors } from "../EntryPoint/containers/App/selectors";
import FrownyTexty from "utils/images/frowny-texty.svg";

import LogoGradient from "components/LogoGradient";
import H3 from "components/H3";
import H5 from "components/H5";
import Page from "features/Authentication/components/Page";
import getTheme from "utils/theme";

function LoginError(props) {
  const { appColors, loginError } = props;
  const { detail: errorDetail } = loginError;
  const muiTheme = createTheme(appColors);
  const appTheme = getTheme({ appColors, muiTheme });

  return (
    <ThemeProvider theme={muiTheme}>
      <StyledComponentsThemeProvider theme={appTheme}>
        <LogoGradient />
        <Page theme={muiTheme}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height="371.25px"
            justifyContent="space-around"
            p={5}
            textAlign="center"
            width="350px"
          >
            <img alt="Login Error" src={FrownyTexty} />
            <H3>Looks like something went wrong!</H3>
            <H5>{errorDetail}</H5>
            <Button variant="contained" color="primary" href="/">
              Try Again
            </Button>
          </Box>
        </Page>
      </StyledComponentsThemeProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state, props) => {
  return {
    appColors: selectAppColors(state, props),
  };
};

LoginError.propTypes = {
  appColors: PropTypes.object.isRequired,
  loginError: PropTypes.object.isRequired,
};

const withConnect = connect(mapStateToProps, {});

export default withConnect(LoginError);
