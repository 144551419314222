import { CHROME_EXTENSION_ID } from "constants/chromeExtensionIds";

export type ChromeWindow = Window & {
  chrome?: {
    runtime: {
      sendMessage: (
        extensionId: string,
        message: { type: string; payload?: number | string },
      ) => void;
    };
  };
};

export const useChromeExtension = (): {
  extensionID: string;
  isExtension: boolean;
  isIFrame: boolean;
  sendMessageToSidePanel: (type: string) => void;
} => {
  const isIFrame = window.top !== window.self;

  const isExtension =
    isIFrame && (window as ChromeWindow).chrome?.runtime !== undefined;

  const extensionID =
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "staging" ||
    process.env.NODE_ENV === "test"
      ? CHROME_EXTENSION_ID.development
      : CHROME_EXTENSION_ID.production;

  const sendMessageToSidePanel = (type: string, payload?: string | number) => {
    return (window as ChromeWindow).chrome?.runtime?.sendMessage(extensionID, {
      type,
      payload,
    });
  };

  return { extensionID, isIFrame, isExtension, sendMessageToSidePanel };
};
