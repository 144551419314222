/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, MouseEvent, ElementType } from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import { Box, Skeleton } from "@mui/material";
import useKeywordGroup from "../hooks/useKeywordGroup";
import { KeywordGroupProps } from "../models/AutomationModels";
import KeywordSubscribersDropdownButton from "../components/KeywordSubscribersDropdownButton";
import { SequencesLinkedChip } from "../components/SequencesLinkedChip";
import ConfirmUnlinkSequenceModal from "../components/ConfirmUnlinkSequenceModal";
import getKeywordSequenceLink from "../utils/getKeywordSequenceLink";
import Table from "components/Table";

import BatchActionToolbar from "components/BatchActionToolbar";
import BlankState from "components/BlankState";
import Logo from "components/Logo";
import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";
import { Dialog } from "components/Dialog";

import { actionGenerators as contactModalActionGenerators } from "features/ContactModal/state";
import { SequenceEnroller } from "features/Sequences/components/SequenceEnroller";
import { useSequenceEnroller } from "features/Sequences/hooks/useSequenceEnroller";
import { useCurrentAccount } from "hooks";
import {
  ConfirmEnrollmentDrawer,
  SelectSequenceDrawer,
} from "features/Sequences/components/Drawers";
import { formatSelectedRecords } from "utils/formatSelectedRecords";
import withRecord from "higherOrderComponents/withRecord";

function KeywordGroup({
  confirmUnlinkRef,
  contactCollection,
  isSinglePanel,
  links,
  keywordGroupId,
  showSidebar,
  linkKeyword,
  setContactModal,
  toggleSidebar,
  toggleUnlinkDialog,
}: KeywordGroupProps) {
  const {
    allSelected,
    campaignDisabled,
    collection,
    currentKeyword,
    displayedContacts,
    fetchNextPage,
    fetchPreviousPage,
    getAccountLicenses,
    getCollapsedColumns,
    getColumns,
    handleSelectedContacts,
    handleSendCampaignClick,
    isLoading,
    licenses,
    page,
    selectAllCount,
    selectedContacts,
    selectedCount,
    selectedRecords,
    sequenceLinkingDisabled,
    setAllSelected,
    setCollection,
    setDisplayedContacts,
    setPagination,
    setPage,
    setSelectedContacts,
    setSelectedRecords,
  } = useKeywordGroup({ contactCollection, setContactModal, keywordGroupId });

  const {
    allSequences,
    confirmEnrollmentDrawerIsOpen,
    isLoadingContacts,
    selectedSequence,
    selectSequenceDrawerIsOpen,
    sequenceSteps,
    backToSelectSequenceDrawer,
    closeConfirmEnrollmentDrawer,
    closeSelectSequenceDrawer,
    openSelectSequenceDrawer,
    selectSequence,
  } = useSequenceEnroller(contactCollection.id);

  useEffect(() => {
    setCollection(contactCollection);
  }, [contactCollection]);

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage((prevPage) => {
      if (newPage > prevPage) {
        fetchNextPage().catch((error) => {
          return console.error(error);
        });
      } else {
        fetchPreviousPage().catch((error) => {
          return console.error(error);
        });
      }
      return newPage;
    });
  };

  const currentLink = getKeywordSequenceLink(links, currentKeyword?.id ?? "");

  const groupKeyword = currentKeyword?.keyword;

  const currentAccount = useCurrentAccount();

  const handleConfirm = () => {
    if (selectedSequence) {
      linkKeyword(selectedSequence, {
        id: currentKeyword?.id ?? null,
        keyword: currentKeyword?.keyword ?? null,
      }).catch((error) => {
        return console.error(error);
      });
    }
    closeConfirmEnrollmentDrawer();
  };

  const selectedContactPhoneIds = selectedRecords.map((record) => {
    return record.phones.members[0].id.replace("/contact_phones/", "");
  });

  useEffect(() => {
    getAccountLicenses().catch((error) => {
      return console.error(error);
    });
  }, []);

  useEffect(() => {
    if (contactCollection) {
      setPagination(contactCollection.view);
    }
  }, [contactCollection?.view]);

  useEffect(() => {
    if (contactCollection?.members) {
      const formattedContacts = formatSelectedRecords(
        contactCollection.members,
      );
      setDisplayedContacts(formattedContacts);
    }
  }, [contactCollection?.members]);

  const actions = [
    {
      button: (
        <SequenceEnroller
          key="enroller"
          allSelected={allSelected}
          buttonType="icon"
          currentAccount={currentAccount}
          contactCollectionId={contactCollection.id}
          contactFilter={contactCollection.id}
          formattedSelectedRecords={formatSelectedRecords(selectedRecords)}
          setSelected={setSelectedContacts}
          setSelectedRecords={setSelectedRecords}
        />
      ),
      showExpanded: true,
    },
  ];

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader
        aria-label={`Keyword Subscribers: ${groupKeyword ?? ""}`}
        title={`Keyword Subscribers: ${groupKeyword ?? ""}`}
        toggleSidebar={toggleSidebar}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={currentLink ? "space-between" : "flex-end"}
          width="100%"
          ml="0.5rem"
        >
          {currentLink ? (
            <SequencesLinkedChip sequence={currentLink.sequence} />
          ) : null}
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width="14rem"
              height="2rem"
              sx={{ marginRight: "1rem" }}
            />
          ) : (
            <KeywordSubscribersDropdownButton
              campaignDisabled={campaignDisabled}
              currentKeyword={currentKeyword}
              handleSendCampaignClick={handleSendCampaignClick}
              currentLink={currentLink}
              licenses={licenses}
              openSelectSequenceDrawer={openSelectSequenceDrawer}
              toggleUnlinkDialog={toggleUnlinkDialog}
              sequenceLinkingDisabled={sequenceLinkingDisabled}
            />
          )}
        </Box>
      </PageHeader>
      {selectedCount > 0 && (
        <BatchActionToolbar
          // @ts-expect-error Deprecated JS component with no types
          actions={actions}
          allSelected={allSelected}
          position={{ top: "55px", left: "64px" }}
          selectedCount={selectedCount}
          selectAllCount={selectAllCount}
          setAllSelected={setAllSelected}
          showSelectAll={selectedCount > 0}
        />
      )}
      <Table
        blankState={
          <BlankState
            image={<Logo color="disabled" />}
            title="This group is empty"
            subTitle="Contact who receive auto-responses to the designated keyword will be listed here"
          />
        }
        collapsedColumns={getCollapsedColumns()}
        collection={collection ?? {}}
        columns={getColumns()}
        dataTag="Keyword-Group-Contacts"
        handleChangePage={handleChangePage}
        notExpandable
        page={page}
        selected={selectedContacts}
        selectedRecords={selectedRecords}
        setSelected={handleSelectedContacts}
        setSelectedRecords={setSelectedRecords}
        withBatchActions
      />
      <SelectSequenceDrawer
        isOpen={selectSequenceDrawerIsOpen}
        isKeywordLink
        sequences={allSequences}
        handleSelectSequence={selectSequence}
        handleClose={closeSelectSequenceDrawer}
      />
      {selectedSequence && (
        <ConfirmEnrollmentDrawer
          allContactsCount={contactCollection.totalItems}
          allSelected={!sequenceLinkingDisabled}
          contactCollectionId={contactCollection.id}
          contactFilter={contactCollection.id}
          currentAccount={currentAccount}
          displayedContacts={displayedContacts}
          fetchNextPage={fetchNextPage}
          isEmpty={contactCollection.totalItems === 0}
          isLinkingKeyword
          isLoading={isLoadingContacts}
          isOpen={confirmEnrollmentDrawerIsOpen}
          selectedContactPhoneIds={selectedContactPhoneIds}
          sequence={selectedSequence}
          sequenceStepOne={sequenceSteps[0]}
          handleBack={backToSelectSequenceDrawer}
          handleConfirm={handleConfirm}
          handleClose={closeConfirmEnrollmentDrawer}
        />
      )}
      {currentLink ? (
        <Dialog<Record<string, never>, string[] | false> ref={confirmUnlinkRef}>
          {({ close }) => {
            return (
              <ConfirmUnlinkSequenceModal
                keyword={currentKeyword?.keyword ?? ""}
                sequence={currentLink.sequence}
                close={close}
              />
            );
          }}
        </Dialog>
      ) : null}
    </PageContent>
  );
}

const mapStateToProps = (): Record<string, never> => {
  return {};
};

const withConnect = connect(mapStateToProps, {
  setContactModal: contactModalActionGenerators.setContactModal,
});

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "ui/app/features/Automation/screens/KeywordGroup",
    shape: { members: [{ phones: { members: [] } }] },

    showLoader: () => {
      return false;
    },
    type: "contactCollection",
  }),
  withConnect,
)(KeywordGroup) as ElementType;
