import { Typography } from "@mui/material";
import { User } from "@tesseract/core";
import Box from "@mui/material/Box";
import Avatar from "components/Avatar";
import fonts from "utils/theme/fonts";

export default function MySettingsModule({
  me,
  children,
}: {
  me: User.Raw;
  children: React.ReactNode;
}) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "20px",
        }}
      >
        <Avatar subject={me} size={38} />
        <div>
          <Box
            style={{
              textTransform: "uppercase",
              fontWeight: "700",
              fontSize: "small",
            }}
          >
            My Settings
          </Box>
          <Typography fontSize="small" color="text.secondary">
            {me.name}
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
}
