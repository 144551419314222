import qrcode from "qrcode";
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SimpleDialog } from "components/SimpleDialog";

import { DialogFooter } from "components/DialogFooter";

function SelectNumber({
  phoneNumbers = [],
  number,
  handler,
}: {
  phoneNumbers: string[];
  number: string | null;
  handler: (num: string | null) => void;
}) {
  return (
    <Box>
      {(phoneNumbers.length > 0 && (
        <>
          <p>Select a phone number to generate a QR code</p>

          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="phoneNumberSelect">Phone number</InputLabel>
            <Select
              labelId="phoneNumberSelect"
              id="demo-multiple-name"
              value={number}
              onChange={(event) => {
                handler(event.target.value);
              }}
              input={<OutlinedInput label="Phone Number" />}
            >
              {phoneNumbers.map((pn) => {
                return (
                  <MenuItem key={pn} value={pn}>
                    {pn}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </>
      )) || (
        <>
          <Alert severity="info" sx={{ marginBottom: "24px" }}>
            This is a non-messaging account. Please enter a phone number.
          </Alert>
          <TextField
            sx={{ width: "100%" }}
            autoFocus
            label="Phone number"
            helperText="000-000-0000"
            onChange={(event) => {
              handler(null);
              const value = event.target.value.toString().replaceAll(/\D/g, "");
              if (value.length === 10) {
                const formattedNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
                handler(formattedNumber);
              }
            }}
          />
        </>
      )}
    </Box>
  );
}

function SimpleQRModal({
  keyword,
  closeHandler,
  isOpen,
  phoneNumbers,
}: {
  keyword: string;
  closeHandler: () => void;
  isOpen: boolean;
  phoneNumbers: string[];
}) {
  const [qrImage, setQrImage] = useState<string | null>(null);
  const [inputNumber, setInputNumber] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [buttonText, setButtonText] = useState<string>("Download");

  const localCloseHandler = () => {
    closeHandler();
    setQrImage(null);
    setPhone(null);
    setInputNumber(null);
  };

  const confirmationHandler = () => {
    if (qrImage) {
      const link = document.createElement("a");
      link.href = qrImage;
      link.download = `${keyword}.png`;
      document.body.append(link);
      link.click();
      link.remove();
    } else {
      setPhone(inputNumber);
    }
  };

  useEffect(() => {
    if (phoneNumbers.length === 1) {
      return setPhone(phoneNumbers[0]);
    }

    return setPhone(phone);
  }, [phoneNumbers, phone]);

  useEffect(() => {
    if (!keyword || !phone) {
      return;
    }

    async function generateQRCode() {
      try {
        const image = await qrcode.toDataURL(`SMSTO:${phone}:${keyword}`, {
          errorCorrectionLevel: "H",
          width: 300,
        });
        setQrImage(image);
      } catch (error) {
        console.error("Failed to generate QR code", error);
      }
    }
    generateQRCode();
  }, [keyword, phone]);

  return (
    <SimpleDialog
      open={isOpen}
      title={`QR code for ${keyword}`}
      handleClose={localCloseHandler}
    >
      <DialogContent sx={{ padding: "1rem" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {qrImage && <img src={qrImage} alt={keyword} />}

          {!qrImage && (
            <SelectNumber
              number={inputNumber}
              handler={setInputNumber}
              phoneNumbers={phoneNumbers}
            />
          )}
        </Box>

        <Typography
          variant="body2"
          sx={(theme) => {
            return {
              margin: "1rem .5rem",
              color: theme.palette.text.secondary,
            };
          }}
        >
          When contacts scan this QR code, it will automatically open their
          texting application with the phone number and keyword pre-filled.
        </Typography>
      </DialogContent>
      <DialogFooter
        isConfirmDisabled={!inputNumber && !qrImage}
        confirmText={`${qrImage ? "Download" : "Generate"} QR code`}
        onConfirm={confirmationHandler}
        onCancel={localCloseHandler}
      />
    </SimpleDialog>
  );
}

export default SimpleQRModal;
