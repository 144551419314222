import ConversationImport from "../components/ConversationImport";
import ManageOptOuts from "../components/ManageOptOuts";
import { ImportRules } from "../components/ImportRules";
import { Account } from "models/Account";

import { SettingsSection } from "models/SettingsSection";

export const getContactSettings = ({
  account,
  isAdmin,
}: {
  account: Account;
  isAdmin: boolean;
}): SettingsSection => {
  const organizationAccount = account?.type === "organization";

  return {
    title: "Contact Settings",
    path: "contact",
    settings: [
      ...(account.featureFlags.contactDeduplication2
        ? ([
            {
              title: "Import Rules",
              to: `/import-rules`,
              OptionComponent: ImportRules,
            },
          ] satisfies SettingsSection["settings"])
        : []),
      ...(isAdmin
        ? ([
            {
              title: "Manage opt-outs",
              to: `/manage-opt-outs`,
              OptionComponent:
                ManageOptOuts as SettingsSection["settings"][0]["OptionComponent"],
            },
          ] satisfies SettingsSection["settings"])
        : []),
      ...(account.phoneNumbers.length > 0 && !organizationAccount
        ? ([
            {
              title: "Conversation Import",
              to: `/conversation-import`,
              OptionComponent: ConversationImport,
            },
          ] satisfies SettingsSection["settings"])
        : []),
    ],
  };
};
