import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { messageSettings, messageTypes } from "../constants";
import {
  AppendedMessageCardProps,
  IntroAndAppendedFormValues,
  MessageDirection,
} from "../types";
import { useMessageSettingsCard } from "../hooks";
import MessageInputFormRow from "components/MessageInputFormRow";
import {
  SettingsCard,
  SettingsCardActions,
  SettingsCardAlert,
  SettingsCardContent,
  SettingsCardHeader,
} from "features/Settings";
import { SimpleDialog } from "components/SimpleDialog";
import MessageBubble from "components/ConversationThread/MessageBubble";
import { BlankAvatar } from "components/BlankAvatar";
import { LockIcon } from "icons";

function AppendedMessageSettingsCard({
  account,
  setting,
}: AppendedMessageCardProps) {
  const { initialValues, values, errors, touched, setFieldValue, submitForm } =
    useFormikContext<IntroAndAppendedFormValues>();

  const {
    admin,
    lockedAtAccountName,
    shortenedLink,
    showPreview,
    handleClosePreview,
    handleOpenPreview,
    setShortenedLink,
    toggleLock,
  } = useMessageSettingsCard(setting, account);

  const currentSetting = account?.settings[setting];

  const { fields, maxChars, placeholder } = messageSettings[setting];

  const { messageEnabled } = values[setting].value;
  const { locked } = values[setting];
  const isConversation = setting === messageTypes.conversation;

  const defaultMessage =
    values.appendedMessageDefaults.value.message === ""
      ? initialValues.appendedMessageDefaults.value.message
      : values.appendedMessageDefaults.value.message;

  const message = values[setting].value.customMessageEnabled
    ? values[setting].value.customMessage
    : defaultMessage;

  const messageValues = {
    formattedBody: `<p>{Regular message content}</p><p>{User's Signature if any}</p><p>${message}</p>`,
    body: message,
    direction: "out" as MessageDirection,
    source: isConversation ? "textus" : "campaignsAppendedMessagePreview",
  };

  return (
    <>
      <SettingsCard>
        <Field name={fields.enabled}>
          {({ field, form }: FieldProps) => {
            return (
              <SettingsCardHeader
                account={account}
                active={field.value}
                canEditLock={!currentSetting?.disabled}
                locked={locked}
                title={`Use in ${isConversation ? "conversations" : "campaigns"}`}
                onActiveChange={() => {
                  form.setFieldValue(field.name, !field.value);
                }}
                onLockedChange={
                  admin
                    ? () => {
                        toggleLock(values, setFieldValue);
                        return submitForm();
                      }
                    : undefined
                }
                sx={{
                  marginBottom: messageEnabled ? 1 : 0,
                  padding: "0.5rem",
                }}
              />
            );
          }}
        </Field>
        {admin ? null : (
          <SettingsCardAlert severity="info" icon={<LockIcon />}>
            This setting can only be modified by your administrator.
          </SettingsCardAlert>
        )}
        {locked && admin ? (
          <SettingsCardAlert severity="info" icon={<LockIcon />}>
            {lockedAtAccountName === "" ? (
              <Skeleton
                width="18rem"
                sx={(theme) => {
                  return {
                    // TODO: Fix this to connect to new MUI theme values
                    backgroundColor: "rgba(40, 60, 89, 0.3)",
                  };
                }}
              />
            ) : (
              <>
                Locked by an admin for accounts below{" "}
                <strong>{lockedAtAccountName}</strong>.
              </>
            )}
          </SettingsCardAlert>
        ) : null}
        {messageEnabled && account ? (
          <>
            <SettingsCardContent>
              <Field name={fields.customMessageEnabled}>
                {({ field, form }: FieldProps) => {
                  return (
                    <RadioGroup value={field.value}>
                      <Typography variant="h6">Appended content:</Typography>
                      <FormControlLabel
                        control={
                          <Radio
                            {...field}
                            color="secondary"
                            checked={!field.value}
                            disabled={locked}
                            onChange={() => {
                              form.setFieldValue(field.name, false);
                            }}
                          />
                        }
                        label="Use default opt-out language"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            {...field}
                            color="secondary"
                            checked={field.value}
                            disabled={locked}
                            onChange={() => {
                              form.setFieldValue(field.name, true);
                            }}
                          />
                        }
                        label="Custom"
                      />
                      {field.value ? (
                        <Box
                          maxWidth={{
                            xs: "30rem",
                            sm: "32rem",
                            md: "38rem",
                            lg: "20.5rem",
                          }}
                        >
                          <MessageInputFormRow
                            currentAccount={account}
                            errors={errors}
                            fieldName={fields.message}
                            isDisabled={locked}
                            hideUserPersonalization={false}
                            maxChars={maxChars}
                            placeholder={placeholder}
                            setFieldValue={setFieldValue}
                            setShortenedLink={setShortenedLink}
                            shortenedLink={shortenedLink}
                            touched={touched}
                            values={values}
                          />
                        </Box>
                      ) : null}
                    </RadioGroup>
                  );
                }}
              </Field>
              <Field name={fields.appendOnEvery}>
                {({ field, form }: FieldProps) => {
                  return (
                    <RadioGroup sx={{ marginTop: "1rem" }}>
                      <Typography variant="h6">Resend cycle:</Typography>
                      <FormControlLabel
                        control={
                          <Radio
                            {...field}
                            color="secondary"
                            checked={!field.value}
                            disabled={locked}
                            onChange={() => {
                              form.setFieldValue(field.name, false);
                            }}
                          />
                        }
                        label="Use default"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            {...field}
                            color="secondary"
                            checked={field.value}
                            disabled={locked}
                            onChange={() => {
                              form.setFieldValue(field.name, true);
                            }}
                          />
                        }
                        label="Every message"
                      />
                    </RadioGroup>
                  );
                }}
              </Field>
            </SettingsCardContent>
            <SettingsCardActions sx={{ justifyContent: "flex-end" }}>
              <Button onClick={handleOpenPreview} variant="outlined">
                Preview
              </Button>
            </SettingsCardActions>
          </>
        ) : null}
      </SettingsCard>

      {/* Preview dialog */}
      <SimpleDialog
        open={showPreview}
        handleClose={handleClosePreview}
        title="Preview appended"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          margin="1rem"
          paddingLeft="0.25rem"
        >
          <MessageBubble message={messageValues} isCampaign={!isConversation} />
          <BlankAvatar sx={{ marginLeft: "0.25rem" }} />
        </Box>
      </SimpleDialog>
    </>
  );
}

export { AppendedMessageSettingsCard };
