import styled from "styled-components";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CallIcon from "@mui/icons-material/Call";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Formik, Field, Form, FieldArray, getIn, FieldProps } from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid2 as Grid,
  Button,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { FormValues, Props } from "./types";
import ContactFormTags from "features/ContactModal/containers/ContactFormTags";
import { ContactValidationSchema } from "formHelpers/validationSchemas";
import { BullhornCandidate, GreenhouseCandidate } from "features/ContactSync";
import { DialogFooter } from "components/DialogFooter";
import getUserRole from "utils/getUserRole";

const FormIcon = styled.div`
  position: relative;
  top: -10px;
  color: ${(props) => {
    return props.theme.colors.text.secondary;
  }};
  margin-right: 30px;
  margin-left: 15px;
`;

// Prevent Chrome from transitioning to a yellow background on autocomplete
const FormWrapper = styled.div<{
  isSmall?: boolean;
}>`
  padding: 20px 20px 0 20px;

  ${FormIcon} {
    display: ${(props) => {
      return props.isSmall ? "none" : "block";
    }};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
  }
`;

const FormRow = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-right: 72px;
`;

const PhoneRow = styled(FormRow)`
  margin-right: 0;
`;

const PhoneFieldWrapper = styled.div`
  flex: 1 1 auto;
`;

const PhoneNumberWrapper = styled(PhoneFieldWrapper)`
  margin-right: 20px;
`;

const PhoneButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 72px;
  margin: 0 0 1.25em 0;
`;

export function ContactForm({
  contact,
  conversationPhoneNumber,
  currentAccount,
  currentUser,
  onCancel,
  onContactUnlink,
  onDelete,
  onSubmit,
  loading,
}: Props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const canDelete = getUserRole(currentUser, currentAccount) === "admin";

  const initialValues: FormValues = {
    name: contact?.name ?? "",
    data: {
      business: get(contact, ["data", "business"], ""),
      tags: get(contact, ["data", "tags"], []) || [],
    },
    phones: contact?.phones
      ? contact.phones.members.map((phone) => {
          return {
            id: phone.id,
            number: phone.formattedPhoneNumber,
            type: phone.type || "",
          };
        })
      : [{ number: "", type: "Mobile" }],
    ...(isEmpty(contact) ? { method: "ui", source: "manual" } : {}),
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={ContactValidationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, isValid, values, setFieldValue }) => {
        return (
          <Grid
            sx={{
              overflowY: "auto",
            }}
            component={Form}
          >
            <FormWrapper isSmall={isSmall}>
              <FormRow>
                <FormIcon>
                  <PersonIcon style={{ fontSize: 24 }} />
                </FormIcon>
                <Field type="text" name="name">
                  {({ field }: FieldProps) => {
                    if (loading) {
                      return (
                        <Skeleton
                          sx={{
                            marginBottom: "23px",
                          }}
                          variant="rounded"
                          width="100%"
                          height={56}
                        />
                      );
                    }

                    return (
                      <TextField
                        {...field}
                        autoFocus
                        error={touched.name && Boolean(errors.name)}
                        fullWidth
                        helperText={touched.name ? errors.name || " " : " "}
                        inputProps={{ "data-lpignore": true }}
                        label="Name"
                        placeholder="Bugs Bunny"
                        type="text"
                        variant="outlined"
                        id="textus-ContactForm-Name"
                        aria-label="New Contact Name Field"
                      />
                    );
                  }}
                </Field>
              </FormRow>
              <FormRow>
                <FormIcon>
                  <ApartmentIcon style={{ fontSize: 22 }} />
                </FormIcon>
                <Field type="text" name="data.business">
                  {({ field }: FieldProps) => {
                    if (loading) {
                      return (
                        <Skeleton
                          sx={{
                            marginBottom: "23px",
                          }}
                          variant="rounded"
                          width="100%"
                          height={56}
                        />
                      );
                    }

                    return (
                      <TextField
                        {...field}
                        fullWidth
                        helperText=" "
                        inputProps={{ "data-lpignore": true }}
                        label="Business"
                        placeholder="Acme Co"
                        type="text"
                        variant="outlined"
                        aria-label="Business Name Field"
                      />
                    );
                  }}
                </Field>
              </FormRow>
              <FieldArray name="phones">
                {(arrayHelpers) => {
                  return (
                    <div>
                      {values.phones.map((phone, index) => {
                        return (
                          <PhoneRow key={index}>
                            <FormIcon>
                              <CallIcon style={{ fontSize: 22 }} />
                            </FormIcon>
                            <PhoneNumberWrapper>
                              <Field name={`phones.${index}.number`}>
                                {({ field }: FieldProps) => {
                                  if (loading) {
                                    return (
                                      <Skeleton
                                        sx={{
                                          marginBottom: "23px",
                                        }}
                                        variant="rounded"
                                        width="100%"
                                        height={56}
                                      />
                                    );
                                  }

                                  return (
                                    <TextField
                                      {...field}
                                      error={
                                        getIn(
                                          touched,
                                          `phones.${index}.number`,
                                        ) &&
                                        Boolean(
                                          getIn(
                                            errors,
                                            `phones.${index}.number`,
                                          ),
                                        )
                                      }
                                      fullWidth
                                      helperText={
                                        getIn(touched, `phones.${index}.number`)
                                          ? getIn(
                                              errors,
                                              `phones.${index}.number`,
                                            ) || " "
                                          : " "
                                      }
                                      inputProps={{ "data-lpignore": true }}
                                      label="Number"
                                      placeholder="(555) 555-5555"
                                      type="text"
                                      variant="outlined"
                                      id={`textus-ContactForm-number-${index}`}
                                      aria-label="Phone Number Field"
                                    />
                                  );
                                }}
                              </Field>
                            </PhoneNumberWrapper>
                            <PhoneFieldWrapper>
                              <Field name={`phones.${index}.type`}>
                                {({ field }: FieldProps) => {
                                  if (loading) {
                                    return (
                                      <Skeleton
                                        sx={{
                                          marginBottom: "23px",
                                        }}
                                        variant="rounded"
                                        width="100%"
                                        height={56}
                                      />
                                    );
                                  }

                                  return (
                                    <TextField
                                      {...field}
                                      error={
                                        getIn(
                                          touched,
                                          `phones.${index}.type`,
                                        ) &&
                                        Boolean(
                                          getIn(errors, `phones.${index}.type`),
                                        )
                                      }
                                      fullWidth
                                      helperText={
                                        getIn(touched, `phones.${index}.type`)
                                          ? getIn(
                                              errors,
                                              `phones.${index}.type`,
                                            ) || " "
                                          : " "
                                      }
                                      inputProps={{ "data-lpignore": true }}
                                      label="Type"
                                      placeholder="Type"
                                      type="text"
                                      variant="outlined"
                                    />
                                  );
                                }}
                              </Field>
                            </PhoneFieldWrapper>
                            <PhoneButtonsWrapper>
                              <Tooltip title="Remove phone number">
                                <div>
                                  <IconButton
                                    type="button"
                                    onClick={() => {
                                      return arrayHelpers.remove(index);
                                    }}
                                    disabled={
                                      loading ||
                                      values.phones.length === 1 ||
                                      conversationPhoneNumber ===
                                        values.phones[index].number
                                    }
                                    size="large"
                                  >
                                    <RemoveCircleOutlineIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              </Tooltip>
                              {index === values.phones.length - 1 &&
                                values.phones.length < 5 && (
                                  <Tooltip title="Add another phone number">
                                    <IconButton
                                      type="button"
                                      disabled={loading}
                                      onClick={() => {
                                        return arrayHelpers.push({
                                          number: "",
                                          type: "Mobile",
                                        });
                                      }}
                                      size="large"
                                    >
                                      <AddCircleOutlineIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                            </PhoneButtonsWrapper>
                          </PhoneRow>
                        );
                      })}
                    </div>
                  );
                }}
              </FieldArray>
              <FormRow>
                <FormIcon>
                  <LocalOfferIcon style={{ fontSize: 22 }} />
                </FormIcon>
                <Field type="text" name="data.tags">
                  {() => {
                    if (loading) {
                      return (
                        <Skeleton
                          sx={{
                            marginBottom: "23px",
                          }}
                          variant="rounded"
                          width="100%"
                          height={56}
                        />
                      );
                    }

                    return (
                      <ContactFormTags
                        contactTagsId={`/${currentAccount.slug}/contacts/tags`}
                        changeHandler={setFieldValue}
                        value={values.data.tags}
                      />
                    );
                  }}
                </Field>
              </FormRow>
            </FormWrapper>
            {contact?.data?.bullhorn?.Candidate && onContactUnlink && (
              <Accordion
                disableGutters
                square
                sx={() => {
                  return {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    boxShadow: "none",
                    "&:before": {
                      display: "none",
                    },
                    "&.Mui-expanded": {
                      ".MuiAccordionSummary-root": {
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      },
                    },
                  };
                }}
              >
                <AccordionSummary
                  sx={() => {
                    return {
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-content": {
                        marginLeft: theme.spacing(1),
                      },
                    };
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  Bullhorn Details
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingX: 2,
                    paddingY: 1,
                  }}
                >
                  <BullhornCandidate
                    contact={contact}
                    currentAccount={currentAccount}
                    onChanged={(candidate) => {
                      setFieldValue("name", candidate.name);
                      setFieldValue("data.bullhorn.Candidate", candidate);
                    }}
                    onContactUnlink={onContactUnlink}
                  />
                </AccordionDetails>
              </Accordion>
            )}
            {contact?.data?.greenhouse?.Candidate && onContactUnlink && (
              <Accordion
                disableGutters
                square
                sx={() => {
                  return {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    boxShadow: "none",
                    "&:before": {
                      display: "none",
                    },
                    "&.Mui-expanded": {
                      ".MuiAccordionSummary-root": {
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      },
                    },
                  };
                }}
              >
                <AccordionSummary
                  sx={() => {
                    return {
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-content": {
                        marginLeft: theme.spacing(1),
                      },
                    };
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  Greenhouse details
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingX: 2,
                    marginY: 1,
                  }}
                >
                  <GreenhouseCandidate
                    contact={contact}
                    currentAccount={currentAccount}
                    onChanged={(candidate) => {
                      setFieldValue(
                        "name",
                        `${candidate.first_name} ${candidate.last_name}`,
                      );
                      setFieldValue("data.greenhouse.Candidate", candidate);
                    }}
                    onContactUnlink={onContactUnlink}
                  />
                </AccordionDetails>
              </Accordion>
            )}
            <DialogFooter
              onCancel={onCancel}
              confirmText="Save"
              isConfirmDisabled={!isValid || isSubmitting || loading}
              altButton={
                canDelete && onDelete ? (
                  <Button
                    color="error"
                    variant="outlined"
                    disabled={isSubmitting || loading}
                    onClick={onDelete}
                    style={{ marginRight: 1 }}
                  >
                    Remove Contact
                  </Button>
                ) : undefined
              }
            />
          </Grid>
        );
      }}
    </Formik>
  );
}
