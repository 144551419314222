import { Box } from "@mui/material";
import { useFormikContext } from "formik";
import { SequenceFormInputsProps } from "./types";
import { Input } from "components/Input";
import { Select } from "components/Select";
import type { SequenceValues } from "features/Sequences/hooks/useSequences/types";

function SequenceFormInputs({ userFlow }: SequenceFormInputsProps) {
  const { errors, handleChange, touched, values } =
    useFormikContext<SequenceValues>();

  const isSettingsTab = userFlow === "settings";
  const isDuplicateForm = userFlow === "duplicate";

  const onReplyDropdownOptions = [
    {
      value: "true",
      label: "Mark finished",
    },
    {
      value: "false",
      label: "Continues to next step",
    },
  ];

  const typeDropdownOptions = [
    {
      value: "relative",
      label: "Steps by ongoing days and time",
    },
    {
      value: "absolute",
      label: "Steps by scheduled date and time",
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="34rem"
      gap="1rem"
      mt="1rem"
      mb="1rem"
    >
      <Input
        name="sequenceName"
        label="Sequence name"
        fullWidth
        helperText={touched.sequenceName && errors.sequenceName}
        inputProps={{ "data-lpignore": true }}
        placeholder="Sequence name"
        data-testid="sequence-name-input"
      />

      <Select
        name="scheduleType"
        disabled={isDuplicateForm || isSettingsTab}
        fullWidth
        inputProps={{ "data-lpignore": true }}
        options={typeDropdownOptions}
        required
        label="Sequence type"
        onChange={handleChange("scheduleType")}
        value={values.scheduleType}
        data-testid="sequence-type-select"
      />
      <Select
        name="finishedOnReply"
        disabled={isSettingsTab}
        label="If contact replies to a sequence message"
        fullWidth
        required={userFlow !== "settings"}
        options={onReplyDropdownOptions}
        onChange={handleChange("finishedOnReply")}
        value={values.finishedOnReply}
        data-testid="sequence-reply-select"
      />
    </Box>
  );
}

export { SequenceFormInputs };
