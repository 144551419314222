import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Theme, useMediaQuery } from "@mui/material";
import { formatDistanceToNow, format } from "date-fns";
import { Unarchive } from "@mui/icons-material";
import KeywordTableActionMenu from "./KeywordTableActionMenu";
import { KeywordTableProps } from "features/Keywords/types";
import { ArchiveFilledIcon } from "icons";
import { NoResultsScreen } from "components/Search/NoResultsScreen";
import { GridActionToolbar } from "components/GridActionToolbar";
import { useGridActionToolbar } from "components/GridActionToolbar/hooks/useGridActionToolbar";

function KeywordTable({
  showingActiveKeywords,
  debouncedSearchTerm,
  handlePageChange,
  isLoadingKeywords,
  keywords,
  paginationModel,
  sx,
}: KeywordTableProps) {
  const isMobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  // TODO: The batch action/selection functionality needs to be built out, just leaving a skeleton here for now
  const { allSelected, selectedRows, selectedCount, handleRowSelection } =
    useGridActionToolbar();

  const menuColumn: GridColDef = {
    field: "moreVertIcon",
    headerName: "",
    disableColumnMenu: true,
    disableReorder: true,
    filterable: false,
    hideSortIcons: true,
    align: "center",
    width: 10,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <KeywordTableActionMenu
          keywordType={showingActiveKeywords ? "active" : "archive"}
        />
      );
    },
  };

  const dateColumn: GridColDef = showingActiveKeywords
    ? {
        field: "updatedAt",
        headerName: "Last edited",
        flex: 1,
        valueFormatter: (params: { value: string }) => {
          return formatDistanceToNow(new Date(params.value), {
            addSuffix: true,
          });
        },
      }
    : {
        field: "updatedAt",
        headerName: "Archived on",
        flex: 1,
        valueFormatter: (params: { value: string }) => {
          return format(new Date(params.value), "MM-dd-yyyy");
        },
      };

  const columns: GridColDef[] = isMobileScreen
    ? [
        {
          field: "keyword",
          headerName: "Name",
          flex: 1,
        },
        menuColumn,
      ]
    : [
        {
          field: "keyword",
          headerName: "Name",
          flex: 1,
        },
        {
          field: "responseBody",
          headerName: "Auto-response",
          flex: 1,
        },
        {
          field: "timesReceived",
          headerName: "Keyword views",
          flex: 1,
          type: "number",
        },
        {
          field: "subKeywords",
          headerName: "Answers",
          flex: 1,
          valueFormatter: (params) => {
            // TODO: Depending on shape of subKeywords, this may need to be updated
            const subKeywords = params.value;
            return subKeywords.length > 0 ? subKeywords.join(", ") : "—";
          },
        },
        {
          field: "PLACEHOLDER", // TODO: Need createdBy added to KeywordResponse
          headerName: "Created by",
          flex: 1,
        },
        dateColumn,
        menuColumn,
      ];

  const { items = [] } = keywords ?? {};
  const tableLabel = "keyword-table";

  return (
    <DataGridPro
      data-testid={tableLabel}
      aria-label={tableLabel}
      checkboxSelection
      columns={columns}
      disableRowSelectionOnClick
      loading={isLoadingKeywords}
      onPaginationModelChange={handlePageChange}
      onRowSelectionModelChange={handleRowSelection}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      pageSizeOptions={[]}
      rows={items}
      rowCount={keywords?.page?.count ?? 0}
      rowHeight={isMobileScreen ? 54 : 122}
      slots={{
        noRowsOverlay: NoResultsScreen,
        toolbar: selectedCount > 0 ? GridActionToolbar : null,
      }}
      slotProps={{
        noRowsOverlay: {
          // @ts-expect-error - noRowsOverlay is a custom slot
          source: "keywords",
          active: debouncedSearchTerm,
          noItemsCreated: keywords?.items.length === 0,
        },
        // @ts-expect-error - data-testid is needed for testing
        pagination: { "data-testid": "pagination" },
        toolbar: {
          actions: [
            {
              key: showingActiveKeywords ? "archive" : "reactivate",
              title: `${showingActiveKeywords ? "Archive" : "Reactivate"} keyword(s)`,
              clickHandler: () => {}, // TODO: Make clicky-clicky do stuff
              icon: showingActiveKeywords ? (
                <ArchiveFilledIcon />
              ) : (
                <Unarchive />
              ),
              showExpanded: true,
            },
          ],
          selectedCount,
          selectAllCount: keywords?.page?.count ?? 0,
          showHeaderCheckbox: true,
        },
      }}
      sx={sx}
    />
  );
}

export default KeywordTable;
