import { useState, useEffect, SyntheticEvent } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { SavedReply } from "@tesseract/core";
import { fetchSavedReplyCollection } from "../api";
import { SavedReplyListProps } from "./types";
import SavedReplyCollection from "features/SavedReplies/containers/SavedReplyCollection";
import { SearchBox } from "components/SearchBox";
import { useCurrentUser, useDebouncedSearch } from "hooks";

const SAVED_REPLY_FILTERS = Object.freeze({
  mine: "mine",
  all: "all",
});

export default function SavedReplyList({
  activeSavedReply,
  currentUser,
  disableTemplateAttachments,
  hasManageOptions,
  mobileManager = false,
  mobileSettingsManager = false,
  isSearching,
  savedReplies,
  savedReplyCollectionId,
  savedReplySelectionHandler,
  setActiveSavedReply,
  setSavedReplies,
  setSavedReplyCollectionId,
  setIsSearching,
  settings,
  templatesMaintenance,
}: SavedReplyListProps) {
  const { mine, all } = SAVED_REPLY_FILTERS;

  const [selectedTab, setSelectedTab] = useState(0);
  const { savedReplies: slug } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { searchTerm, debouncedSearchTerm, handleClear, handleSearch } =
    useDebouncedSearch();

  const filter = selectedTab === 0 ? mine : all;

  const getSavedReplies = async () => {
    const response = await fetchSavedReplyCollection(
      slug as `${string}/saved_replies`,
      filter,
      debouncedSearchTerm,
    );
    return response.json();
  };

  const { data: allSavedReplies } = useQuery({
    queryKey: ["savedReplies", filter, debouncedSearchTerm],
    queryFn: getSavedReplies,
    throwOnError: (error) => {
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
      });
      console.error(error);
      return false;
    },
  });

  useEffect(() => {
    setSavedReplies(allSavedReplies);
    const nonAttachmentSavedReply = allSavedReplies?.members?.find(
      (member: SavedReply.Raw) => {
        return member?.attachments?.members?.length === 0;
      },
    );
    if (templatesMaintenance && !debouncedSearchTerm) {
      setActiveSavedReply(
        disableTemplateAttachments
          ? nonAttachmentSavedReply
          : allSavedReplies?.members[0],
      );
    }
  }, [
    allSavedReplies,
    setActiveSavedReply,
    settings,
    templatesMaintenance,
    setSavedReplies,
    disableTemplateAttachments,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    if (setIsSearching) {
      setIsSearching(Boolean(searchTerm));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, setIsSearching]);

  const handleTabChange = (event: SyntheticEvent, newTab: number) => {
    setSelectedTab(newTab);
    const newFilter = newTab === 0 ? mine : all;
    setSavedReplyCollectionId(
      `${currentUser.savedReplies}?filter=${newFilter}`,
    );
  };

  return (
    <Box display="flex" flexDirection="column" overflow="auto" height="100%">
      <Box
        alignItems="flex-start"
        justifyContent="flex-start"
        display="flex"
        flexDirection="column"
        sx={{
          borderBottom: (theme) => {
            return `1px solid ${theme.palette.divider}`;
          },
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={(theme) => {
            return {
              width: "100%",
              borderBottom: `1px solid ${theme.palette.divider}`,
            };
          }}
        >
          <Tab
            label={mine}
            data-testid={`saved-reply-${mine}-tab`}
            sx={{ width: "50%" }}
          />
          <Tab
            label={all}
            data-testid={`saved-reply-${all}-tab`}
            sx={{ width: "50%" }}
          />
        </Tabs>
        <SearchBox
          autoFocus
          value={searchTerm}
          onClear={handleClear}
          onChange={handleSearch}
          placeholder="Search templates"
          sx={{ padding: "0.5rem 1rem" }}
        />
      </Box>
      <SavedReplyCollection
        activeSavedReply={activeSavedReply}
        disableTemplateAttachments={disableTemplateAttachments}
        hasManageOptions={hasManageOptions}
        mobileManager={mobileManager}
        mobileSettingsManager={mobileSettingsManager}
        isSearching={isSearching}
        savedReplies={savedReplies}
        savedReplyCollectionId={savedReplyCollectionId}
        savedReplySelectionHandler={savedReplySelectionHandler}
        searchTerm={debouncedSearchTerm}
        setActiveSavedReply={setActiveSavedReply}
        settings={settings}
        templatesMaintenance={templatesMaintenance}
        setSavedReplies={setSavedReplies}
      />
    </Box>
  );
}
