import { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";

import { ListItemButton } from "@mui/material";
import AccountRowMenu from "./AccountRowMenu";
import Avatar from "components/Avatar";

function AccountRow({
  account,
  index,
  handleClick,
  style = {},
  selected = [],
  setSelectedAccounts,
  withCheckboxes,
}) {
  const { name, nestingLevel = 0, slug } = account;
  const to = `/${slug}/settings`;

  const renderLink = useMemo(() => {
    return forwardRef((linkProps, ref) => {
      return <Link to={to} {...linkProps} innerRef={ref} />;
    });
  }, [to]);

  const handleNodeClick = (event) => {
    if (handleClick) {
      handleClick(event, account);
    }
  };

  const isSelected = selected
    .map((acc) => {
      return acc.id;
    })
    .includes(account.id);

  const extraStyle = {
    paddingLeft: `${(nestingLevel + 1) * 20}px`,
    paddingTop: 0,
    paddingBottom: 0,
    height: "60px",
  };

  const additionalListItemProps = withCheckboxes
    ? {
        ContainerProps: { style },
        ContainerComponent: "div",
        style: extraStyle,
      }
    : {
        component: renderLink,
        style: { ...style, ...extraStyle },
        selected: isSelected,
      };

  return (
    <ListItemButton
      dense
      onClick={handleNodeClick}
      key={index}
      aria-label={name}
      {...additionalListItemProps}
    >
      {withCheckboxes && (
        <ListItemIcon>
          <Checkbox
            edge="start"
            color="secondary"
            checked={isSelected}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
      )}
      <ListItemAvatar style={{ minWidth: "38px" }}>
        <Avatar subject={account} size={33} />
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={slug}
        primaryTypographyProps={{ noWrap: true, display: "block" }}
        secondaryTypographyProps={{ noWrap: true }}
      />
      {withCheckboxes && (
        <ListItemSecondaryAction style={{ right: "10px" }}>
          {get(account, ["children", "members"], []).length > 0 && (
            <AccountRowMenu
              account={account}
              selected={selected}
              setSelectedAccounts={setSelectedAccounts}
            />
          )}
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  );
}

AccountRow.propTypes = {
  account: PropTypes.object.isRequired,
  index: PropTypes.number,
  handleClick: PropTypes.func,
  style: PropTypes.object,
  selected: PropTypes.array,
  setSelectedAccounts: PropTypes.func,
  withCheckboxes: PropTypes.bool,
};

export default AccountRow;
