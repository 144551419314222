import { useEffect } from "react";
import localStorageHelper from "utils/localStorageHelper";
import { useChromeExtension } from "hooks/useChromeExtension";
import { useCurrentAccount } from "hooks";

const useExtensionReady = () => {
  const { isIFrame } = useChromeExtension();
  const currentAccount = useCurrentAccount();

  useEffect(() => {
    if (isIFrame) {
      localStorageHelper.setItem({
        item: "extensionOpened",
        value: new Date().toISOString(),
      });

      // Let's the Chrome Extension know that the app is ready to receive and respond to messages.
      // We'll use this event to send data the extension needs to know about the app.
      window.parent.postMessage(
        {
          type: "extensionReady",
          payload: {
            // Used by Find Numbers to properly validate phone numbers
            countryCode: currentAccount?.settings?.countryCode?.value || "US",
          },
        },
        "*",
      );
    }
  }, [currentAccount?.settings?.countryCode?.value, isIFrame]);
};

export default useExtensionReady;
