import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";

import { merge } from "lodash";
import { Props } from "./types";

function SearchBox({
  autoFocus = false,
  onClear: handleClear,
  fullWidth = true,
  variant = "outlined",
  value,
  sx,
  slotProps,
  ...props
}: Props) {
  return (
    <TextField
      data-testid="search-field"
      sx={
        typeof sx === "function"
          ? sx
          : merge(
              {
                ".MuiInputBase-root": {
                  paddingRight: "2px",
                },
              },
              sx,
            )
      }
      inputRef={
        autoFocus
          ? (input) => {
              return input && input.focus();
            }
          : undefined
      }
      slotProps={merge(
        {
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: value ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Clear search term"
                  data-testid="clear-search-term"
                  onClick={handleClear}
                  onMouseDown={handleClear}
                >
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          },
        },
        slotProps,
      )}
      {...merge(
        {
          fullWidth,
          variant,
          value,
        },
        props,
      )}
    />
  );
}

export { SearchBox };
