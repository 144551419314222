import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { lighten } from "polished";
import { SketchPicker, CirclePicker } from "react-color";
import { Formik, Form } from "formik";
import get from "lodash/get";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { lightTheme } from "@tesseract/theme";
import SettingsPageWrapper from "../../../../../components/SettingsPageComponents/SettingsPageWrapper";
import SubmitRow from "../../SubmitRow";
import LockForm from "../../LockForm";
import LockOverlay from "../../LockOverlay";
import Preview from "./Preview";
import forcePageRefresh from "utils/forcePageRefresh";
import BlankState from "components/BlankState";
import { WhiteLabelValidationSchema } from "formHelpers/validationSchemas";

import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import ExpansionPanelDetails from "components/ExpansionPanel/Details";
import { Dropzone } from "components/Dropzone";
import Logo from "components/Logo";
import PageHeader from "components/Page/PageHeader";
import H4 from "components/H4";
import P from "components/P";

const buildLogoUrl = (url) => {
  if (!url) return null;

  const height = Math.round(50 * window.devicePixelRatio);
  const [, , domain, key] = url.split("/");
  const data = JSON.parse(atob(key));
  data.edits = {
    resize: {
      height,
      fit: "outside",
    },
  };
  return `https://${domain}/${btoa(JSON.stringify(data))}`;
};

const COLOR_PANELS = [
  {
    name: "Primary Color",
    key: "primaryColor",
    details:
      "This color will be used for the main navigation and most headers throughout the app",
  },
  {
    name: "Secondary Color",
    key: "secondaryColor",
    details:
      " This color will be used for badges, secondary buttons and sub headers throught the app",
  },
  {
    name: "Tertiary Color",
    key: "tertiaryColor",
    details:
      " This color will be used for unread indicators, active states and other accents throughout the app",
  },
];

const settings = [
  {
    default: lightTheme.primaryColor,
    name: "primaryColor",
  },
  {
    default: lightTheme.secondaryColor,
    name: "secondaryColor",
  },
  {
    default: lightTheme.tertiaryColor,
    name: "tertiaryColor",
  },
  {
    default: lightTheme.outboundMessageColor,
    name: "outboundMessageColor",
  },
  {
    default: "",
    name: "logo",
  },
];

const OUTBOUND_MESSAGE_COLORS = [
  "#FFE2FA",
  "#FFC3C4",
  "#D1D0FF",
  "#D4E6FF",
  "#BFF1C6",
  "#D8F9A1",
  "#FFFEAC",
  "#FFDE92",
];

const Root = styled(SettingsPageWrapper)`
  & .sketch-picker {
    padding: 0 0 15px !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    width: auto !important;
    background: ${(props) => {
      return props.theme.colors.background.paper;
    }} !important;
  }
`;

const StyledForm = styled(Form)`
  height: 100%;
  overflow: hidden;
  flex: 1 1 100%;
  position: relative;
`;

const StyledLockOverlay = styled(LockOverlay)`
  z-index: 999 !important;

  & > div {
    top: 50%;
    box-shadow: ${(props) => {
      return props.theme.mixins.boxShadow;
    }};
  }
`;

const FormContent = styled.div`
  display: flex;
  height: 100%;
`;

const Settings = styled.div`
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }} !important;
  flex: 1 1 400px;
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  position: relative;

  & > ${H4} {
    margin: 20px;
  }
`;

const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  position: sticky !important;
  top: 0 !important;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }} !important;
  display: flex;
  z-index: 10;

  .MuiExpansionPanelSummary-content {
    align-items: center;

    ${H4} {
      margin: 0;
    }
  }
`;

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  flex-flow: column nowrap;
  width: 100%;

  p {
    background: ${(props) => {
      return props.theme.colors.background.paper;
    }} !important;
    padding: 18px;
  }
`;

const ColorBubble = styled.div`
  height: 22px;
  width: 22px;
  flex: 0 0 22px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  background: ${(props) => {
    return props.color;
  }};
  align-self: flex-start;
`;

const StyledSketchPicker = styled(SketchPicker)`
  input {
    font-size: ${(props) => {
      return props.theme.fonts.messageFontSize;
    }} !important;
  }
`;

const StyledCirclePicker = styled(CirclePicker)`
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }} !important;
  padding: 20px;
  width: 100% !important;
  justify-content: center;
`;

const StyledSubmitRow = styled(SubmitRow)`
  padding: 0 20px;
`;

const StyledPhotoFilterIcon = styled(PhotoFilterIcon)`
  margin-right: 13px;
  font-size: 26px !important;
`;

const TrashButton = styled.button`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  box-shadow: ${(props) => {
    return props.theme.mixins.boxShadow();
  }};
  height: 33px;
  width: 33px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
  cursor: pointer;
`;

const StyledUploadZone = styled(Dropzone)`
  margin: 13px;
  background: ${(props) => {
    return props.hasLogo && props.primaryColor;
  }};
  border-radius: ${(props) => {
    return props.theme.defaults.uploadZoneBorderRadius;
  }};

  &:hover {
    background: ${(props) => {
      return props.hasLogo && lighten(0.1, props.primaryColor);
    }};
  }

  & > div {
    border: ${(props) => {
      return props.hasLogo && "none";
    }} !important;
  }

  span {
    margin-top: 10px;
  }
`;

const PhotoUploader = styled.div`
  padding: 10px;
  position: relative;
  text-align: center;

  span {
    font-weight: 700;
  }

  ${StyledPhotoFilterIcon} {
    margin-right: 0;
    margin-bottom: 10px;
  }

  &:hover {
    ${TrashButton} {
      display: flex;
    }
  }
`;

const PreviewImage = styled.img`
  height: 55px;
`;

const DarkModeBlankState = styled(BlankState)`
  span[role="img"] {
    font-size: 40px;
  }

  ${P} {
    margin-top: 10px;
    max-width: 600px;
  }
`;

class WhiteLabel extends Component {
  state = {
    temporaryLogo: null,
    temporaryMobileLogo: null,
  };

  static propTypes = {
    appColors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
    whiteLabelContainer: PropTypes.object.isRequired,
    updateAppColors: PropTypes.func.isRequired,
    uploadWhiteLabelLogoRequest: PropTypes.func.isRequired,
    uploadWhiteLabelMobileLogoRequest: PropTypes.func.isRequired,
  };

  getFlattenedTheme = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      return {
        ...acc,
        [key]: obj[key].value,
      };
    }, {});
  };

  handleSubmit = (
    values,
    actions,
    message = "🎉 Your theme has been updated!",
  ) => {
    const newAppColors = {
      ...this.getFlattenedTheme(values.settings),
      type: "light",
    };

    this.props.handleSubmit(values, actions, message);

    this.props.updateAppColors(newAppColors);

    this.setState({
      temporaryLogo: null,
      temporaryMobileLogo: null,
    });
  };

  handleFilesAccepted = ({ setFieldValue, logoType }) => {
    return (file) => {
      const logo = URL.createObjectURL(file);

      this.setState(
        logoType === "logo"
          ? { temporaryLogo: logo }
          : { temporaryMobileLogo: logo },
      );

      if (logoType === "logo") {
        this.props.uploadWhiteLabelLogoRequest(
          this.props.account.signedWhitelabelUrl,
          { file },
          {
            successCallback: (logoKey) => {
              return setFieldValue(`settings.logo.value`, logoKey);
            },
          },
        );
      } else if (logoType === "mobileLogo") {
        this.props.uploadWhiteLabelMobileLogoRequest(
          this.props.account.signedWhitelabelUrl,
          { file },
          {
            successCallback: (logoKey) => {
              return setFieldValue(`settings.mobileLogo.value`, logoKey);
            },
          },
        );
      }
    };
  };

  handleDeleteLogo = ({ setFieldValue, logoType }) => {
    this.setState(
      logoType === "logo"
        ? { temporaryLogo: null }
        : { temporaryMobileLogo: null },
    );
    setFieldValue(`settings.${logoType}.value`, "");
  };

  renderPhotoUploaderContent = ({ isUploading, logo, temporaryLogo }) => {
    if (isUploading) {
      return (
        <Logo
          animate
          color="transparent"
          dotColor={logo ? "contrast" : "primary"}
        />
      );
    }

    if (temporaryLogo) {
      return <PreviewImage alt="temp-logo" src={temporaryLogo} />;
    }

    if (logo) {
      return <PreviewImage alt="temp-logo" src={logo} />;
    }

    return <StyledPhotoFilterIcon color="primary" />;
  };

  renderLogoUploaderContent = () => {
    const {
      account,
      whiteLabelContainer: { isUploading },
    } = this.props;
    const generalLogo = get(account, ["logo"]);
    const { temporaryLogo } = this.state;
    return this.renderPhotoUploaderContent({
      logo: generalLogo,
      temporaryLogo,
      isUploading,
    });
  };

  renderMobileLogoUploaderContent = () => {
    const {
      account,
      whiteLabelContainer: { isMobileUploading },
    } = this.props;
    const mobileLogo = get(account, ["settings", "mobileLogo", "value"]);
    const { temporaryMobileLogo } = this.state;

    return this.renderPhotoUploaderContent({
      logo: mobileLogo,
      temporaryLogo: temporaryMobileLogo,
      isUploading: isMobileUploading,
    });
  };

  renderPhotoUploaderMessage = ({ isUploading, logo, temporaryLogo }) => {
    if (isUploading) {
      return "Uploading";
    }

    if (!logo && !temporaryLogo) {
      return "Upload a photo";
    }

    if (temporaryLogo) {
      return "Click 'Save' to update theme";
    }

    return null;
  };

  renderLogoUploaderMessage = () => {
    const {
      account,
      whiteLabelContainer: { isUploading },
    } = this.props;
    const generalLogo = get(account, ["logo"]);
    const { temporaryLogo } = this.state;

    return this.renderPhotoUploaderMessage({
      logo: generalLogo,
      temporaryLogo,
      isUploading,
    });
  };

  renderMobileLogoUploaderMessage = () => {
    const {
      account,
      whiteLabelContainer: { isMobileUploading },
    } = this.props;
    const mobileLogo = get(account, ["settings", "mobileLogo", "value"]);
    const { temporaryMobileLogo } = this.state;

    return this.renderPhotoUploaderMessage({
      logo: mobileLogo,
      temporaryLogo: temporaryMobileLogo,
      isUploading: isMobileUploading,
    });
  };

  renderColorExpansionPanel = (panel, values, setFieldValue) => {
    const { key, details, name } = panel;
    const color = values.settings[key].value;

    return (
      <ExpansionPanel key={key}>
        <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <ColorBubble color={color} />
          <div>
            <H4>{name}</H4>
          </div>
        </StyledExpansionPanelSummary>

        <StyledExpansionPanelDetails>
          <P>{details}</P>
          <StyledSketchPicker
            disableAlpha
            presetColors={[]}
            color={color}
            onChange={({ hex }) => {
              return setFieldValue(`settings.${key}.value`, hex);
            }}
          />
        </StyledExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  renderForm = ({ values, setFieldValue }) => {
    const {
      settings: { primaryColor, outboundMessageColor },
    } = values;
    const { temporaryLogo, temporaryMobileLogo } = this.state;
    const { currentUser, account, isDisabled } = this.props;
    const uploadProps = {
      acceptedFileTypes: "image/jpeg, image/png",
      onFilesSelected: () => {},
      onFilesRejected: () => {},
    };

    const generalLogo = get(account, ["logo"]);
    const mobileLogo = get(account, ["settings", "mobileLogo", "value"]);
    const palette = this.getFlattenedTheme(values.settings);

    const disabled =
      settings.some((setting) => {
        return get(account, ["settings", setting.name, "disabled"]);
      }) || isDisabled;

    return (
      <StyledForm>
        <FormContent>
          <Settings>
            {disabled && <StyledLockOverlay />}

            <ExpansionPanel>
              <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <StyledPhotoFilterIcon color="primary" />
                <div>
                  <H4>Logo</H4>
                </div>
              </StyledExpansionPanelSummary>

              <StyledExpansionPanelDetails>
                <PhotoUploader>
                  <StyledUploadZone
                    {...uploadProps}
                    onFileAccepted={this.handleFilesAccepted({
                      setFieldValue,
                      logoType: "logo",
                    })}
                    hasLogo={generalLogo && !temporaryLogo}
                    primaryColor={primaryColor.value}
                  >
                    {this.renderLogoUploaderContent()}
                    <span>{this.renderLogoUploaderMessage()}</span>
                  </StyledUploadZone>
                  {generalLogo && !temporaryLogo && (
                    <TrashButton
                      type="submit"
                      onClick={() => {
                        return this.handleDeleteLogo({
                          setFieldValue,
                          logoType: "logo",
                        });
                      }}
                    >
                      <DeleteIcon />
                    </TrashButton>
                  )}
                </PhotoUploader>
              </StyledExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
              <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <StyledPhotoFilterIcon color="primary" />
                <div>
                  <H4>Mobile Logo</H4>
                </div>
              </StyledExpansionPanelSummary>

              <StyledExpansionPanelDetails>
                <PhotoUploader>
                  <StyledUploadZone
                    {...uploadProps}
                    onFileAccepted={this.handleFilesAccepted({
                      setFieldValue,
                      logoType: "mobileLogo",
                    })}
                    hasLogo={mobileLogo && !temporaryMobileLogo}
                    primaryColor={primaryColor.value}
                  >
                    {this.renderMobileLogoUploaderContent()}
                    <span>{this.renderMobileLogoUploaderMessage()}</span>
                  </StyledUploadZone>
                  {mobileLogo && !temporaryMobileLogo && (
                    <TrashButton
                      type="submit"
                      onClick={() => {
                        return this.handleDeleteLogo({
                          setFieldValue,
                          logoType: "mobileLogo",
                        });
                      }}
                    >
                      <DeleteIcon />
                    </TrashButton>
                  )}
                </PhotoUploader>
              </StyledExpansionPanelDetails>
            </ExpansionPanel>

            {COLOR_PANELS.map((panel) => {
              return this.renderColorExpansionPanel(
                panel,
                values,
                setFieldValue,
              );
            })}

            <ExpansionPanel>
              <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <ColorBubble color={outboundMessageColor.value} />
                <div>
                  <H4>Outbound Message Color</H4>
                </div>
              </StyledExpansionPanelSummary>

              <StyledExpansionPanelDetails>
                <P>
                  Select a color for the oubound messages in your conversation
                  threads!
                </P>
                <StyledCirclePicker
                  disableAlpha
                  colors={OUTBOUND_MESSAGE_COLORS}
                  color={outboundMessageColor.value}
                  onChange={(color) => {
                    return setFieldValue(
                      "settings.outboundMessageColor.value",
                      color.hex,
                    );
                  }}
                />
              </StyledExpansionPanelDetails>
            </ExpansionPanel>

            <StyledSubmitRow>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={false}
                onClick={() => {
                  return forcePageRefresh();
                }}
              >
                Save
              </Button>
            </StyledSubmitRow>
          </Settings>
          <Preview
            palette={palette}
            currentUser={currentUser}
            logo={temporaryLogo || buildLogoUrl(account.logo)}
          />
        </FormContent>
      </StyledForm>
    );
  };

  renderDarkModeInformation = () => {
    return (
      <DarkModeBlankState
        image={
          <span role="img" aria-label="Moon Emoji">
            🌙
          </span>
        }
        title="Attention dark mode user!"
        subTitle={
          <P>
            White Label themes take precendence over dark mode and the default
            TextUs theme. You will need to switch back to the default TextUs
            theme in order to update your White Label settings. Once applied
            users on this account will not be able to access dark mode.
          </P>
        }
      />
    );
  };

  render() {
    const { appColors, toggleSidebar, account, handleSubmit } = this.props;

    const initialValues = {
      settings: Object.keys(appColors).reduce((acc, key) => {
        return {
          ...acc,
          [key]: {
            value: appColors[key],
          },
        };
      }, {}),
    };

    return (
      <Root>
        <PageHeader title="White label" toggleSidebar={toggleSidebar}>
          <LockForm
            account={account}
            handleSubmit={handleSubmit}
            settings={settings.map((setting) => {
              return setting.name;
            })}
          />
        </PageHeader>
        {get(appColors, ["type"]) === "dark" ? (
          this.renderDarkModeInformation()
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={WhiteLabelValidationSchema}
            onSubmit={this.handleSubmit}
          >
            {this.renderForm}
          </Formik>
        )}
      </Root>
    );
  }
}

export default WhiteLabel;
