import { useEffect } from "react";
import {
  Alert,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { BaseDrawer } from "../BaseDrawer";
import { EnrollDrawerContact, EnrollContactsDrawerProps } from "./types";
import { SearchBox } from "components/SearchBox";
import { useEnrollContactsDrawer } from "features/Sequences/hooks/useEnrollContactsDrawer";
import { getSequenceStepDelay } from "features/Sequences/screens/IndividualSequenceOverview/utils";
import { useTimeZones } from "hooks";

export function EnrollContactsDrawer({
  hideBackdrop,
  isOpen,
  selectedEnrollees,
  sequenceStepOne,
  handleConfirm,
  handleClose,
  setSelectedEnrollees,
}: EnrollContactsDrawerProps) {
  // ==== HOOKS ==== //
  const { accountTimeZone } = useTimeZones();

  const {
    contactList,
    currentAccount,
    debouncedSearch,
    isLoading,
    isLoadingNextPage,
    nextPage,
    searchTerm,
    totalItems,
    fetchNextRow,
    getContacts,
    handleClear,
    handleSearch,
  } = useEnrollContactsDrawer();

  useEffect(() => {
    if (debouncedSearch) {
      getContacts(currentAccount, debouncedSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  // ==== METHODS ==== //
  const getIsChecked = (id: string) => {
    return typeof selectedEnrollees === "string"
      ? false
      : Boolean(
          selectedEnrollees.find((selectedContact) => {
            return selectedContact === id;
          }),
        );
  };

  const handleToggle = (contact: EnrollDrawerContact): void => {
    if (typeof selectedEnrollees === "string") {
      return;
    }

    const foundContact = selectedEnrollees.find((selectedContact) => {
      return selectedContact === contact.id;
    });

    if (!foundContact) {
      setSelectedEnrollees([...selectedEnrollees, contact.id]);
    } else {
      setSelectedEnrollees(
        selectedEnrollees.filter((selectedContact) => {
          return selectedContact !== contact.id;
        }),
      );
    }
  };

  const handleClearAll = () => {
    setSelectedEnrollees([]);
    handleClear();
  };

  const handleCancel = () => {
    handleClose();
    handleClearAll();
  };

  const renderLoadingBar = () => {
    return (
      <Box
        padding={1}
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Skeleton width="100%" />
        <Skeleton width="100%" />
      </Box>
    );
  };

  const noSelections = selectedEnrollees.length === 0;

  const buttonProps = {
    clear: {
      disabled: false,
      visible: !noSelections,
    },
    confirm: {
      disabled: noSelections,
      visible: true,
    },
  };

  const emptyText = searchTerm ? "No contacts found" : "No results yet";

  // ==== RENDER ==== //
  return (
    <BaseDrawer
      title="Enroll contacts"
      isOpen={isOpen}
      buttonProps={buttonProps}
      confirmText={
        selectedEnrollees.length === 0
          ? "Confirm"
          : `Confirm (${selectedEnrollees.length})`
      }
      handleClose={handleCancel}
      handleClear={handleClearAll}
      handleCancel={handleCancel}
      handleConfirm={() => {
        handleConfirm();
        handleClear();
      }}
      hideBackdrop={hideBackdrop}
    >
      {/* ==== SEARCH BAR ==== */}
      <Box
        className="search-bar-wrapper"
        sx={{
          alignItems: "center",
          borderBottom: "1px solid #ccc",
          display: "flex",
          height: "3rem",
        }}
      >
        <Box width="100%" margin={1}>
          <SearchBox
            data-testid="enroll-contacts-drawer-search"
            value={searchTerm}
            onClear={handleClear}
            onChange={handleSearch}
            placeholder="Search name, tags, phone number"
            size="small"
            sx={{
              margin: "0",
              "& fieldset": {
                border: "none",
              },
              "& .MuiInputBase-root": {
                paddingLeft: 0,
              },
            }}
          />
        </Box>
      </Box>

      <Box
        className="search-results-wrapper"
        sx={{
          flex: "1 1 auto",
          overflowY: "scroll",
        }}
        id="scrollableList" // for <InfiniteScroll>
      >
        {/* ==== LIST OF CONTACTS FOUND ==== */}
        <InfiniteScroll
          dataLength={contactList.length}
          hasMore={totalItems > contactList.length}
          loader={isLoadingNextPage ? renderLoadingBar() : null}
          next={() => {
            fetchNextRow(currentAccount, nextPage);
          }}
          scrollableTarget="scrollableList"
        >
          <List
            className="contacts-list"
            sx={{
              flex: "1 1 auto",
              padding: 0,
            }}
          >
            {contactList.map((contact) => {
              const {
                contact: contactDetails,
                id,
                formattedPhoneNumber,
              } = contact;
              return (
                <ListItem key={id}>
                  <Checkbox
                    data-testid={id}
                    edge="start"
                    checked={getIsChecked(id)}
                    onClick={() => {
                      return handleToggle(contact);
                    }}
                  />
                  <ListItemText primary={contactDetails.name} />
                  <ListItemText
                    primary={formattedPhoneNumber}
                    sx={{
                      textAlign: "right",
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        </InfiniteScroll>

        {/* ==== LOADING BARS ===== */}
        {isLoading ? renderLoadingBar() : null}

        {/* ==== NOT LOADING + NO CONTACTS FOUND ==== */}
        {contactList.length === 0 && !isLoading ? (
          <Box
            display="flex"
            height="100%"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body1">{emptyText}</Typography>
          </Box>
        ) : null}
      </Box>

      {/* ==== BOTTOM ALERT ==== */}
      {contactList.length > 0 ? (
        <Alert
          severity="info"
          sx={{
            margin: "1rem",
          }}
        >
          Step #1 will be sent{" "}
          {`${
            sequenceStepOne.absoluteTime ? "on" : "in"
          } ${getSequenceStepDelay(sequenceStepOne, accountTimeZone)}`}{" "}
          if active
        </Alert>
      ) : null}
    </BaseDrawer>
  );
}
