import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import withSizes from "react-sizes";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Grid from "@mui/material/Grid2";
import {
  AggregatePlanCard,
  AnalyticsDetailReportProps,
} from "../models/AnalyticsModels";
import { useDetailReport } from "../hooks/useDetailReport";
import AnalyticsTable from "./AnalyticsTable";
import AnalyticsDetailReportHeader from "./AnalyticsDetailReportHeader";
import AnalyticsCard from "./AnalyticsCard";
import withRecord from "higherOrderComponents/withRecord";
import breakpoints from "utils/styles/breakpoints";
import ErrorComponent from "components/ErrorComponent";

function AnalyticsDetailReport({
  analyticsReportId,
  aggregatePlanUrl = undefined,
  billingReport = false,
  currentAccount,
  currentUser,
  fetchAnalyticsReportsRequest,
  reportName,
  reportType,
  scheduledReports,
  setScheduledReports,
}: AnalyticsDetailReportProps) {
  const {
    analyticReport,
    analyticsError,
    existingReports,
    loading,
    aggregatePlanCards,
    fetchAggregatePlan,
    fetchAnalyticsReportsErrorCallback,
    fetchAnalyticsReportsSuccessCallback,
  } = useDetailReport({
    currentAccount,
    currentUser,
    reportName,
  });

  useEffect(() => {
    fetchAnalyticsReportsRequest(analyticsReportId, null, {
      successCallback: fetchAnalyticsReportsSuccessCallback,
      errorCallback: fetchAnalyticsReportsErrorCallback,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsReportId]);

  useEffect(() => {
    // Ensures that we only try to fetch on correct page (/billing).
    if (!aggregatePlanUrl) {
      return;
    }

    fetchAggregatePlan(aggregatePlanUrl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregatePlanUrl]);

  if (loading) {
    return (
      <Box display="flex" m={4} justifyContent="space-around" aria-busy>
        <CircularProgress aria-label="analytics-detail-report-progress-bar" />
      </Box>
    );
  }
  return (
    <>
      {analyticsError && (
        <ErrorComponent
          title="Sorry! Analytics is unavailable."
          subtitle="Try again later."
        />
      )}

      {aggregatePlanCards.length > 0 ? (
        <Box mt={4}>
          <Grid
            aria-label="Analytics Card Display"
            container
            spacing={2}
            alignContent="center"
          >
            {aggregatePlanCards.map((card: AggregatePlanCard) => {
              return (
                <AnalyticsCard
                  key={card.title}
                  analytic={card.value}
                  analyticTitle={card.title}
                  grid={{
                    lg: 3,
                  }}
                />
              );
            })}
          </Grid>
        </Box>
      ) : null}

      {analyticReport && (
        <>
          <Box display="flex" justifyContent="space-between">
            <AnalyticsDetailReportHeader
              analyticReport={analyticReport}
              billingReport={billingReport}
              currentAccount={currentAccount}
              currentUser={currentUser}
              existingReports={existingReports}
              reportName={reportName}
              reportType={reportType}
              scheduledReports={scheduledReports}
              setScheduledReports={setScheduledReports}
            />
          </Box>
          <AnalyticsTable
            billingReport={billingReport}
            report={analyticReport}
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = () => {
  return {};
};

const withConnect = connect(() => {
  return mapStateToProps;
});

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/AccountAnalytics/AnalyticsReports",
    multiple: true,
    noFetch: true,
    shape: { data: [], headers: [] },
    showLoader: () => {
      return false;
    },
    type: "analyticsReports",
  }),
  withSizes(({ width }) => {
    return { fullScreen: width < breakpoints.medium };
  }),
  withConnect,
)(AnalyticsDetailReport);
