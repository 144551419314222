/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Fab,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid-pro";
import KeywordTable from "./KeywordTable";
import PageContent from "components/Page/PageContent";
import PageHeader from "components/Page/PageHeader";
import { KeywordsProps } from "features/Keywords/types";
import { SearchBox } from "components/SearchBox";
import { useKeywordAutomation } from "features/Automation/hooks/useKeywordAutomation";
import { useDebouncedSearch } from "hooks";
import { AddIcon } from "icons";
import { SECONDARY_NAV_LINKS } from "features/Automation/constants/AutomationConstants";

function Keywords({
  isSinglePanel,
  keywords,
  selectedKeywordNavLink,
  selectedKeywordTab,
  setKeywords,
  showSidebar,
  handleTabChange,
  toggleSidebar,
}: KeywordsProps) {
  /* STATE */
  const [paginationModel, setPaginationModel] = useState({
    page: Number(new URLSearchParams(window.location.search).get("page")) || 0,
    pageSize: 10,
  });

  /* HOOKS */
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const history = useHistory();

  const {
    handleFetchAutomationKeywords,
    isLoadingKeywords,
    keywordsPage,
    setKeywordsPage,
  } = useKeywordAutomation(keywords, setKeywords);

  const { debouncedSearchTerm, searchTerm, handleSearch, handleClear } =
    useDebouncedSearch();

  /* EFFECTS */
  useEffect(() => {
    const getPage = async () => {
      await handleFetchAutomationKeywords({
        active: selectedKeywordTab === 0,
        items: paginationModel.pageSize,
        keywordCont: debouncedSearchTerm,
        mine: selectedKeywordNavLink === SECONDARY_NAV_LINKS.mine,
      });
    };
    getPage().catch((error) => {
      console.error(error);
    });
  }, [
    debouncedSearchTerm,
    keywordsPage,
    selectedKeywordTab,
    selectedKeywordNavLink,
  ]);

  useEffect(() => {
    if (mobileScreen) {
      // Clear debouncedSearchTerm when switching to mobile view
      handleClear();
    }
  });

  /* FUNCTIONS */
  const handlePageChange = (newPaginationModel: GridPaginationModel) => {
    setKeywordsPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  const handleCreateClick = () => {
    // Path: /automation/keywords/new
    history.push(`${window.location.pathname}/new`);
  };

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader
        aria-label="Keywords"
        title="Keywords"
        toggleSidebar={toggleSidebar}
        displaySearch={mobileScreen}
        toggleSearch={() => {
          // Path: /automation/keywords/search
          history.push(`${window.location.pathname}/search`);
        }}
      />
      {mobileScreen ? (
        <Box position="absolute" right="25px" bottom="50px">
          <Fab
            aria-label="Add Keyword"
            data-testid="create-keyword-fab"
            color="primary"
            onClick={handleCreateClick}
          >
            <AddIcon
              sx={{
                width: 28,
                height: 28,
              }}
            />
          </Fab>
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            gap="8px"
            justifyContent="space-between"
            sx={{ margin: "8px 16px" }}
          >
            <SearchBox
              value={searchTerm}
              onClear={handleClear}
              onChange={handleSearch}
              placeholder="Search keywords"
              slotProps={{ input: { sx: { height: "40px" } } }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "124px",
                paddingLeft: "16px",
              }}
            >
              <Button
                data-testid="create-keyword-button"
                size="large"
                variant="contained"
                startIcon={<AddIcon fontSize="small" />}
                onClick={handleCreateClick}
              >
                Keyword
              </Button>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      <Tabs value={selectedKeywordTab} onChange={handleTabChange}>
        <Tab label="Active (#)" />
        <Tab label="Archive (#)" />
      </Tabs>
      <Divider />
      <KeywordTable
        showingActiveKeywords={selectedKeywordTab === 0}
        debouncedSearchTerm={debouncedSearchTerm}
        handlePageChange={handlePageChange}
        isLoadingKeywords={isLoadingKeywords}
        keywords={keywords}
        paginationModel={paginationModel}
        sx={mobileScreen ? { height: "calc(100vh - 166px)" } : {}} // Keeps pagination footer pinned to bottom
      />
    </PageContent>
  );
}

export default Keywords;
