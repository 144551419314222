import { Fragment, useState } from "react";
import { Typography, Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledChip,
  StyledDivider,
} from "./components";
import {
  getSequenceStepAccordionHeading,
  getSequenceStepDelay,
} from "features/Sequences/screens/IndividualSequenceOverview/utils";
import { SequenceStepAccordionProps } from "props/SequenceStepAccordionProps";
import { useTimeZones } from "hooks";

export function SequenceStepAccordion({
  index,
  menuDisabled,
  needsScheduling,
  step,
  handleDeleteStep,
  handleEditStep,
}: SequenceStepAccordionProps) {
  // ==== FIELDS ==== //
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUserHovering, setIsUserHovering] = useState(false);

  const { accountTimeZone } = useTimeZones();

  const menuOpen = Boolean(anchorEl);

  const isAbsoluteSequence = Boolean(step.absoluteTime || needsScheduling);

  const hasAttachment = Boolean(step.attachments?.length);

  const chips: [number, string][] = [
    [step.finishedCount, "finished"],
    [step.pendingCount, "pending"],
    [step.failedCount, "failed"],
    [step.omittedCount, "omitted"],
  ];

  const stats: [number, string][] = [
    [isUserHovering ? step.deliveredCount : step.deliveryRate, "Delivered"],
    [isUserHovering ? step.respondedCount : step.respondedRate, "Responded"],
    [isUserHovering ? step.failedCount : step.failedRate, "Failed"],
  ];

  // ==== METHODS ==== //

  /**
   * Render a string based on the current schedule status.
   *
   * @returns {React.Element} A Typography component with formatted text showing the schedule status.
   */
  const renderScheduleString = () => {
    return step.absoluteTime === null &&
      Object.keys(step.relativeDelay).length === 0 ? (
      <Typography mr="20px" fontSize="13px" color="error">
        Delay not scheduled
      </Typography>
    ) : (
      <Typography mr="20px" fontSize="13px" color="rgba(0, 0, 0, 0.7)">
        {isAbsoluteSequence
          ? `Delay until ${formatInTimeZone(
              new Date(step.absoluteTime),
              accountTimeZone,
              "eeee, LLL do, yyyy, h:mm a zzz",
            )}`
          : `Delay by ${getSequenceStepDelay(step, accountTimeZone, "short")}`}
      </Typography>
    );
  };

  const handleMenuClick = (ev: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(ev.currentTarget);
    ev.stopPropagation();
  };

  const handleMenuClose = (ev: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(null);
    ev.stopPropagation();
  };

  const handleEditStepClick = (ev: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(null);
    handleEditStep();
    ev.stopPropagation();
  };

  const handleDeleteStepClick = (ev: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(null);
    handleDeleteStep();
    ev.stopPropagation();
  };

  const toggleIsUserHovering = () => {
    setIsUserHovering(!isUserHovering);
  };

  // ==== RENDER ==== //
  return (
    <StyledAccordion>
      <StyledAccordionSummary>
        <Typography component="span" variant="h6" mr="16px">
          {getSequenceStepAccordionHeading(step, needsScheduling)}
        </Typography>

        <Box flex="1 1 auto">
          {chips.map(([count, title]) => {
            if (count > 0) {
              return (
                <StyledChip
                  key={title}
                  className={title}
                  label={`${count} ${title}`}
                />
              );
            }

            return null;
          })}
        </Box>
        {renderScheduleString()}
        <IconButton
          aria-controls={menuOpen ? `sequence-step-menu-${index}` : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          disabled={menuDisabled}
          onClick={handleMenuClick}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id={`sequence-step-menu-${index}`}
          aria-labelledby={`sequence-step-menu-${index}`}
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          sx={{
            "& .MuiPaper-root": {
              width: "172px",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={handleEditStepClick}
            data-testid="edit-sequence-step"
          >
            Edit step
          </MenuItem>
          <MenuItem
            onClick={handleDeleteStepClick}
            data-testid="delete-sequence-step"
            sx={(theme) => {
              return {
                "&.MuiButtonBase-root": {
                  color: theme.palette.error.main,
                },
              };
            }}
          >
            Delete step
          </MenuItem>
        </Menu>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="0.5rem"
        >
          {hasAttachment ? <AttachFileIcon fontSize="small" /> : null}
          <Typography
            sx={{
              maxWidth: "675px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {step.body}
          </Typography>
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: "1 1 auto",
            justifyContent: "flex-end",
          }}
        >
          <Box
            data-testid="sequence-step-stats"
            display="flex"
            flexDirection="row"
            gap="16px"
            onMouseEnter={toggleIsUserHovering}
            onMouseLeave={toggleIsUserHovering}
          >
            {stats.map(([stat, title], idx) => {
              return (
                <Fragment key={title}>
                  <Box textAlign="center">
                    <Typography fontWeight={700}>
                      {stat || 0}
                      {`${isUserHovering ? "" : "%"}`}
                    </Typography>
                    <Typography>{title}</Typography>
                  </Box>

                  {idx !== stats.length - 1 && <StyledDivider />}
                </Fragment>
              );
            })}
          </Box>
        </Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
