/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable camelcase */
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { formatInTimeZone } from "date-fns-tz";
import { updateIntegration } from "../../api/updateIntegration";
import { IntegrationDetailsContactsTabProps } from "./types";
import { SettingsCard, SettingsCardContent } from "features/Settings";
import { InfoOutlineIcon } from "icons";
import { useTimeZones } from "hooks";

export function IntegrationDetailsContactsTab({
  integrationState,
  setUpdatedIntegrationData,
  updatedIntegrationData,
}: IntegrationDetailsContactsTabProps) {
  const [initialImportCheckBoxes, setInitialImportCheckBoxes] = useState({
    updated_within_days: {
      enabled: false,
      value: 30,
      options: [30, 60, 90],
      label: "All candidates updated in the last:",
    },
    created_within_days: {
      enabled: false,
      value: 30,
      options: [30, 60, 90],
      label: "All candidates created in the last:",
    },
    owned: {
      enabled: false,
      label: "All candidates with a matching TextUs Owner",
    },
  });

  const largeScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("lg");
  });

  const { enqueueSnackbar } = useSnackbar();

  const { accountTimeZone } = useTimeZones();

  /* 
  The below method is coming in a future Opt-out feature work and is not implemented yet 
  */
  // const renderBullhornOptOutsCard = () => {
  //   return (
  //     <SettingsCard sx={{ width: "100%", minWidth: "288px" }}>
  //       <SettingsCardContent
  //         sx={{
  //           "&:last-child": {
  //            paddingY: "16px",
  //           }
  //         }}
  //       >
  //         <Box
  //           alignItems="center"
  //           display="flex"
  //           justifyContent="space-between"
  //           paddingBottom="24px"
  //         >
  //           <Box display="flex">
  //             <Typography variant="h5" gutterBottom>
  //               Opt-outs: {integrationState?.providerName}
  //             </Typography>
  //             <ArrowForwardIcon
  //               sx={{
  //                 marginX: "8px",
  //               }}
  //             />
  //             <Typography variant="h5" gutterBottom>
  //               TextUs
  //             </Typography>
  //           </Box>
  //           <Chip
  //             color={updatedIntegrationData?.active ? "secondary" : "default"}
  //             label={updatedIntegrationData?.active ? "Active" : "Paused"}
  //             size="small"
  //           />
  //         </Box>
  //         <Typography variant="body2">
  //           Changes to opt-out/opt-in status of any Candidates, Contacts, or
  //           Leads records in Bullhorn will be reflected in the corresponding
  //           TextUs contact. Similarly, if a change in status occurs in TextUs,
  //           it will sync back to the Bullhorn record.
  //         </Typography>
  //       </SettingsCardContent>
  //     </SettingsCard>
  //   );
  // };

  const handleInitialImport = async () => {
    try {
      const { updated_within_days, created_within_days, owned } =
        initialImportCheckBoxes;
      const body = {
        settings: {
          ...updatedIntegrationData?.settings,
          contact_schemas_enabled: true,
          initial_contact_import: {
            enabled: true,
            owned: owned.enabled,
            created_within_days: created_within_days.enabled
              ? created_within_days.value
              : undefined,
            updated_within_days: updated_within_days.enabled
              ? updated_within_days.value
              : undefined,
          },
        },
      };
      const response = await updateIntegration(
        updatedIntegrationData?.id ?? "",
        body,
      );
      if (!response.ok) {
        throw new Error("Cannot fetch available integrations");
      }
      const data = await response.json();
      setUpdatedIntegrationData(data);
    } catch {
      enqueueSnackbar("Failed to initialize import.", {
        variant: "error",
      });
    }
  };

  const handleContactSyncToggle = async () => {
    try {
      const body = {
        settings: {
          ...updatedIntegrationData?.settings,
          contact_schemas_enabled:
            !updatedIntegrationData?.settings?.contact_schemas_enabled,
        },
      };
      const response = await updateIntegration(
        updatedIntegrationData?.id ?? "",
        body,
      );
      if (!response.ok) {
        throw new Error("Cannot fetch available integrations");
      }
      const data = await response.json();
      setUpdatedIntegrationData(data);
      enqueueSnackbar(
        `Contact sync ${updatedIntegrationData?.settings?.contact_schemas_enabled ? "disabled" : "activated"}.`,
        {
          variant: "info",
        },
      );
    } catch {
      enqueueSnackbar("Failed to toggle contact sync.", {
        variant: "error",
      });
    }
  };

  const renderBullhornInitialImportCard = () => {
    const { initiated } =
      updatedIntegrationData?.settings?.initial_contact_import ?? {};
    return (
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: largeScreen ? "row" : "column",
          justifyContent: "space-between",
          paddingTop: "16px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            minWidth: largeScreen ? "18rem" : "0rem",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Initial import
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <SettingsCard
            sx={{
              width: "100%",
              minWidth: "288px",
              marginBottom: "48px",
            }}
          >
            <SettingsCardContent
              sx={{
                "&:last-child": {
                  paddingY: "16px",
                },
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "24px",
                }}
              >
                <Box display="flex">
                  <Typography variant="h5" gutterBottom>
                    {`${integrationState?.providerName} (Candidates)`}
                  </Typography>
                  <ArrowForwardIcon
                    sx={{
                      marginX: "8px",
                    }}
                  />
                  <Typography variant="h5" gutterBottom>
                    TextUs
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body2">
                A one-time initial import of Candidate records will create
                TextUs contacts. Any duplicates within Bullhorn will also be
                imported into TextUs.
              </Typography>
              <Divider sx={{ marginY: "16px" }} />
              {initiated ? (
                <Typography variant="body2">
                  Initial import completed on{" "}
                  {formatInTimeZone(
                    new Date(initiated),
                    accountTimeZone,
                    "MMM. dd, yyyy 'at' hh:mm a z",
                  )}
                  .
                </Typography>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {Object.entries(initialImportCheckBoxes).map((entry) => {
                    const [key, value] = entry;
                    if (value.options) {
                      return (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          key={key}
                          paddingBottom="16px"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value.enabled}
                                color="secondary"
                                disabled={
                                  !updatedIntegrationData?.active ||
                                  !updatedIntegrationData?.settings
                                    ?.contact_schemas_enabled
                                }
                                onChange={(event) => {
                                  setInitialImportCheckBoxes({
                                    ...initialImportCheckBoxes,
                                    [key]: {
                                      ...value,
                                      enabled: event.target.checked,
                                    },
                                  });
                                }}
                              />
                            }
                            label={value.label}
                          />
                          <TextField
                            disabled={
                              !updatedIntegrationData?.active ||
                              !updatedIntegrationData?.settings
                                ?.contact_schemas_enabled
                            }
                            key={key}
                            label="Days"
                            onChange={(event) => {
                              setInitialImportCheckBoxes({
                                ...initialImportCheckBoxes,
                                [key]: {
                                  ...value,
                                  value: event.target.value,
                                },
                              });
                            }}
                            select
                            SelectProps={{
                              native: true,
                              inputProps: { "aria-label": "Batch Frequency" },
                            }}
                            sx={{ maxWidth: "128px", width: "100%" }}
                            defaultValue={value.value}
                            variant="outlined"
                          >
                            {value.options.map((option) => {
                              return (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              );
                            })}
                          </TextField>
                        </Box>
                      );
                    }
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            checked={value.enabled}
                            disabled={
                              !updatedIntegrationData?.active ||
                              !updatedIntegrationData?.settings
                                ?.contact_schemas_enabled
                            }
                            color="secondary"
                            onChange={(event) => {
                              setInitialImportCheckBoxes({
                                ...initialImportCheckBoxes,
                                [key]: {
                                  ...value,
                                  enabled: event.target.checked,
                                },
                              });
                            }}
                          />
                        }
                        label={value.label}
                      />
                    );
                  })}
                  <Button
                    color="primary"
                    disabled={
                      !initialImportCheckBoxes.owned.enabled &&
                      !initialImportCheckBoxes.created_within_days.enabled &&
                      !initialImportCheckBoxes.updated_within_days.enabled
                    }
                    variant="outlined"
                    sx={{
                      alignSelf: "flex-end",
                      marginTop: "16px",
                    }}
                    onClick={() => {
                      return handleInitialImport();
                    }}
                  >
                    Import
                  </Button>
                </Box>
              )}
            </SettingsCardContent>
          </SettingsCard>
        </Box>
      </Box>
    );
  };

  const renderBanner = () => {
    return updatedIntegrationData?.active ? (
      <Alert
        severity="info"
        icon={<InfoOutlineIcon />}
        sx={{
          marginBottom: "16px",
        }}
      >
        <Typography variant="body2">
          Sync remains active unless the integration is paused or removed.
        </Typography>
      </Alert>
    ) : (
      <Alert
        severity="warning"
        sx={{
          marginBottom: "16px",
        }}
      >
        <Typography variant="body2">
          Integration paused. Resume to continue syncing contacts.
        </Typography>
      </Alert>
    );
  };

  const renderHeaderChip = () => {
    if (
      updatedIntegrationData?.providerName === "Bullhorn" &&
      !updatedIntegrationData?.settings?.contact_schemas_enabled
    ) {
      return <Chip color="default" label="Disabled" size="small" />;
    }
    return (
      <Chip
        color={updatedIntegrationData?.active ? "secondary" : "default"}
        label={updatedIntegrationData?.active ? "Active" : "Paused"}
        size="small"
      />
    );
  };

  const renderContactSyncSwitch = () => {
    return (
      <Switch
        checked={updatedIntegrationData?.settings?.contact_schemas_enabled}
        color="secondary"
        disabled={!updatedIntegrationData?.active}
        onChange={() => {
          handleContactSyncToggle();
        }}
      />
    );
  };

  return (
    <>
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: largeScreen ? "row" : "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            minWidth: largeScreen ? "18rem" : "0rem",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Contact sync
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <SettingsCard sx={{ width: "100%", minWidth: "288px" }}>
            <SettingsCardContent
              sx={{
                "&:last-child": {
                  paddingY: "16px",
                },
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "24px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {integrationState?.providerName === "Bullhorn" &&
                    renderContactSyncSwitch()}
                  <Typography variant="h5" gutterBottom>
                    {`${integrationState?.providerName} (Candidates)`}
                  </Typography>
                  <ArrowForwardIcon
                    sx={{
                      marginX: "8px",
                    }}
                  />
                  <Typography variant="h5" gutterBottom>
                    TextUs
                  </Typography>
                </Box>
                {renderHeaderChip()}
              </Box>
              {renderBanner()}
              <Typography variant="body2">
                Newly created or updated candidate records within{" "}
                {integrationState?.providerName} will also create or update a
                corresponding TextUs contact.
              </Typography>
            </SettingsCardContent>
          </SettingsCard>
        </Box>
      </Box>
      {integrationState?.providerName === "Bullhorn" &&
        // The below method is coming in a future Opt-out feature work
        // renderBullhornOptOutsCard()
        renderBullhornInitialImportCard()}
    </>
  );
}
