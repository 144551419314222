import {
  Button,
  Divider,
  FormHelperText,
  Grid2 as Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Field, Form, FieldProps, FormikHelpers } from "formik";
import { Google, Mail } from "@mui/icons-material";
import { useCallback } from "react";
import { useSnackbar } from "notistack";
import getIsClockInaccurate from "./containers/LoginScreen/utils/getIsClockInaccurate";
import { PasswordLoginSchema } from "formHelpers/validationSchemas";
import Page from "features/Authentication/components/Page";
import { BullhornIcon } from "icons";

const UnstyledButton = styled("button")({});

declare global {
  interface Window {
    branding: {
      labeling: {
        company_name: string;
      };
    };
  }
}

type LoginFormValues = {
  email: string;
  password: string;
};

type ForgotPasswordFormValues = {
  email: string;
};

type Props = {
  onLogin: (params: {
    email: string;
    password: string;
    errorCallback: (error: {
      response?: {
        detail?: string;
      };
    }) => void;
  }) => void;
  onResetPassword: (
    formData: ForgotPasswordFormValues,
    actions: FormikHelpers<ForgotPasswordFormValues>,
  ) => void;
};

export function LoginPage({ onLogin, onResetPassword }: Props) {
  const snackbar = useSnackbar();

  const handleLogin = useCallback(
    (formData: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
      onLogin({
        email: formData.email,
        password: formData.password,
        errorCallback: (error) => {
          snackbar.enqueueSnackbar(
            error?.response?.detail ??
              "There was a problem with your credentials",
            {
              variant: "error",
            },
          );

          actions.setSubmitting(false);
          actions.resetForm();
        },
      });
    },
    [onLogin, snackbar],
  );

  const isSidePanel = window.top !== window.self;

  return (
    <Page>
      <Formik<LoginFormValues>
        initialValues={{ email: "", password: "" }}
        validationSchema={PasswordLoginSchema}
        onSubmit={handleLogin}
      >
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <Field name="email">
                {({ field, form }: FieldProps<string>) => {
                  return (
                    <TextField
                      {...field}
                      autoComplete="email"
                      disabled={isSubmitting}
                      error={touched.email && Boolean(errors.email)}
                      fullWidth
                      helperText={touched.email ? errors.email || "" : ""}
                      id="email"
                      label="Email"
                      onChange={({ target: { value } }) => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        form.setFieldValue(field.name, value);
                        // this.updateAllowedLoginMethods(value);
                      }}
                      placeholder="Please enter your email"
                      sx={{ marginBottom: 2 }}
                      type="email"
                    />
                  );
                }}
              </Field>

              <Field name="password">
                {({ field }: FieldProps<string>) => {
                  return (
                    <TextField
                      {...field}
                      autoComplete="current-password"
                      disabled={isSubmitting}
                      error={touched.password && Boolean(errors.password)}
                      fullWidth
                      id="password"
                      label="Password"
                      type="password"
                    />
                  );
                }}
              </Field>
              <Grid
                sx={{
                  display: "grid",
                  gridAutoFlow: "column",
                }}
              >
                {touched.password && Boolean(errors.password) && (
                  <FormHelperText
                    sx={{
                      marginLeft: "14px",
                    }}
                    error
                  >
                    {errors.password}
                  </FormHelperText>
                )}
                <UnstyledButton
                  type="button"
                  onClick={() => {
                    alert("Password reset");
                  }}
                  sx={(theme) => {
                    return {
                      marginTop: 1,
                      color: theme.palette.text.hyperlink,
                      justifySelf: "flex-end",
                      "&:hover": {
                        textDecoration: "underline",
                        color: theme.palette.lightBlue[900],
                      },

                      "&:focus": {
                        outline: theme.palette.text.hyperlink,
                        outlineOffset: "2px",
                        color: theme.palette.lightBlue[900],
                      },
                    };
                  }}
                >
                  <Typography component="span" variant="body2">
                    Forgot Password?
                  </Typography>
                </UnstyledButton>
              </Grid>
              <Button
                variant="contained"
                size="large"
                aria-label="Sign in"
                data-testid="sign-in-button"
                type="submit"
                disabled={isSubmitting || getIsClockInaccurate()}
                sx={{
                  marginTop: 3,
                }}
              >
                Sign in
              </Button>
            </Form>
          );
        }}
      </Formik>
      <Grid
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
          alignItems: "center",
          gap: 1,
          paddingY: "12px",
          width: "100%",
        }}
      >
        <Divider />
        <Typography
          variant="overline"
          sx={(theme) => {
            return {
              textTransform: "uppercase",
              color: theme.palette.text.secondary,
            };
          }}
        >
          or
        </Typography>
        <Divider />
      </Grid>
      <Stack
        spacing={2}
        sx={{
          width: "100%",
        }}
      >
        {window.branding.labeling.company_name === "Bullhorn" && (
          <form
            target={isSidePanel ? "_blank" : "_self"}
            name="bullhorn auth"
            method={isSidePanel ? "get" : "post"}
            action={isSidePanel ? "/" : "/auth/bullhorn"}
            aria-label="Bullhorn Sign in Form"
          >
            <Button
              fullWidth
              data-testid="BullhornLoginStarted"
              aria-label="Bullhorn Sign in Button"
              startIcon={
                <BullhornIcon
                  sx={{
                    fill: "#FF6B00",
                  }}
                />
              }
              variant="outlined"
              size="large"
              type="submit"
            >
              Sign in with Bullhorn
            </Button>
            <input
              type="hidden"
              name="authenticity_token"
              value={
                document.head
                  .querySelector("[name=csrf-token]")!
                  .getAttribute("content")!
              }
            />
          </form>
        )}
        <Button
          data-testid="passwordless-sign-in-button"
          fullWidth
          startIcon={<Mail />}
          variant="outlined"
          size="large"
          onClick={() => {
            alert("Send a login link");
          }}
        >
          Send a login link
        </Button>
        <form
          name="google_auth"
          target={isSidePanel ? "_blank" : "_self"}
          method={isSidePanel ? "get" : "post"}
          action={isSidePanel ? "/" : "/auth/google_oauth2"}
          aria-label="Google Sign in Form"
        >
          <Button
            data-testid="google-sign-in-button"
            fullWidth
            startIcon={<Google />}
            variant="outlined"
            size="large"
            type="submit"
          >
            Sign in with Google
          </Button>
          <input
            type="hidden"
            name="authenticity_token"
            value={
              document.head
                .querySelector("[name=csrf-token]")
                ?.getAttribute("content") ?? ""
            }
          />
        </form>
      </Stack>
    </Page>
  );
}
