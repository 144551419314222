import PropTypes from "prop-types";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function ReportHeader({ openScheduleModal }) {
  return (
    <Box display="flex" justifyContent="space-between" mb={4}>
      <Box>
        <Typography component="h3" variant="h5">
          Scheduled analytics
        </Typography>
      </Box>
      <Box flex="0 0 auto">
        <Button
          color="primary"
          onClick={openScheduleModal}
          variant="contained"
          aria-label="Add Report"
        >
          <Box paddingRight="5px">
            <AddIcon />
          </Box>
          Add Report
        </Button>
      </Box>
    </Box>
  );
}

ReportHeader.propTypes = {
  openScheduleModal: PropTypes.func.isRequired,
};

export default ReportHeader;
