import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import { Alert, TextField, Button } from "@mui/material";
import Loader from "components/Loader";
import PageHeader from "components/Page/PageHeader";
import { ApiTokensValidationSchema } from "formHelpers/validationSchemas";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";

const Tokens = styled.div`
  margin-top: 80px;
`;

const Token = styled.div`
  & + & {
    margin-top: 10px;
    padding-top: 10px;
    border-top: solid 1px
      ${(props) => {
        return props.theme.colors.divider;
      }};
  }

  form {
    width: 100%;
  }

  p {
    margin: 0;
  }
`;

const Description = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubmitSection = styled.section`
  margin-top: 40px;
  text-align: right;
`;

const Actions = styled.div`
  margin-left: 20px;
`;

const Warning = styled.p`
  margin: 0 0 20px 0;

  svg {
    margin-right: 10px;
  }
`;

const TokenInput = styled.input`
  width: 100%;
  margin-top: 10px;
  padding: 5px 10px;
  background: white;
  border-radius: 4px;
`;

class Root extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    userApiTokenCollection: PropTypes.object.isRequired,
    fetchUserApiTokenCollectionRequest: PropTypes.func.isRequired,
    createUserApiTokenRequest: PropTypes.func.isRequired,
    deleteUserApiTokenRequest: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    isMediumLarge: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      lastToken: null,
    };
  }

  handleCreate = (values, actions) => {
    this.props.createUserApiTokenRequest("/user_api_tokens", values, {
      successCallback: (response) => {
        return this.onCreateSuccess(response, actions);
      },
      errorCallback: () => {
        return this.onCreateError(actions);
      },
    });
  };

  handleDelete = (id, actions) => {
    this.props.deleteUserApiTokenRequest(id, null, {
      successCallback: () => {
        return this.onDeleteSuccess(actions);
      },
      errorCallback: () => {
        return this.onDeleteError(actions);
      },
    });
  };

  onCreateSuccess = (response, actions) => {
    this.setState({ lastToken: response });
    actions.setSubmitting(false);
    actions.setFieldValue("description", "");
    actions.resetForm();
    this.props.fetchUserApiTokenCollectionRequest("/user_api_tokens");
  };

  onCreateError = (actions) => {
    actions.setSubmitting(false);
  };

  onDeleteSuccess = (actions) => {
    actions.setSubmitting(false);
    this.props.fetchUserApiTokenCollectionRequest("/user_api_tokens");
  };

  onDeleteError = (actions) => {
    actions.setSubmitting(false);
  };

  isNewToken = (id) => {
    return this.state.lastToken && this.state.lastToken.id === id;
  };

  render() {
    return (
      <SettingsPageWrapper>
        <PageHeader
          title="API Tokens"
          toggleSidebar={this.props.toggleSidebar}
        />
        <SettingsPageContent isMediumLarge={this.props.isMediumLarge}>
          <Formik
            initialValues={{ description: "" }}
            validationSchema={ApiTokensValidationSchema}
            onSubmit={this.handleCreate}
          >
            {({ errors = {}, touched = {}, isSubmitting, isValid }) => {
              return (
                <Form>
                  <Field type="text" name="description">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          id="Profile-ApiTokens-description"
                          data-testid="profile-apiTokens-description"
                          type="text"
                          helperText={touched.description && errors.description}
                          label="Description"
                          error={
                            touched.description && Boolean(errors.description)
                          }
                        />
                      );
                    }}
                  </Field>
                  <SubmitSection>
                    <Button
                      aria-label="Create token"
                      data-testid="create-token"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                    >
                      <Loader isLoading={isSubmitting}>Create token</Loader>
                    </Button>
                  </SubmitSection>
                </Form>
              );
            }}
          </Formik>
          <Tokens>
            {[...this.props.userApiTokenCollection.members]
              .sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .map((userApiToken) => {
                return (
                  <Token
                    key={userApiToken.id}
                    className={this.isNewToken(userApiToken.id) ? "new" : null}
                  >
                    <Formik
                      validationSchema={ApiTokensValidationSchema}
                      initialValues={{ description: userApiToken.description }}
                      onSubmit={(values, actions) => {
                        return this.handleDelete(userApiToken.id, actions);
                      }}
                    >
                      {({ isSubmitting }) => {
                        return (
                          <Form>
                            <Wrapper>
                              <Description>
                                {userApiToken.description}
                              </Description>
                              <Actions>
                                <Button
                                  aria-label="Delete"
                                  data-testid="delete-button"
                                  type="submit"
                                  variant="outlined"
                                  color="primary"
                                  disabled={isSubmitting}
                                >
                                  <Loader isLoading={isSubmitting}>
                                    Delete
                                  </Loader>
                                </Button>
                              </Actions>
                            </Wrapper>
                            {this.isNewToken(userApiToken.id) && (
                              <Alert severity="warning" sx={{ marginTop: 2 }}>
                                <p>
                                  Copy this token and store it securely, as it
                                  won't be displayed again after leaving this
                                  page.
                                </p>
                                <TokenInput
                                  readOnly
                                  type="text"
                                  value={this.state.lastToken.accessToken}
                                  onClick={(event) => {
                                    return event.target.select();
                                  }}
                                />
                              </Alert>
                            )}
                          </Form>
                        );
                      }}
                    </Formik>
                  </Token>
                );
              })}
          </Tokens>
        </SettingsPageContent>
      </SettingsPageWrapper>
    );
  }
}

export default Root;
