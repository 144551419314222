import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { fontSize, styled } from "@mui/system";
import Typography from "@mui/material/Typography";

import H4 from "components/H4";

const StyledLink = styled(Link)({
  fontWeight: "bold",
  textDecoration: "none",

  ":hover": {
    textDecoration: "underline",
  },
});

function ConversationUploadDescription() {
  return (
    <Box marginBottom="40px">
      <Box marginBottom="28px">
        <p>
          Import your existing contacts and conversation history into this
          messaging account from your previous texting platform using CSV files.
        </p>
      </Box>
      <Typography
        variant="h4"
        sx={(theme) => {
          return {
            fontSize: theme.typography.body1,
            fontWeight: "bold",
          };
        }}
      >
        Step 1
      </Typography>
      <p>
        Upload a CSV file of contacts that includes information such as the
        contact name and phone number. You will need to complete this step
        before importing your conversation history. If you have already uploaded
        your contacts previously, select <strong>Skip Step 1</strong>.
      </p>
      <Typography
        variant="h4"
        sx={(theme) => {
          return {
            fontSize: theme.typography.body1,
            fontWeight: "bold",
          };
        }}
      >
        Step 2
      </Typography>
      <p>
        Once the CSV file of contacts has been uploaded, you will receive a
        notification that you are ready to upload your conversation history.
        Select <strong>Skip Step 1</strong>, then upload your conversation
        history using a properly formatted CSV file.
      </p>
      <p>
        You will receive another email notification once the conversation upload
        is complete. Uploaded conversations can be viewed from the Closed folder
        in this account’s Inbox until you reopen the conversation.
      </p>
    </Box>
  );
}

export default ConversationUploadDescription;
