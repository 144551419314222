import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";

import { Formik, Field, Form } from "formik";
import { Grid2 as Grid, TextField, Typography } from "@mui/material";
import { DialogFooter } from "components/DialogFooter";

import { ContactFilterValidationSchema } from "formHelpers/validationSchemas";

const FormRow = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
`;

export default class ContactFilterFormRoot extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    requestUrl: PropTypes.string.isRequired,
    savedPath: PropTypes.string.isRequired,
    submitHandler: PropTypes.func.isRequired,
  };

  successCallbackCreator = (actions) => {
    return () => {
      actions.setSubmitting(false);
      this.props.closeModal();
    };
  };

  errorCallbackCreator = (actions) => {
    return (errors) => {
      actions.setSubmitting(false);
      actions.setErrors(get(errors, ["validationErrors"]));
    };
  };

  handleSubmit = (values, actions) => {
    const { requestUrl, submitHandler } = this.props;
    submitHandler(requestUrl, values, {
      successCallback: this.successCallbackCreator(actions),
      errorCallback: this.errorCallbackCreator(actions),
    });
  };

  render() {
    const { savedPath, closeModal } = this.props;
    const initialValues = { title: "", savedPath };
    return (
      <Formik
        initialValues={initialValues}
        isInitialValid={ContactFilterValidationSchema.isValidSync(
          initialValues,
        )}
        validationSchema={ContactFilterValidationSchema}
        onSubmit={this.handleSubmit}
      >
        {({ errors, touched, isSubmitting, isValid }) => {
          return (
            <Form>
              <Grid
                sx={{
                  paddingX: 2,
                  paddingTop: 2,
                }}
              >
                <Typography variant="body1" color="text.primary" mb={2}>
                  Save your search terms in order to quickly access them later.
                </Typography>

                <FormRow>
                  <Field type="text" name="title">
                    {({ field }) => {
                      return (
                        <TextField
                          aria-label="Title Text Field"
                          data-testid="title-text-field"
                          {...field}
                          autoFocus
                          error={touched.title && Boolean(errors.title)}
                          fullWidth
                          helperText={touched.title ? errors.title || " " : " "}
                          inputProps={{ "data-lpignore": true }}
                          label="Title"
                          placeholder="Best Search Ever"
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                </FormRow>
              </Grid>

              <DialogFooter
                confirmText="Save"
                isConfirmDisabled={!isValid || isSubmitting}
                onCancel={closeModal}
              />
            </Form>
          );
        }}
      </Formik>
    );
  }
}
