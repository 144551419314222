import { AccordionDetails, Box, Typography } from "@mui/material";
import { BullhornCandidateDetails } from "../BullhornCandidateDetails";
import { GreenhouseCandidateDetails } from "../GreenhouseCandidateDetails";
import { ContactSyncDetails } from "../ContactSyncDetails/ContactSyncDetails";
import { ContactSyncDetailsAccordionProps } from "./types";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import { ExpandMoreIcon } from "icons";

export function ContactSyncDetailsAccordion({
  contactSyncData,
  expanded,
  handleExpansion,
  integrationName,
}: ContactSyncDetailsAccordionProps) {
  return (
    <ExpansionPanel
      square
      expanded={expanded === `${integrationName} details`}
      onChange={handleExpansion(`${integrationName} details`)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Box width="100%">
          <Typography sx={{overflow: "hidden", textOverflow:"ellipsis"}}>
            {integrationName} details
          </Typography>
        </Box>
      </ExpansionPanelSummary>
      <AccordionDetails
        sx={(theme) => {
          return {
            padding: 2,
            background: theme.palette.customColors.backgrounds.secondary,
          };
        }}
      >
        {integrationName === "Bullhorn" ? (
          <BullhornCandidateDetails candidate={contactSyncData.Candidate} />
        ) : null}
        {integrationName === "Greenhouse" ? (
          <GreenhouseCandidateDetails candidate={contactSyncData.Candidate} />
        ) : null}
        {integrationName !== "Bullhorn" && integrationName !== "Greenhouse" ? (
          <ContactSyncDetails contactSyncData={contactSyncData} />
        ) : null}
      </AccordionDetails>
    </ExpansionPanel>
  );
}
