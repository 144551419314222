import { useCallback, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack, Button, Grid2 as Grid, Box } from "@mui/material";
import { styled } from "@mui/system";
import { getWindowFeatures } from "./utils";
import { StyledExpansionPanel, StyledExpansionPanelDetails } from "./common";
import { IntegrationProps } from "./types";
import ModalHeader from "components/ModalHeader";
import ModalContent from "components/ModalContent";
import { Section } from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import { NativeSelect } from "components/NativeSelect";
import { HubspotValidationSchema } from "formHelpers/validationSchemas";
import { usePopup } from "utils/popup";
import H4 from "components/H4";

export const Logo = styled("img")({
  width: "252px",
  margin: "12px 20px 12px 20px",
});

interface OAuthConfig {
  consentUrl: string;
  clientId: string;
  redirectUri: string;
  scopes: string[];
}

/**
 * Get url for authorization
 *
 * @param {OAuthConfig} – OAuth Config
 * @returns authorization url
 */
const getUrl = ({ consentUrl, clientId, scopes, redirectUri }: OAuthConfig) => {
  return `${consentUrl}?client_id=${clientId}&scope=${scopes.join(
    "%20",
  )}&redirect_uri=${redirectUri}` as const;
};

/**
 *  <HubSpot /> component
 *
 * @param {Props} - <HubSpot /> props
 * @returns React element
 */
export default function HubSpot({
  account,
  appSettings,
  deleteIntegrationRequest,
  fetchIntegrationCollectionRequest,
  handleSubmit: submit,
  provider,
}: IntegrationProps<
  {
    code: string;
  },
  {
    note_type: "sms" | "task";
  }
>) {
  const [code, setCode] = useState<string | undefined>();

  const [isAuthenticated, label] = useMemo(() => {
    const _isAuthenticated = Boolean(provider.integration || code);

    return [
      _isAuthenticated,
      _isAuthenticated ? "Deauthorize" : "Authorize",
    ] as const;
  }, [provider.integration, code]);

  const [expandedPanel, setExpandedPanel] = useState<string | undefined>(() => {
    return isAuthenticated ? "settings" : undefined;
  });

  /**
   * Handle submit
   */
  const handleSubmit = useCallback(
    ({ settings, config }, closeModal: boolean) => {
      return submit(
        {
          provider,
          settings,
          config,
        },
        closeModal,
      );
    },
    [provider, submit],
  );

  /**
   * Handle de-authorize
   */
  const handleDeauthorize = useCallback(() => {
    deleteIntegrationRequest(provider?.integration?.id, null, {
      successCallback: () => {
        fetchIntegrationCollectionRequest(account.integrations);
        provider.closeModal();
      },
    });
  }, [
    account.integrations,
    deleteIntegrationRequest,
    fetchIntegrationCollectionRequest,
    provider,
  ]);

  const popupParameters = useMemo(() => {
    return [
      getUrl({
        consentUrl: appSettings.HUBSPOT_AUTHORIZATION_URI,
        clientId: appSettings.HUBSPOT_CLIENT_ID,
        redirectUri: `${window.location.origin}/auth/hubspot_callback`,
        scopes: [
          "crm.objects.contacts.read",
          "crm.objects.contacts.write",
          "crm.objects.owners.read",
        ],
      }),
      "MS Dynamics Window",
      getWindowFeatures({ width: 500, height: 750 }),
    ] as const;
  }, [appSettings.HUBSPOT_AUTHORIZATION_URI, appSettings.HUBSPOT_CLIENT_ID]);

  /**
   * Popup
   */
  const { open: openAuthorizer } = usePopup<string>(
    "HubspotChannel",
    popupParameters,
  );

  const handleOnClick = useCallback(async () => {
    if (isAuthenticated) {
      handleDeauthorize();
    } else {
      openAuthorizer({
        submit: (_code) => {
          setCode(_code);

          setExpandedPanel("settings");

          handleSubmit(
            {
              config: {
                code: _code,
              },
              settings: provider.integration?.settings,
            },
            false,
          );
        },
      });
    }
  }, [
    handleDeauthorize,
    handleSubmit,
    isAuthenticated,
    openAuthorizer,
    provider.integration?.settings,
  ]);

  return (
    <>
      <ModalHeader closeModal={provider.closeModal}>
        {provider.name}
      </ModalHeader>
      <ModalContent noPadding style={{ overflow: "auto" }}>
        <Stack>
          <Formik
            onSubmit={({ settings }) => {
              handleSubmit({ settings }, true);
            }}
            initialValues={{
              settings: {
                noteType: provider.integration?.settings?.note_type,
              },
            }}
            validationSchema={HubspotValidationSchema}
          >
            {({ isSubmitting, isValid }) => {
              return (
                <Form>
                  <Section>
                    <Logo src={provider.logo} width="252" height="100" />
                    <Box mb={3}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleOnClick}
                        color={isAuthenticated ? "error" : "primary"}
                        aria-label={label}
                      >
                        {label}
                      </Button>
                    </Box>
                  </Section>
                  <StyledExpansionPanel
                    expanded={expandedPanel === "settings"}
                    onChange={() => {
                      setExpandedPanel(
                        expandedPanel === "settings" ? undefined : "settings",
                      );
                    }}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Settings
                    </ExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <Section>
                        <Stack gap={2}>
                          <H4>Message Logging</H4>
                          <NativeSelect
                            required
                            label="Type"
                            color="secondary"
                            name="settings.noteType"
                            placeholder="Type"
                            options={[
                              {
                                value: "task",
                                label: "Tasks",
                              },
                              {
                                value: "sms",
                                label: "SMS Activities",
                              },
                            ]}
                          />
                          <Grid container justifyContent="flex-end">
                            <Button
                              aria-label="Save Integration"
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={
                                !(isValid && isAuthenticated) || isSubmitting
                              }
                            >
                              Save
                            </Button>
                          </Grid>
                        </Stack>
                      </Section>
                    </StyledExpansionPanelDetails>
                  </StyledExpansionPanel>
                </Form>
              );
            }}
          </Formik>
        </Stack>
      </ModalContent>
    </>
  );
}
