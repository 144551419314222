import { Account } from "models/Account";
import { IntegrationProvider } from "models/IntegrationProvider";

export const areBatchNotesAvailable = (
  currentAccount: Account,
  integrationState?: IntegrationProvider,
) => {
    // If batching isn't available, we can just return false
    if (!integrationState?.settings?.batching) { return false; }

    // If the provider isn't Salesforce, we can just return true
    // (we know that batching is available from the prior conditional)
    if (integrationState?.provider !== 'salesforce') { return true; }

    // If batching is enabled and the provider is Salesforce, return the value of the feature flag
    return currentAccount.featureFlags.salesforceBatchNotes;
};