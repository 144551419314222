interface GerneralContactSyncData {
  id?: string;
  status?: string;
  application?: {
    current_stage?: {
      name: string;
    }[];
    job?: {
      name: string;
    }[];
  };
  stage?: string;
  owner?: string;
  title?: string;
  birthday?: string;
  locations?: string[] | any[];
  emails?: string | string[];
}

/* 
This function is used to gerneralize the various data shapes we get back from each contact sync provider.
Here is a reference to the data matrix for contact sync providers besides Bullhorn and Greenhouse:
https://www.notion.so/textus/Merge-Implementation-a902d8a5e04b4a08b9601edbf79e79cf#5f18198918f241b1878cda7b92e301ad
This will need to be updated if we add more contact sync providers that have different data shapes.
*/
export const generateContactSyncDetails = (contactSyncData: any) => {
  const details = {} as GerneralContactSyncData;
  const dataKeys = Object.keys(contactSyncData);
  dataKeys.forEach((key) => {
    if (key === "remote_id" || key === "Student_Reference_WID") {
      details.id = contactSyncData[key];
    }
    if (
      key === "employment_status" ||
      key === "Student_Record_Status_Reference_Student_Record_Status_ID"
    ) {
      details.status = contactSyncData[key];
    }
    if (key === "application") {
      [details.application] = contactSyncData[key];
    }
    if (key === "Academic_Level_Reference_Academic_Level_ID") {
      details.stage = contactSyncData[key];
    }
    if (key === "credited_to" || key === "owner" || key === "manager_id") {
      details.owner = contactSyncData[key];
    }
    if (
      key === "title" ||
      key === "job_title" ||
      key === "Educational_Credential_ID"
    ) {
      details.title = contactSyncData[key];
    }
    if (key === "date_of_birth" || key === "Date_of_birth") {
      details.birthday = contactSyncData[key];
    }
    if (
      key === "locations" ||
      key === "addresses" ||
      key === "location" ||
      key === "Address_Data"
    ) {
      details.locations = contactSyncData[key];
    }
    if (
      key === "emails" ||
      key === "email_address" ||
      key === "work_email" ||
      key === "Email_Information_Data"
    ) {
      details.emails = contactSyncData[key];
    }
  });
  return details;
};
