import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SnackbarContent, SnackbarKey, SnackbarMessage } from "notistack";
import { forwardRef } from "react";
import { grayscale } from "@tesseract/theme/src/palettes/colors/grayscale";
import {
  CheckCircleOutlineIcon,
  ErrorOutlineIcon,
  InfoOutlinedIcon,
} from "icons";
import SnackbarCloseButton from "containers/SnackbarCloseButton";

export interface SnackbarProps {
  id: SnackbarKey;
  message: SnackbarMessage;
  variant: "info" | "success" | "error";
  actionButton?: JSX.Element;
  includeCloseButton?: boolean;
  width?: string;
}

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  (props, ref) => {
    const { id, message, variant, actionButton, includeCloseButton, width } =
      props;
    const theme = useTheme();

    const bgColor = {
      info:
        theme.palette.mode === "light"
          ? theme.palette.blueGrey[600]
          : theme.palette.blueGrey[400],
      success: theme.palette.success.main,
      error: theme.palette.error.main,
    };

    const textAndIconColor =
      theme.palette.mode === "light" ? grayscale[50] : grayscale[900];

    const iconMap = Object.freeze({
      info: <InfoOutlinedIcon />,
      success: <CheckCircleOutlineIcon />,
      error: <ErrorOutlineIcon />,
    });

    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <SnackbarContent
        key={id}
        ref={ref}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          component="div"
          sx={{
            alignItems: "center",
            backgroundColor: bgColor[variant],
            borderRadius: "4px",
            display: "flex",
            flex: mobile ? "0 1 auto" : "1 1 auto",
            width: width || "auto",
            padding: "14px 16px",
          }}
        >
          <Box
            component="div"
            sx={{
              color: textAndIconColor,
              marginRight: "0.75rem",
            }}
          >
            {iconMap[variant]}
          </Box>
          <Typography
            fontSize={14}
            flex="1 1 auto"
            sx={{ color: textAndIconColor }}
          >
            {message}
          </Typography>
          <Box marginLeft="8px">{actionButton || null}</Box>
          {includeCloseButton ? <SnackbarCloseButton snackbarKey={id} /> : null}
        </Box>
      </SnackbarContent>
    );
  },
);

Snackbar.displayName = "Snackbar";
