import { SetStateAction, SyntheticEvent, useState } from "react";

/**
 * Hook for commonly repeated variables and functions related to MUI Tabs.
 * @example ```tsx
 * <Tabs value={selectedTab} onChange={handleTabChange}>
 *   <Tab label="Active" />
 *   <Tab label="Archive" />
 * </Tabs>
 * ```
 */
export const useTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (
    _: SyntheticEvent,
    newValue: SetStateAction<number>,
  ) => {
    setSelectedTab(newValue);
  };

  return { selectedTab, handleTabChange };
};
