export const supportedIntegrations = [
  {
    provider: "bullhorn",
    providerName: "Bullhorn",
  },
  {
    provider: "greenhouse",
    providerName: "Greenhouse",
  },
  {
    provider: "sap_successfactors.merge_hris",
    providerName: "SAP SuccessFactors: HRIS",
  },
  {
    provider: "sap_successfactors.merge_ats",
    providerName: "SAP SuccessFactors: ATS",
  },
];