import { ChangeEvent, useEffect, useState } from "react";
import { format } from "date-fns";
import { CurrentAccount } from "features/Automation/models/AutomationModels";
import { fetchAccountUsage } from "features/AccountAnalytics/api/fetchAccountUsage";
import { Usage } from "features/AccountAnalytics/models/BillingAnalyticsModels";
import { Pagination } from "@tesseract/core/src/models/Pagination";
import { useSnackbar } from "notistack";
import { ContractTerm } from "../useCumulativeBillingAnalytics/types";
import { exportBillingAnalytics } from "features/AccountAnalytics/api/exportBillingAnalytics";
/*
This hook acts as the controller for the account usage section for the 
Cumulative Billing Analytics screen.
*/
const useAccountUsageTable = (
  currentAccount: CurrentAccount,
  contractTerm: ContractTerm,
) => {
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>("");
  const [timePeriodOptions, setTimePeriodOptions] = useState<string[]>([]);
  const [accountUsageData, setAccountUsageData] = useState<
    Usage[] | undefined
  >();
  const [paginationData, setPaginationData] = useState<Pagination>();
  const [pageUrl, setPageUrl] = useState<string>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [isLoadingAccountUsage, setIsLoadingAccountUsage] =
    useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  /*
  Fetches account usage data and sets 'Time period' dropdown options on render 
  and/or when different contract term is selected.
  */
  useEffect(() => {
    if (currentAccount && contractTerm.startDate) {
      const { startDate, endDate } = contractTerm;
      const termToDate = true;
      getUsageByAccount(startDate, termToDate);
      getTimePeriodOptions(new Date(startDate), new Date(endDate));
    }
  }, [contractTerm, currentAccount]);

  const getUsageByAccount = async (
    startDate: string,
    termToDate: boolean,
    accountUsageDate?: string,
  ) => {
    setIsLoadingAccountUsage(true);

    const url = termToDate
      ? `/${currentAccount.slug}/billing/usage?contract-term-start-date=${startDate}&term-to-date=${termToDate}&page=1&page-size=25`
      : `/${currentAccount?.slug}/billing/usage?contract-term-start-date=${startDate}&term-to-date=${termToDate}&account-usage-date=${accountUsageDate}&page=1`;

    setPageUrl(url);
    setPaginationModel({ page: 0, pageSize: 25 });

    try {
      const response = await fetchAccountUsage(url);
      const data = await response.json();
      setAccountUsageData(data.usages);
      setPaginationData(data.page);
    } catch (err) {
      enqueueSnackbar(`There was an error loading your usage data: ${err}`, {
        variant: "error",
      });
    } finally {
      setIsLoadingAccountUsage(false);
    }
  };

  /*
  Fetches account usage data when value in 'Time period' dropdown value changes
  */
  const handleSelectTimePeriod = (option: ChangeEvent<HTMLInputElement>) => {
    const { value } = option.target;
    const { startDate } = contractTerm;

    if (value === "Term to date") {
      const termToDate = true;
      getUsageByAccount(startDate, termToDate);
    } else {
      const termToDate = false;
      const accountUsageDate = () => {
        // value comes in the format "Month Year" which cannot be converted to a valid Date object
        // hence we need to split the value and add a day to it to make it a valid Date object
        const formattedTimePeriod = new Date(value.replace(" ", " 1 "));

        return format(formattedTimePeriod, "yyyy-MM");
      };

      getUsageByAccount(startDate, termToDate, accountUsageDate());
    }
    setSelectedTimePeriod(value);
    return null;
  };

  /*
  Creates the 'Time period' dropdown options for the selected contract term
  */
  const getTimePeriodOptions = (startDate: any, endDate: any) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const result = ["Term to date"];
    const year = startDate.getFullYear();
    let current = startDate.getMonth();
    const end =
      (endDate.getYear() - startDate.getYear()) * 12 + endDate.getMonth();
    for (; current <= end; current += 1) {
      result.push(
        `${monthNames[current % 12]} ${current <= 11 ? year : year + 1}`,
      );
    }
    setTimePeriodOptions(result);
    setSelectedTimePeriod("Term to date");
  };

  /*
  Handles POST request for exporting Account Usage report when Mailer Icon is clicked
  */
  const sendReport = async (reportType: string) => {
    try {
      const bodyParams = {
        contractTermStartDate: contractTerm.startDate,
        accountUsageDate: selectedTimePeriod,
        reports: [reportType],
      };
      const response = await exportBillingAnalytics(
        currentAccount.slug,
        bodyParams,
      );
      if (response.ok) {
        enqueueSnackbar(
          `Data exported successfully! Your report will be emailed to you shortly.`,
          { variant: "info" },
        );
      }
    } catch (e) {
      enqueueSnackbar(`Data exported failed. Error:${e}`, { variant: "error" });
    }
  };

  return {
    accountUsageData,
    paginationData,
    timePeriodOptions,
    selectedTimePeriod,
    handleSelectTimePeriod,
    isLoadingAccountUsage,
    setAccountUsageData,
    setPaginationData,
    sendReport,
    pageUrl,
    paginationModel,
    setPaginationModel,
  };
};

export { useAccountUsageTable };
