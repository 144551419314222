/* eslint-disable no-param-reassign */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import { Formik, Form } from "formik";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material";

import { isValid as isValidDate } from "date-fns";
import { useSnackbar } from "notistack";
import getValidationSchema from "./utils/getValidationSchema";
import renderFieldType from "./utils/renderFieldType";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import PageHeader from "components/Page/PageHeader";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";
import withRecord from "higherOrderComponents/withRecord";

function UserPersonalization({
  currentUser,
  fetchUserSchemaRequest,
  toggleSidebar,
  updateUserPersonalizationRequest,
  userSchemaId,
  userSchemaIdsArray,
}) {
  const [accountName, setAccountName] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentUserSchemaId, setCurrentUserSchemaId] = useState(userSchemaId);
  const [nextTab, setNextTab] = useState(0);
  const [nextUserSchemaId, setNextUserSchemaId] = useState("");
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [userSchema, setUserSchema] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const themeMode = useTheme().palette.mode;

  useEffect(() => {
    userSchemaIdsArray.forEach((id) => {
      fetchUserSchemaRequest(id, null, {
        successCallback: (response) => {
          setUserSchema((prevSchema) => {
            return [...prevSchema, response];
          });
        },
        errorCallback: () => {
          enqueueSnackbar(
            "There was a problem loading your account's user schema.",
            { variant: "error" },
          );
        },
      });
    });
  }, []);

  const currentUserSchema = userSchema.find((schema) => {
    return schema.id === currentUserSchemaId;
  });

  const userPersonalizations = get(
    currentUserSchema,
    ["fields", "members"],
    [],
  );

  const userPersonalizationValues = get(
    currentUser,
    ["settings", "customSettings"],
    {},
  );

  const sortedUserSchema = userSchema.sort((a, b) => {
    const { id: idOfA } = a;
    const { id: idOfB } = b;
    const indexOfA = userSchemaIdsArray.indexOf(idOfA);
    const indexOfB = userSchemaIdsArray.indexOf(idOfB);
    return indexOfA - indexOfB;
  });

  // import function to keep Yup import separate
  const userPersonalizationValidationSchema =
    getValidationSchema(userPersonalizations);

  const adjustReceivedValue = (val, type) => {
    const isShortDate = type === "calendar_day";
    if (!val && !isShortDate) {
      return "";
    }
    if (!val && isShortDate) {
      return ["", ""];
    }
    if (val && isShortDate) {
      return val.split("-").slice(1);
    }
    return val;
  };

  const initialValues = userPersonalizations.reduce(
    (formValues, fetchedValue) => {
      const { id, title, type } = fetchedValue;
      const value = get(userPersonalizationValues, [id], null);
      if (!formValues[title]) {
        formValues[title] = {
          id,
          value: adjustReceivedValue(value, type),
        };
      }
      return formValues;
    },
    {},
  );

  const adjustValueToSend = (val) => {
    if (Array.isArray(val)) {
      const month = val[0] - 1; // monthIndex is 0 based in Date constructor
      const day = val[1];
      const dateISOString = new Date(2000, month, day).toISOString();
      return val.includes("") ? null : dateISOString;
    }
    if (isValidDate(val)) {
      return val.toISOString();
    }
    return val || null;
  };

  const handleSubmit = (values, actions) => {
    const valuesToSend = {
      settings: {
        customSettings: Object.keys(values).reduce((finalValues, key) => {
          const { value, id } = values[key];
          if (!finalValues[id]) {
            finalValues[id] = adjustValueToSend(value);
          }
          return finalValues;
        }, {}),
      },
    };

    updateUserPersonalizationRequest(currentUser.id, valuesToSend, {
      successCallback: () => {
        actions.setSubmitting(false);
        enqueueSnackbar("Personalized variables updated successfully.", {
          variant: "info",
        });
      },
      errorCallback: (errors) => {
        const validationErrors = get(errors, ["validationErrors"], {
          name: "Something went wrong!",
        });
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
        enqueueSnackbar(
          "We were unable to update your personalized variables.",
          { variant: "error" },
        );
      },
    });
  };

  const getAccountName = (id) => {
    const schemaToFind = userSchema.find((schema) => {
      return schema.id === id;
    });
    return get(schemaToFind, ["account", "name"]);
  };

  const handleTabClick = (id, index) => {
    if (formIsDirty) {
      setAccountName(getAccountName(id));
      setNextTab(index);
      setNextUserSchemaId(id);
      setConfirmationModalOpen(true);
    } else {
      setCurrentTab(index);
      setCurrentUserSchemaId(id);
    }
  };

  const handleConfirmSwitchTabs = () => {
    setFormIsDirty(false);
    setCurrentUserSchemaId(nextUserSchemaId);
    setCurrentTab(nextTab);
    setConfirmationModalOpen(false);
  };

  const handleCancelSwitchTabs = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <SettingsPageWrapper>
      <ConfirmationModal
        cancellationHandler={handleCancelSwitchTabs}
        confirmationHandler={handleConfirmSwitchTabs}
        isOpen={confirmationModalOpen}
        message={`Navigating to ${accountName} will not save your changes.`}
        title="Discard unsaved changes?"
      />
      <PageHeader title="User Personalization" toggleSidebar={toggleSidebar} />
      <SettingsPageContent>
        <SettingsDescription>
          Add your personal details to the following fields to be able to
          quickly include them in messages and templates.
        </SettingsDescription>
        {userSchema.length > 1 && (
          <Box>
            <Tabs value={currentTab}>
              {sortedUserSchema.map(({ id, account: { name } }, index) => {
                return (
                  <Tab
                    key={id}
                    label={name}
                    id={`simple-tab-${index}`}
                    onClick={() => {
                      return handleTabClick(id, index);
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>
        )}
        {userPersonalizations.length > 0 ? (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              setFormIsDirty(false);
              handleSubmit(values, actions);
            }}
            validationSchema={userPersonalizationValidationSchema}
          >
            {({
              errors,
              isValid,
              isSubmitting,
              setFieldTouched,
              touched,
              values,
            }) => {
              return (
                <Form>
                  {userPersonalizations.map((personalization) => {
                    return renderFieldType({
                      personalization,
                      errors,
                      setFieldTouched,
                      setFormIsDirty,
                      touched,
                      values,
                    });
                  })}
                  <Box
                    display="flex"
                    flex="0 0 auto"
                    justifyContent="flex-end"
                    width="80%"
                    mt={4}
                  >
                    <Button
                      aria-label="submit button"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Box
            sx={(theme) => {
              return {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: 16,
                color: theme.palette.text.secondary,
                justifyContent: "center",
                gap: 5,
              };
            }}
          >
            {(themeMode === "light" && (
              <img
                src="/assets/not_found_light.svg"
                alt="No personalization fields found"
              />
            )) || (
              <img
                src="/assets/not_found_dark.svg"
                alt="No personalization fields found"
              />
            )}
            <p>
              No personalization fields found. Reach out to your administrator
              to have them set up Personalization Fields.
            </p>
          </Box>
        )}
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

UserPersonalization.propTypes = {
  currentUser: PropTypes.object.isRequired,
  fetchUserSchemaRequest: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  updateUserPersonalizationRequest: PropTypes.func.isRequired,
  userSchemaId: PropTypes.string.isRequired,
  userSchemaIdsArray: PropTypes.array.isRequired,
};

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/Profile/containers/UserPersonalization/userSchema",
    multiple: true,
    noFetch: true,
    shape: { fields: { members: [] } },
    type: "userSchema",
    showLoader: () => {
      return false;
    },
  }),
  withRecord({
    actions: ["update"],
    container: "containers/UserPersonalization",
    type: "userPersonalization",
  }),
)(UserPersonalization);
