import { IconButton, Menu, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";
import { MoreVertIcon } from "icons";

interface KeywordTableActionMenuProps {
  keywordType: "active" | "archive";
}

function KeywordTableActionMenu({ keywordType }: KeywordTableActionMenuProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {/* TODO: Add onCLick handlers to MenuItems */}
        <MenuItem>Manage keyword</MenuItem>
        <MenuItem>View subscribers</MenuItem>
        <MenuItem>
          {keywordType === "active" ? "Archive keyword" : "Reactivate keyword"}
        </MenuItem>
      </Menu>
    </>
  );
}

export default KeywordTableActionMenu;
