import PropTypes from "prop-types";
import styled from "styled-components";
import Badge from "@mui/material/Badge";
import ExtensionIcon from "@mui/icons-material/Extension";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { isBefore, subDays } from "date-fns";
import breakpoints from "utils/styles/breakpoints";
import localStorageHelper from "utils/localStorageHelper";

import { useChromeExtension } from "hooks/useChromeExtension";

const NavButtonsWrapper = styled.div`
  display: none;
  height: 100%;

  button,
  a {
    height: 40px;
    width: 40px;
    color: unset;
  }

  @media (min-width: ${breakpoints.large}px) {
    display: flex;
    align-items: center;
  }
`;

function NavButtons({ isSinglePanel }) {
  const { extensionID, isIFrame } = useChromeExtension();
  const extensionOpened = localStorageHelper.getItem("extensionOpened");
  const showExtensionLink =
    !extensionOpened ||
    isBefore(new Date(extensionOpened), subDays(new Date(), 30));

  // What's new button - To be used for launching Pendo resource center.
  const whatsNewButton = (
    <Tooltip title="What's new?" aria-label="What's new?">
      <IconButton color="inherit" style={{ cursor: "pointer" }} size="large">
        <MenuBookIcon data-testid="whats-new" />
      </IconButton>
    </Tooltip>
  );

  // Download extension button.
  const downloadExtensionButton = (
    <Tooltip
      title="Download Our Extension"
      aria-label="download textus chrome extension"
    >
      <IconButton
        color="inherit"
        href={`https://chromewebstore.google.com/detail/textus-chrome-extension/${extensionID}?hl=en&authuser=0`}
        style={{ cursor: "pointer" }}
        target="_blank"
        data-product-tour="ExtensionDownloadButton"
        size="large"
      >
        <Badge color="error" variant="dot">
          <ExtensionIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );

  return (
    <div>
      {!isSinglePanel && (
        <NavButtonsWrapper>
          {whatsNewButton}

          {!isIFrame && showExtensionLink && downloadExtensionButton}
        </NavButtonsWrapper>
      )}
    </div>
  );
}

NavButtons.propTypes = {
  isSinglePanel: PropTypes.bool.isRequired,
};

export default NavButtons;
