import { useMemo } from "react";
import { compose } from "redux";
import { useLocation } from "react-router-dom";
import qs from "query-string";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import { AnalyticsSummaryProps } from "../models/AnalyticsModels";
import { summaryItems } from "../constants/Summary";
import AnalyticsCard from "./AnalyticsCard";

import withRecord from "higherOrderComponents/withRecord";

function AnalyticsSummary({ accountAggregateStats }: AnalyticsSummaryProps) {
  const { search } = useLocation();
  const { descendants } = useMemo(() => {
    return qs.parse(search);
  }, [search]);

  return (
    <Box mt={4}>
      <Grid
        aria-label="Analytics Card Display"
        container
        spacing={2}
        alignContent="center"
      >
        {summaryItems
          .filter(({ type }) => {
            return type === "totalAccounts" ? descendants === "true" : true;
          })
          .map(({ title, type, percent }) => {
            return (
              <AnalyticsCard
                key={title}
                analytic={accountAggregateStats[type]}
                analyticTitle={title}
                percent={percent}
              />
            );
          })}
      </Grid>
    </Box>
  );
}

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/AccountAnalytics/AnalyticsSummary",
    showLoader: () => {
      return false;
    },
    shape: {},
    type: "accountAggregateStats",
  }),
)(AnalyticsSummary);
