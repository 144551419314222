/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { useEffect, useState } from "react";
import { Box, Divider, IconButton, Theme, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router";
import { GridPaginationModel } from "@mui/x-data-grid-pro";
import { MobileKeywordSearchProps } from "../types";
import KeywordTable from "./KeywordTable";
import PageContent from "components/Page/PageContent";
import PageHeader from "components/Page/PageHeader";
import { SearchBox } from "components/SearchBox";
import { ArrowBackIcon } from "icons";
import { useDebouncedSearch } from "hooks";
import { KeywordResponse } from "models/Keyword";
import { useKeywordAutomation } from "features/Automation/hooks/useKeywordAutomation";
import { SECONDARY_NAV_LINKS } from "features/Automation/constants/AutomationConstants";

function MobileKeywordSearch({
  isSinglePanel,
  selectedKeywordNavLink,
  selectedKeywordTab,
  showSidebar,
  toggleSidebar,
}: MobileKeywordSearchProps) {
  /* STATE */
  // Separate from main keywords state, for mobile search results only
  const [keywords, setKeywords] = useState<KeywordResponse | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: Number(new URLSearchParams(window.location.search).get("page")) || 0,
    pageSize: 10,
  });

  /* HOOKS */
  const history = useHistory();

  const isMobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const { debouncedSearchTerm, searchTerm, handleSearch, handleClear } =
    useDebouncedSearch();

  const {
    handleFetchAutomationKeywords,
    isLoadingKeywords,
    keywordsPage,
    setKeywordsPage,
  } = useKeywordAutomation(keywords, setKeywords);

  /* VARIABLES */
  const mainKeywordsPageUrl = window.location.pathname.replace("/search", "");

  /* FUNCTIONS */
  const handlePageChange = (newPaginationModel: GridPaginationModel) => {
    setKeywordsPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  /* EFFECTS */
  useEffect(() => {
    if (!isMobileScreen) {
      history.push(mainKeywordsPageUrl);
    }

    return () => {
      // Clears keywords state on unmount only for this mobile component
      setKeywords(null);
    };
  }, [isMobileScreen, mainKeywordsPageUrl, history]);

  useEffect(() => {
    const getPage = async () => {
      await handleFetchAutomationKeywords({
        active: selectedKeywordTab === 0,
        items: paginationModel.pageSize,
        keywordCont: debouncedSearchTerm,
        mine: selectedKeywordNavLink === SECONDARY_NAV_LINKS.mine,
      });
    };

    if (debouncedSearchTerm) {
      getPage().catch((error) => {
        console.error(error);
      });
    } else {
      setKeywords(null);
    }
  }, [
    debouncedSearchTerm,
    keywordsPage,
    selectedKeywordNavLink,
    selectedKeywordTab,
  ]);

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: "8px 16px 8px 0px" }}
      >
        <IconButton
          onClick={() => {
            return history.push(mainKeywordsPageUrl);
          }}
          sx={{ padding: "0px 16px" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <SearchBox
          value={searchTerm}
          onClear={handleClear}
          onChange={handleSearch}
          placeholder="Search keywords"
          slotProps={{ input: { sx: { height: "48px" } } }}
        />
      </Box>
      <Divider />
      <PageHeader
        title={`Search results (${debouncedSearchTerm ? (keywords?.items.length ?? 0) : 0})`}
        toggleSidebar={toggleSidebar}
      />
      <KeywordTable
        debouncedSearchTerm={debouncedSearchTerm}
        handlePageChange={handlePageChange}
        isLoadingKeywords={isLoadingKeywords}
        keywords={keywords}
        paginationModel={paginationModel}
        showingActiveKeywords={selectedKeywordTab === 0}
        sx={{ height: "calc(100vh - 230px)" }} // Keeps pagination footer pinned to bottom
      />
    </PageContent>
  );
}

export default MobileKeywordSearch;
