import { compose } from "redux";

import { Grid2 as Grid, Typography } from "@mui/material";
import { Account, Contact } from "@tesseract/core";
import { useLocation } from "react-router";
import { useSnackbar } from "notistack";
import { DialogHeader } from "components/DialogHeader";
import getPathnameArray from "utils/getPathnameArray";
import withRecord from "higherOrderComponents/withRecord";
import { DialogFooter } from "components/DialogFooter";

type ComposedProps = {
  deleteContactRequest: (
    url: string,
    data: { contact: Contact.Raw["id"] },
    config: {
      successCallback: (record: Contact.Raw) => void;
      errorCallback: () => void;
    },
  ) => void;
  fetchContactCollectionRequest: (url: string, data: null) => void;
};

type PassedProps = {
  currentAccount: Account.Raw;
  handleClose: () => void;
  contact: Contact.Raw;
  cancel: () => void;
};

type Props = ComposedProps & PassedProps;

function ContactConfirmDeleteDialog(props: Props) {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    cancel,
    contact,
    currentAccount,
    deleteContactRequest,
    fetchContactCollectionRequest,
    handleClose,
  } = props;

  const handleConfirmDeleteButtonClick = () => {
    deleteContactRequest(
      `/${currentAccount.slug + contact.id}`,
      {
        contact: contact.id,
      },
      {
        successCallback: (record) => {
          const [, accountSlug, feature, featureSlug] = getPathnameArray(
            location.pathname,
          );

          if (feature === "contacts") {
            const contactCollectionId = `/${accountSlug}/contact_filters/${featureSlug}/contacts${location.search}`;
            fetchContactCollectionRequest(contactCollectionId, null);
          }

          handleClose();

          enqueueSnackbar(`Successfully removed ${contact.name}`, {
            variant: "info",
          });
        },
        errorCallback: () => {
          enqueueSnackbar("Something went wrong. Please try again.", {
            variant: "error",
          });
        },
      },
    );
  };

  return (
    <>
      <DialogHeader onClose={handleClose} title="Remove contact?" />
      <Grid
        sx={{
          paddingX: 2,
          paddingTop: 2,
        }}
      >
        <Typography variant="body1">
          This contact will no longer be available for messaging on this
          account, and will be removed from this account’s:
        </Typography>
        <ul>
          <li>Existing conversations</li>
          <li>Groups</li>
          <li>Integrations</li>
        </ul>
      </Grid>
      <DialogFooter
        confirmText="Remove"
        onCancel={cancel}
        onConfirm={handleConfirmDeleteButtonClick}
      />
    </>
  );
}

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "containers/DiscardContact/contactCollection",
    noFetch: true,
    showLoader: () => {
      return false;
    },
    type: "contactCollection",
  }),
  withRecord({
    actions: ["delete"],
    container: "containers/DiscardContact/contact",
    type: "contact",
  }),
)(ContactConfirmDeleteDialog) as React.ComponentType<PassedProps>;
