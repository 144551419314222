import { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { formatInTimeZone } from "date-fns-tz";
import { setMinutes, startOfDay } from "date-fns";

export default class CampaignCutoffInfo extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    hasCutoffError: PropTypes.bool,
  };

  getCampaignCutoffTime = () => {
    const { currentAccount } = this.props;
    const accountTimezone = currentAccount.settings.timeZone.value;
    const campaignCutoffTimeMinutes =
      currentAccount.settings.campaignDeliveryCutoffTime?.value;
    return formatInTimeZone(
      setMinutes(startOfDay(new Date()), campaignCutoffTimeMinutes),
      accountTimezone,
      "hh:mm:ss a z",
    );
  };

  render() {
    const { currentAccount, hasCutoffError } = this.props;
    const cutoffTimeEnabled =
      currentAccount.settings.campaignDeliveryCutoffTimeEnabled?.value;
    const textColor = hasCutoffError ? "error" : "text.primary";
    if (!cutoffTimeEnabled) return null;
    return (
      <Typography variant="body2" sx={{ color: textColor }}>
        Campaign cutoff time: <b>{this.getCampaignCutoffTime()}</b>
      </Typography>
    );
  }
}
