/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useEffect, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useSnackbar } from "notistack";
import { getWindowFeatures } from "../../utils/getWindowFeatures";
import { getConnectedIntegrations } from "../../api/getConnectedIntegrations";
import { AuthorizeSalesforceButtonProps } from "./types";
import { selectAppSettings } from "features/EntryPoint/containers/App/selectors";
import {
  integrationsId,
  salesforceSubdomain,
  salesforceAuthCallback,
} from "constants/salesforceAuthConstants";
import localStorageHelper from "utils/localStorageHelper";
import { IntegrationProvider } from "models/IntegrationProvider";

export function AuthorizeSalesforceButton({
  additionalSettings,
  currentAccount,
  setOauthDialogOpen,
  setSelectedIntegration,
}: AuthorizeSalesforceButtonProps) {
  const [authWindow, setAuthWindow] = useState<Window | null>(null);
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const appSettings = useSelector(selectAppSettings);
  const { enqueueSnackbar } = useSnackbar();
  const subdomain = additionalSettings?.sandboxChecked ? "test" : "login";

  const handleGetConnectedIntegrations = async () => {
    try {
      const response = await getConnectedIntegrations(currentAccount.slug);
      if (!response.ok) {
        throw new Error("Failed to get connected integrations.");
      }
      const data = await response.json();
      if (data.members && data.members.length > 0) {
        const connectedIntegrationData = data.members.find(
          (integration: IntegrationProvider) => {
            return integration.provider === "salesforce";
          },
        );
        const formattedData = {
          ...connectedIntegrationData,
          providerName: "Salesforce",
          strategy: "oauth",
        };
        setSelectedIntegration(formattedData);
        setOauthDialogOpen(false);
        if (connectedIntegrationData) {
          enqueueSnackbar("Integration successfully connected.", {
            variant: "info",
          });
        }
      }
    } catch {
      enqueueSnackbar("Failed to get connected integrations.", {
        variant: "error",
      });
    }
  };

  const getAuthUrl = () => {
    // const subdomain = additionalSettings?.sandboxChecked ? "test" : "login";
    const query = queryString.stringify(
      {
        response_type: "code",
        client_id: appSettings.SALESFORCE_KEY,
        redirect_uri: salesforceAuthCallback,
      },
      {
        encode: false,
      },
    );
    return `https://${subdomain}.salesforce.com/services/oauth2/authorize?${query}`;
  };

  const openAuthWindow = () => {
    const accountIntegrationId = currentAccount.integrations;
    localStorageHelper.setItem({
      item: integrationsId,
      value: accountIntegrationId,
    });
    localStorageHelper.setItem({
      item: salesforceSubdomain,
      value: subdomain,
    });
    const openWindow = window.open(
      getAuthUrl(),
      "Salesforce Window",
      getWindowFeatures({ width: 800, height: 800 }),
    );
    setAuthWindow(openWindow);

    const id = window.setInterval(() => {
      if (openWindow && openWindow.closed) {
        window.clearInterval(id);
        setAuthWindow(null);
        setIntervalId(null);
        handleGetConnectedIntegrations();
      }
    }, 1000);
    setIntervalId(id);
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        authWindow?.clearInterval(intervalId);
      }
      localStorage.removeItem(integrationsId);
      localStorage.removeItem(salesforceSubdomain);
    };
  }, []);

  return (
    <Button
      color="primary"
      endIcon={<OpenInNewIcon />}
      onClick={() => {
        openAuthWindow();
      }}
      variant="contained"
      sx={{
        padding: "6px 16px",
      }}
    >
      Authorize
    </Button>
  );
}
