import { textUsApiV3 } from "../../../services";
import { Member } from "../interfaces/GetContactByPhoneResponse";

interface Props {
  phoneNumber: string;
  accountSlug: string;
}

const getContactsByPhoneApi = async ({
  phoneNumber,
  accountSlug,
}: Props): Promise<Member[]> => {
  const result = await textUsApiV3.get(
    `/${accountSlug}/contact_phones/search_to_text?q=${phoneNumber}`,
  );

  const json = await result.json();

  const contacts = json.members.map((m: any) => {
    return m.contact;
  });

  return contacts || [];
};

export { getContactsByPhoneApi };
