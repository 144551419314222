import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import get from "lodash/get";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

import AccountSetting from "./AccountSetting";

import { RegisteredLinksValidationSchema } from "formHelpers/validationSchemas";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";
import { SHORT_IO_URL } from "constants/defaults";

const Description = styled(SettingsDescription)`
  a {
    font-weight: bold;
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

function RegisteredLinks(props) {
  const { account } = props;

  const getDescription = () => {
    return (
      <Box>
        <Description>
          <Typography
            sx={{ marginBottom: "12px" }}
            element="h2"
            color="primary"
            variant="h4"
          >
            Shortened links
          </Typography>
          <Box sx={{ marginBottom: "24px" }}>
            <p>
              By default, messaging accounts have access to the Shorten Link
              feature which will abbreviate and standardize links to use
              ‘sms.textlink.us’ as the base URL.
            </p>
            <p>
              Shortened links are only accessible if a messaging account has{" "}
              <Link
                component={RouterLink}
                to={`/${account.slug}/settings/privacy/link-removal`}
              >
                outbound links enabled
              </Link>
              .
            </p>
          </Box>
          <Typography
            sx={{ marginBottom: "12px" }}
            element="h3"
            color="primary"
            variant="h5"
          >
            Branded subdomain{" "}
            <Chip label="Campaigns Pro" color="primary" size="small" />
          </Typography>

          <p>
            With a Campaigns Pro license, shortened links can be sent with a
            branded subdomain as the base URL instead. We recommend using your
            organization’s name so contacts can easily identify the link as
            safe.
          </p>
        </Description>
      </Box>
    );
  };

  const getRegisteredLinkRender = () => {
    return ({ errors, values }) => {
      return function ({ field, form }) {
        const { value: registeredLinks } =
          account?.settings?.registeredLinks ?? "";
        const { value: errorMessage } = errors?.settings?.registeredLinks ?? "";
        const { value: formValue } = values?.settings?.registeredLinks ?? "";
        return (
          <Box>
            <Box display="flex" my={4}>
              <TextField
                disabled={!!registeredLinks}
                error={!!formValue && !!errorMessage}
                helperText={
                  registeredLinks
                    ? "To change your subdomain, please contact your Account Manager or support@textus.com"
                    : formValue && errorMessage
                }
                label="Custom Branded Base URL"
                onChange={(event) => {
                  form.setFieldValue(field.name, event.target.value);
                }}
                style={{ flexGrow: 1 }}
                value={registeredLinks}
                variant="outlined"
                inputProps={{ "aria-label": "Shortened Custom URL Link" }}
              />
              <Box mx={1}>
                <p>.{SHORT_IO_URL}</p>
              </Box>
            </Box>
            <Typography
              sx={{ marginBottom: "12px" }}
              element="h4"
              color="primary"
              variant="h6"
            >
              Subdomain Requirements
            </Typography>

            <ul>
              <li>A minimum of 2 characters</li>
              <li>A maximum of 12 characters</li>
              <li>No special characters or ending with a hyphen</li>
              <li>Cannot already be in use by another organization</li>
            </ul>
          </Box>
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      isDisabled={!get(account, ["settings", "timeZone", "value"])}
      settings={[
        {
          default: "",
          name: "registeredLinks",
          render: getRegisteredLinkRender(),
        },
      ]}
      title="Shortened Links"
      validationSchema={RegisteredLinksValidationSchema}
    />
  );
}

RegisteredLinks.propTypes = {
  account: PropTypes.object.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default RegisteredLinks;
