/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Field, Form, Formik } from "formik";
import { Dialog, DialogContent, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { authorizeIntegration } from "../../api/authorizeIntegration";
import { CustomIntegrationDialogProps } from "./types";
import { generateCustomIntegrationValidationSchema } from "./validationSchema";
import { DialogFooter } from "components/DialogFooter";
import { DialogHeader } from "components/DialogHeader";

interface BullhornDatacenter {
  apiHost: string;
  authHost: string;
  name: string;
}

export function CustomIntegrationDialog({
  currentAccount,
  integration,
  open,
  setCustomAuthorizationDialogOpen,
  setSelectedIntegration,
}: CustomIntegrationDialogProps) {
  const { config } = integration ?? {};

  const { enqueueSnackbar } = useSnackbar();

  const getInitialFormValues = () => {
    // The below code is to avoid mutating the original config object
    const initialValues = _.cloneDeep(config);
    Object.entries(initialValues).forEach(([key, value]: [string, any]) => {
      if (value.type === "text") {
        initialValues[key] = "";
      }
    });
    if (integration?.provider === "bullhorn") {
      return {
        ...initialValues,
        apiHost: "",
        authHost: "",
      };
    }
    return initialValues;
  };

  const handleFormSubmit = async (values: any) => {
    try {
      if (integration?.provider === "bullhorn") {
        delete values.datacenter;
      }
      const response = await authorizeIntegration(currentAccount.slug, {
        provider: integration?.provider ?? "",
        active: true,
        config: values,
      });
      if (!response.ok) {
        throw new Error("Failed to connect integration.");
      }
      const data = await response.json();
      // Append providerName and strategy to the data object for consistency.
      const formattedData = {
        ...data,
        providerName: integration?.providerName,
        strategy: integration?.strategy,
      };
      setSelectedIntegration(formattedData);
      setCustomAuthorizationDialogOpen(false);
      enqueueSnackbar("Integration successfully connected.", {
        variant: "info",
      });
    } catch {
      enqueueSnackbar("Failed to connect integration.", {
        variant: "error",
      });
    }
  };

  const handleCloseModal = () => {
    enqueueSnackbar("Integration setup cancelled.", {
      variant: "info",
    });
    setCustomAuthorizationDialogOpen(false);
    setSelectedIntegration(null);
  };

  return config ? (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        return handleCloseModal();
      }}
    >
      <DialogHeader
        title={`Authenticate ${integration?.providerName ?? ""}`}
        onClose={() => {
          return handleCloseModal();
        }}
      />
      <Formik
        initialValues={getInitialFormValues() ?? {}}
        onSubmit={() => {}}
        validationSchema={generateCustomIntegrationValidationSchema(config)}
      >
        {({ errors, isValid, setFieldValue, touched, values }) => {
          return (
            <Form>
              <DialogContent
                sx={{
                  paddingBottom: "8px",
                }}
              >
                {Object.hasOwn(config, "username") && (
                  <Field name="username" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.username && Boolean(errors.username)}
                          fullWidth
                          helperText={touched.username ? errors.username : ""}
                          label="Username"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "password") && (
                  <Field name="password" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.password && Boolean(errors.password)}
                          fullWidth
                          helperText={touched.password ? errors.password : ""}
                          label="Password"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "clientId") && (
                  <Field name="clientId" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.clientId && Boolean(errors.clientId)}
                          fullWidth
                          helperText={touched.clientId ? errors.clientId : ""}
                          label="Client ID"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "clientSecret") && (
                  <Field name="clientSecret" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={
                            touched.clientSecret && Boolean(errors.clientSecret)
                          }
                          fullWidth
                          helperText={
                            touched.clientSecret ? errors.clientSecret : ""
                          }
                          label="Client secret"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "datacenter") && (
                  <Field name="apiHost" type="select">
                    {({ field }: any) => {
                      return (
                        <TextField
                          error={touched.apiHost && Boolean(errors.apiHost)}
                          label="Datacenter"
                          name={field.name}
                          onChange={(event) => {
                            const index = Number(event.target.value);
                            setFieldValue(
                              "apiHost",
                              config.datacenter.options[index].apiHost,
                            );
                            setFieldValue(
                              "authHost",
                              config.datacenter.options[index].authHost,
                            );
                          }}
                          select
                          SelectProps={{
                            native: true,
                            inputProps: { "aria-label": "Datacenter" },
                          }}
                          sx={{
                            marginBottom: "16px",
                          }}
                          variant="outlined"
                        >
                          <option
                            key="placeholder"
                            aria-label="Empty"
                            value=""
                          />
                          {integration?.config.datacenter.options.map(
                            (option: BullhornDatacenter, i: number) => {
                              return (
                                <option key={option.apiHost} value={i}>
                                  {option.name}
                                </option>
                              );
                            },
                          )}
                        </TextField>
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "entityId") && (
                  <Field name="entityId" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.entityId && Boolean(errors.entityId)}
                          fullWidth
                          helperText={touched.entityId ? errors.entityId : ""}
                          label="Entity ID"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "baseUrl") || integration?.provider === "webhook" ? (
                  <Field name="baseUrl" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.baseUrl && Boolean(errors.baseUrl)}
                          fullWidth
                          helperText={touched.baseUrl ? errors.baseUrl : ""}
                          label={integration?.provider === "erecruit" ? "Base URL" : "Webhook URL"}
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                ) : null}
                {Object.hasOwn(config, "tenantId") && (
                  <Field name="tenantId" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.tenantId && Boolean(errors.tenantId)}
                          fullWidth
                          helperText={touched.tenantId ? errors.tenantId : ""}
                          label="Tenant ID"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "organizationUrl") && (
                  <Field name="organizationUrl" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={
                            touched.organizationUrl &&
                            Boolean(errors.organizationUrl)
                          }
                          fullWidth
                          helperText={
                            touched.organizationUrl
                              ? errors.organizationUrl
                              : ""
                          }
                          label="Organization URL"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "email") && (
                  <Field name="email" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.email && Boolean(errors.email)}
                          fullWidth
                          helperText={touched.email ? errors.email : ""}
                          label="Email"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "apiKey") && (
                  <Field name="apiKey" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.apiKey && Boolean(errors.apiKey)}
                          fullWidth
                          helperText={touched.apiKey ? errors.apiKey : ""}
                          label="API key"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "apiUrl") && (
                  <Field name="apiUrl" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.apiUrl && Boolean(errors.apiUrl)}
                          fullWidth
                          helperText={touched.apiUrl ? errors.apiUrl : ""}
                          label="API URL"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "tenantSlug") && (
                  <Field name="tenantSlug" type="text">
                    {({ field }: any) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.tenantSlug && Boolean(errors.tenantSlug)}
                          fullWidth
                          helperText={touched.tenantSlug ? errors.tenantSlug : ""}
                          label="Tenant slug"
                          sx={{
                            marginBottom: "16px",
                          }}
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                )}
                {Object.hasOwn(config, "subdomain") && (
                  <>
                    <Field name="subdomain" type="text">
                      {({ field }: any) => {
                        return (
                          <TextField
                            {...field}
                            error={
                              touched.subdomain && Boolean(errors.subdomain)
                            }
                            fullWidth
                            helperText={
                              touched.subdomain ? errors.subdomain : ""
                            }
                            label="Subdomain"
                            sx={{
                              marginBottom: "8px",
                            }}
                            type="text"
                            variant="outlined"
                          />
                        );
                      }}
                    </Field>
                    <Typography variant="body2">
                      To use candidate IDs in TextUs as hyperlinks, please enter
                      your Greenhouse URL subdomain. You can determine what your
                      subdomain is by signing into Greenhouse and looking at
                      your browser's address bar.
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "italic",
                        marginBottom: "8px",
                      }}
                    >
                      Example: The subdomain in https://app4.greenhouse.io/
                      would be <b>app4</b>
                    </Typography>
                  </>
                )}
              </DialogContent>
              <DialogFooter
                confirmText="Authorize"
                onCancel={() => {
                  return handleCloseModal();
                }}
                onConfirm={() => {
                  handleFormSubmit(values);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  ) : null;
}
