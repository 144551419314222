/**
 *
 * Contacts
 *
 */

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { matchPath, Switch, Route, Redirect } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import { Box } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReactTouchEvents from "react-touch-events";
import ContactSearchInfo from "./components/ContactSearchInfo";
import SearchTooltip from "./components/SearchTooltip";
import SearchGraphicV2 from "utils/images/contact-search-blank-state-v2.svg";
import { SearchIcon } from "icons";

import { actionGenerators as contactModalActionGenerators } from "features/ContactModal/state";
import BlankState from "components/BlankState";
import ContactFilter from "features/Contacts/containers/ContactFilter";
import ContactFilterCollection from "features/Contacts/containers/ContactFilterCollection";
import PageSearchHeader from "components/Page/PageSearchHeader";
import { handleMainSwipe, handleSidebarSwipe } from "utils/handleSwiping";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";

import PageContent from "components/Page/PageContent";
import PageSection from "components/Page/PageSection";
import PageSidebar from "components/Page/PageSidebar";
import PageSidebarHeader from "components/Page/PageSidebarHeader";
import PageSidebarHeaderButton from "components/Page/PageSidebarHeaderButton";
import PageWrapper from "components/Page/PageWrapper";
import withSidebar from "higherOrderComponents/withSidebar";

const SEARCH_BAR_HEIGHT = "57px";

export class Contacts extends PureComponent {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    getLocation: PropTypes.func,
    history: PropTypes.object.isRequired,
    isSinglePanel: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setContactModal: PropTypes.func,
    showSidebar: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      savedSearchPath: null,
    };
  }

  getContactFilterIdFromUrl = () => {
    const { location } = this.props;
    const { pathname, search } = location;
    const match = matchPath(pathname, {
      path: "/:accountSlug/contacts/:activeContactFilterSlug",
    });
    const { filterSlug, query } =
      get(match, ["params", "activeContactFilterSlug"]) === "search"
        ? { filterSlug: "all", query: search || "?q=" }
        : { filterSlug: match.params.activeContactFilterSlug, query: "" };
    return match
      ? `/${match.params.accountSlug}/contact_filters/${filterSlug}${query}`
      : undefined;
  };

  setSavedSearchPath = (savedSearchPath) => {
    this.setState({ savedSearchPath });
  };

  getSearchValue = (location) => {
    const { search } = location;
    const { savedSearchPath } = this.state;
    const { q: urlQuery } = queryString.parse(search);
    const { q: savedSearchQuery } = savedSearchPath
      ? queryString.parse(savedSearchPath.split("?")[1])
      : {};
    return urlQuery || savedSearchQuery;
  };

  handleBackClick = () => {
    const { currentAccount, history } = this.props;
    history.push({
      pathname: `/${currentAccount.slug}/contacts/all`,
    });
  };

  handleClear = () => {
    const { currentAccount, history } = this.props;
    const { search } = this.props.location;
    if (search) {
      history.push({
        pathname: `/${currentAccount.slug}/contacts/search`,
      });
    }
  };

  handleSearch = (q) => {
    const { currentAccount, history } = this.props;
    history.push({
      pathname: `/${currentAccount.slug}/contacts/search`,
      search: `?q=${fixedEncodeURIComponent(q)}`,
    });
  };

  toggleSearch = () => {
    const { currentAccount, history, isSinglePanel, toggleSidebar } =
      this.props;
    const { pathname } = this.props.location;
    const nextPathname = pathname.includes("search")
      ? `/${currentAccount.slug}/contacts/all`
      : `/${currentAccount.slug}/contacts/search`;
    history.push({ pathname: nextPathname });
  };

  renderContactFilter = (props) => {
    const { setContactModal, toggleSidebar, currentAccount } = this.props;

    return (
      <ContactFilter
        {...props}
        contactFilterId={this.getContactFilterIdFromUrl()}
        setContactModal={setContactModal}
        setSavedSearchPath={this.setSavedSearchPath}
        toggleSidebar={toggleSidebar}
        toggleSearch={this.toggleSearch}
      />
    );
  };

  render() {
    const {
      currentAccount,
      isSinglePanel,
      match,
      setContactModal,
      showSidebar,
      toggleSidebar,
    } = this.props;
    const { location } = this.props;
    const { pathname } = location;
    const searchMode = pathname.includes("search");
    const defaultRedirect = get(currentAccount, [
      "settings",
      "contactImportTarget",
      "value",
    ])
      ? "all"
      : "account";

    return (
      <PageWrapper>
        <ReactTouchEvents
          onSwipe={(direction) => {
            return handleSidebarSwipe(direction, toggleSidebar);
          }}
          style={{ zIndex: 101 }}
        >
          <PageSidebar>
            <PageSidebarHeader>
              {currentAccount.contactsEnabled && (
                <PageSidebarHeaderButton
                  ariaLabel="Add Contact Button"
                  dataTestId="add-contact-button"
                  clickHandler={() => {
                    return setContactModal({ active: true, form: true });
                  }}
                  data-product-tour="AddContactButton"
                  icon={<PersonAddIcon fontSize="small" />}
                  primary
                  text="Add Contact"
                />
              )}
            </PageSidebarHeader>
            <ContactFilterCollection
              contactFilterCollectionId={currentAccount.contactFilters}
              match={match}
              toggleSidebar={toggleSidebar}
            />
          </PageSidebar>
        </ReactTouchEvents>
        <ReactTouchEvents
          style={{ zIndex: 101 }}
          onSwipe={(direction) => {
            return handleMainSwipe(direction, toggleSidebar);
          }}
        >
          <PageContent
            isSinglePanel={isSinglePanel}
            showSidebar={showSidebar}
            className="page-content"
          >
            {searchMode && (
              <Box
                position="relative"
                width="100%"
                height={SEARCH_BAR_HEIGHT}
                className="page-content-search-box-wrapper"
              >
                <SearchTooltip
                  title={<ContactSearchInfo />}
                  placement="bottom-start"
                  sidebarOpen={showSidebar}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [-6, -14],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <Box
                    alignItems="center"
                    color="text.secondary"
                    display="flex"
                    height="100%"
                    left="3rem"
                    position="absolute"
                    px={1}
                    style={{ zIndex: 1 }}
                  >
                    <SearchIcon
                      style={{
                        color: "#63676A",
                        cursor: "help",
                      }}
                    />
                  </Box>
                </SearchTooltip>
                <PageSearchHeader
                  autoFocus={!this.props.isSinglePanel}
                  backClickHandler={this.handleBackClick}
                  clearHandler={this.handleClear}
                  localStorageKey="recentContactQueries"
                  placeholder="Search contacts"
                  searchHandler={this.handleSearch}
                  styles={{
                    input: (provided) => {
                      return {
                        ...provided,
                        paddingLeft: "2rem",
                      };
                    },
                    placeholder: (provided) => {
                      return {
                        ...provided,
                        paddingLeft: "2rem",
                      };
                    },
                  }}
                  type="contacts"
                  value={this.getSearchValue(location)}
                />
              </Box>
            )}

            <PageSection
              className="page-section"
              style={
                searchMode
                  ? { height: `calc(100% - ${SEARCH_BAR_HEIGHT})` }
                  : {}
              }
            >
              <Switch location={location}>
                <Route
                  exact
                  path={`${match.path}`}
                  render={() => {
                    return (
                      <Redirect
                        to={`${match.url}/${defaultRedirect}`.replace(
                          "//",
                          "/",
                        )}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path={`${match.path}/search`}
                  render={(props) => {
                    return props.location.search === "" ? (
                      <BlankState
                        className="blank-contacts"
                        image={
                          <img
                            src={SearchGraphicV2}
                            alt="Search"
                            title="Search for Contacts"
                          />
                        }
                        subTitle="You can search by name or number to find an individual contact"
                      />
                    ) : (
                      this.renderContactFilter(props)
                    );
                  }}
                />
                <Route
                  path={`${match.path}`}
                  render={(props) => {
                    return this.renderContactFilter(props);
                  }}
                />
              </Switch>
            </PageSection>
          </PageContent>
        </ReactTouchEvents>
      </PageWrapper>
    );
  }
}

const withConnect = connect(
  () => {
    return {};
  },
  {
    setContactModal: contactModalActionGenerators.setContactModal,
  },
);

export const Component = withSidebar(Contacts);

export default compose(withSidebar, withConnect)(Contacts);
