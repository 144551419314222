import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import styled from "styled-components";
import { SettingsSectionWithSubSections } from "models/SettingsSection";

const ListWrapper = styled("div")(({ theme }) => {
  return {
    borderBottom: "1px solid #e0e0e0",
    "&:last-child": {
      borderBottom: "none",
    },
  };
});

function SectionTitle({
  linkProps,
  title,
  path,
}: {
  linkProps: any;
  path: string;
  title: string;
}) {
  const match = useRouteMatch();

  return (
    <Link
      sx={(theme) => {
        return {
          alignItems: "center",
          color: theme.palette.text.primary,
          display: "flex",
          fontSize: theme.typography.body2.fontSize,
          justifyContent: "space-between",
          padding: "12px 20px",
          width: "100%",
          "&:hover": {
            background: theme.palette.action.hover,
          },
        };
      }}
      component={RouterLink}
      key={title}
      to={`${match.path}/${path}`.replace("//", "/")}
      underline="none"
      {...linkProps}
    >
      {title}
    </Link>
  );
}

export function SettingsSectionList({
  sections,
  activeParentRoute,
  subRoute,
  match,
  hideTitle,
}: {
  sections: SettingsSectionWithSubSections[];
  activeParentRoute: string;
  subRoute: string;
  match: any;
  hideTitle?: boolean;
}) {
  return (
    <>
      {sections.map(({ sectionTitle, subsections }) => {
        return (
          <ListWrapper key={sectionTitle}>
            {!hideTitle && (
              <Typography
                sx={(theme) => {
                  return {
                    color: theme.palette.text.primary,
                    fontSize: theme.typography.body2.fontSize,
                    padding: "12px 20px",
                    fontWeight: "600",
                  };
                }}
              >
                {sectionTitle}
              </Typography>
            )}
            {subsections?.map(({ linkProps, path, settings, title }) => {
              return (
                <div key={path}>
                  {SectionTitle({
                    linkProps,
                    path,
                    title,
                  })}
                  {activeParentRoute === path && (
                    <ul
                      style={{
                        listStyleType: "none",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {settings.map((setting) => {
                        return (
                          <li key={setting.title}>
                            <Link
                              underline="none"
                              relative
                              component={RouterLink}
                              to={`${match.url}/${path}${settings.length > 0 ? setting.to : null}`.replace(
                                "//",
                                "/",
                              )}
                              {...linkProps}
                              sx={(theme) => {
                                return {
                                  alignItems: "center",
                                  color: theme.palette.text.primary,
                                  display: "flex",
                                  fontSize: theme.typography.body2.fontSize,
                                  justifyContent: "space-between",
                                  padding: "12px 50px",
                                  width: "100%",
                                  "&:hover": {
                                    background: theme.palette.action.hover,
                                  },
                                  ...(subRoute ===
                                    setting.to.replace("/", "") && {
                                    background:
                                      theme.palette.selected.secondary,
                                  }),
                                };
                              }}
                            >
                              {setting.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            })}
          </ListWrapper>
        );
      })}
    </>
  );
}
