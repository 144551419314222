import { Alert, Box, Grid2 as Grid } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { format, parseISO } from "date-fns";
import {
  AnalyticsData,
  ContractTerm,
} from "../hooks/useCumulativeBillingAnalytics/types";
import BillingFilter from "./BillingFilter";
import AsOfDateFilter from "features/AccountAnalytics/components/AsOfDateFilter";
import AnalyticsPremiumFeatures from "features/AccountAnalytics/components/AnalyticsPremiumFeatures";
import { cumulativeBillingCards } from "features/AccountAnalytics/constants/CumulativeBillingCards";
import AnalyticsCard from "features/AccountAnalytics/components/AnalyticsCard";

type CumulativeBillingOverviewProps = {
  asOfDate: Date;
  analyticsData: AnalyticsData;
  contractTerms: ContractTerm[];
  getBillingAnalytics: () => void;
  handleSelectContractTerm: (option: ChangeEvent<HTMLInputElement>) => void;
  hasOverage: () => boolean;
  selectedContractTerm: ContractTerm;
  setAsOfDate: Dispatch<SetStateAction<Date>>;
};

function CumulativeBillingOverview({
  asOfDate,
  analyticsData,
  contractTerms,
  getBillingAnalytics,
  handleSelectContractTerm,
  hasOverage,
  selectedContractTerm,
  setAsOfDate,
}: CumulativeBillingOverviewProps) {
  const createContractTermOptions = () => {
    return contractTerms.map((term: ContractTerm) => {
      return term.label;
    });
  };

  const contractTermCardText = `${format(
    analyticsData.termStartDate
      ? parseISO(`${analyticsData.termStartDate}T00:00:00`)
      : new Date(),
    "MMM. dd, y",
  )} - ${format(analyticsData.termEndDate ? parseISO(`${analyticsData.termEndDate}T00:00:00`) : new Date(), "MMM. dd, y")}`;

  const messagesOver = (analyticsData.used - analyticsData.allowance)
    .toLocaleString()
    .replace("-", "");

  const messagesLeft = analyticsData.left
    ? analyticsData.left.toLocaleString()
    : 0;

  const { premiumFeatures }: AnalyticsData = analyticsData;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      pt="32px"
    >
      <Box width={{ xs: "200px" }}>
        <BillingFilter
          label="Contract term"
          menuOptions={createContractTermOptions()}
          selectHandler={handleSelectContractTerm}
          selectedOption={selectedContractTerm?.label ?? "Current term"}
        />
      </Box>
      <AsOfDateFilter
        asOfDate={asOfDate}
        getBillingAnalytics={getBillingAnalytics}
        selectedContractTerm={selectedContractTerm}
        setAsOfDate={setAsOfDate}
      />
      <Box>
        <Grid
          aria-label="Analytics Card Display"
          container
          spacing={2}
          alignContent="center"
        >
          <AnalyticsCard
            key={cumulativeBillingCards.contractTermCard.title}
            analytic={contractTermCardText}
            analyticTitle={cumulativeBillingCards.contractTermCard.title}
            grid={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
            }}
            subText={`${analyticsData.daysLeftInTerm} days left`}
            tooltipText={cumulativeBillingCards.contractTermCard.tooltip}
          />
          <AnalyticsCard
            key={cumulativeBillingCards.allowanceCard.title}
            analytic={analyticsData.allowance}
            analyticTitle={cumulativeBillingCards.allowanceCard.title}
            displayWarning={hasOverage()}
            grid={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            }}
            subText={
              analyticsData
                ? hasOverage()
                  ? `${messagesOver} SMS messages over`
                  : `${messagesLeft} SMS messages left`
                : undefined
            }
            tooltipText={cumulativeBillingCards.allowanceCard.tooltip}
          />
          <AnalyticsCard
            data-testid="messages-sent"
            key={cumulativeBillingCards.usedCard.title}
            analytic={analyticsData.used}
            analyticTitle={cumulativeBillingCards.usedCard.title}
            grid={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            }}
            subText={
              analyticsData.sent
                ? `${analyticsData.sent.toLocaleString()} total messages sent`
                : undefined
            }
            tooltipText={cumulativeBillingCards.usedCard.tooltip}
          />

          {hasOverage() && (
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
              <Alert
                icon={<WarningAmberIcon color="error" />}
                severity="warning"
              >
                {`You used your maximum SMS message allowance as of ${analyticsData.overageDate}. This may not reflect your current allowance as of today.`}
              </Alert>
            </Grid>
          )}

          <AnalyticsPremiumFeatures premiumFeatures={premiumFeatures} />
        </Grid>
      </Box>
    </Box>
  );
}

export { CumulativeBillingOverview };
