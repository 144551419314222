import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/system";

const ExpansionPanelSummary = styled(AccordionSummary)(({ theme }) => {
  return {
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexDirection: "row-reverse",
    paddingRight: "13px",
    paddingLeft: 0,
    marginBottom: -1,
    minHeight: 40,
    height: 40,
    "&.Mui-expanded": {
      minHeight: 40,
      height: 40,
    },
    ".MuiAccordionSummary-content": {
      margin: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      "&.Mui-expanded": {
        margin: "12px 0",
      },
    },
    ".MuiAccordionSummary-expandIcon": {
      padding: "8px",
      right: "10px",
    },
  };
});

ExpansionPanelSummary.muiName = "AccordionSummary";

export default ExpansionPanelSummary;
