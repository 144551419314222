import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import get from "lodash/get";
import { Box, List, Typography } from "@mui/material";
import PaginationLoader from "components/PaginationLoader";
import SavedReplyCollectionItem from "features/SavedReplies/containers/SavedReplyCollectionItem";
import { fetchSavedReplyCollection } from "features/SavedReplies/api";
import { useCurrentAccount } from "hooks";
import { NoMembersScreen } from "components/BlankState";
import { NoResultsScreen } from "components/Search/NoResultsScreen";

function SavedReplyCollection({
  activeSavedReply,
  children,
  hasManageOptions,
  mobileManager = false,
  mobileSettingsManager = false,
  isSearching,
  savedReplies: responsiveSavedReplies,
  savedReplySelectionHandler,
  savedReplyCollectionId,
  searchTerm,
  setActiveSavedReply,
  setSavedReplies,
  disableTemplateAttachments,
}) {
  const { featureFlags } = useCurrentAccount();
  const templatesMaintenance = featureFlags?.templatesMaintenance;
  const showEmptySection =
    responsiveSavedReplies?.members.length === 0 &&
    searchTerm === "" &&
    !activeSavedReply;

  const noSearchResults =
    isSearching && responsiveSavedReplies?.members?.length === 0;

  if (showEmptySection) {
    return templatesMaintenance ? (
      <NoMembersScreen
        source="templates"
        subtitle="Create a new template to get started."
      />
    ) : (
      <Box
        data-testid="template-section"
        height="100%"
        padding="4rem 0rem"
        textAlign="center"
        justifyContent="center"
        width="100%"
      >
        <Typography
          data-testid="no-template"
          variant="h5"
          sx={{
            color: (theme) => {
              return theme.palette.text.secondary;
            },
          }}
        >
          You don&apos;t have any Templates!
        </Typography>
        <Typography
          variant="body1"
          fontSize="0.75rem"
          margin="0.25rem"
          sx={{
            color: (theme) => {
              return theme.palette.text.secondary;
            },
          }}
        >
          {hasManageOptions
            ? "Click on the create button to get started"
            : null}
        </Typography>
      </Box>
    );
  }

  const fetchNextPage = async () => {
    const flaggedFetchFn = fetchSavedReplyCollection;

    try {
      const response = await flaggedFetchFn(responsiveSavedReplies?.view.next);
      const data = await response.json();
      setSavedReplies((prevState) => {
        return {
          ...prevState,
          members: [...prevState.members, ...data.members],
          view: data.view,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoadMore = () => {
    fetchNextPage().catch((error) => {
      console.error(error);
    });
  };

  return (
    <List
      sx={{
        listStyle: "none",
        overflow: "auto",
        width: "100%",
        height: "100%",
        display: noSearchResults ? "flex" : "block",
        justifyContent: noSearchResults ? "center" : "flex-start",
        alignItems: noSearchResults ? "center" : "flex-start",
        overflowX: "hidden",
      }}
    >
      <InfiniteScroll
        hasMore={Boolean(responsiveSavedReplies?.view.next)}
        initialLoad={false}
        loader={<PaginationLoader key="SavedReplyLoader" />}
        loadMore={handleLoadMore}
        threshold={100}
        useWindow={false}
      >
        {typeof children === "function"
          ? children({
              responsiveSavedReplies,
              selectSavedReply: savedReplySelectionHandler,
            })
          : responsiveSavedReplies?.members.map((savedReply) => {
              return (
                <SavedReplyCollectionItem
                  clickHandler={savedReplySelectionHandler}
                  disableTemplateAttachments={disableTemplateAttachments}
                  isActive={savedReply.id === get(activeSavedReply, ["id"])}
                  key={savedReply.id}
                  mobileManager={mobileManager}
                  mobileSettingsManager={mobileSettingsManager}
                  savedReply={savedReply}
                  savedReplyCollectionId={savedReplyCollectionId}
                  setActiveSavedReply={setActiveSavedReply}
                  setSavedReplies={setSavedReplies}
                />
              );
            })}
        {searchTerm &&
        responsiveSavedReplies?.members?.length > 0 &&
        templatesMaintenance ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            padding=".5rem"
          >
            <Typography
              variant="body2"
              sx={{
                color: (theme) => {
                  return theme.palette.text.secondary;
                },
                fontWeight: "medium",
              }}
            >
              All results loaded
            </Typography>
          </Box>
        ) : null}
        {searchTerm && !templatesMaintenance ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            height="50%"
            padding=".5rem"
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: (theme) => {
                  return theme.palette.text.secondary;
                },
                fontWeight: "medium",
              }}
            >
              All results loaded
            </Typography>
          </Box>
        ) : null}
        {isSearching &&
        responsiveSavedReplies?.members?.length === 0 &&
        templatesMaintenance ? (
          <NoResultsScreen source="templates" active padding=".75rem 1rem" />
        ) : null}
      </InfiniteScroll>
    </List>
  );
}

export default SavedReplyCollection;

SavedReplyCollection.propTypes = {
  activeSavedReply: PropTypes.object, // passed
  disableTemplateAttachments: PropTypes.bool, // passed
  hasManageOptions: PropTypes.bool, // passed
  isSearching: PropTypes.bool, // passed
  mobileManager: PropTypes.bool, // passed
  mobileSettingsManager: PropTypes.bool, // passed
  savedReplies: PropTypes.object, // passed
  savedReplySelectionHandler: PropTypes.func.isRequired, // passed
  savedReplyCollectionId: PropTypes.string.isRequired, // passed
  searchTerm: PropTypes.string, // passed
  setActiveSavedReply: PropTypes.func.isRequired, // passed
  setSavedReplies: PropTypes.func.isRequired, // passed
  children: PropTypes.func,
};
