import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Popper,
  Theme,
  Typography,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { SequencesStatsProps, SummaryItem } from "./types";
import InfoOutlineIcon from "icons/InfoOutlineIcon";

function SequenceStats({ individualSequence, isLoading }: SequencesStatsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleHover = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const summaryItems: SummaryItem[] = [
    { title: "Steps", type: "stepCount" },
    { title: "Days", type: "daySpan" },
    { title: "Pending", type: "pendingCount" },
    { title: "All contacts", type: "contactCount" },
    { title: "Engagement rate", percent: true, type: "engagementRate" },
  ];

  return (
    <Box display="flex" flexDirection="row" width="70%" p={3}>
      {summaryItems.map(({ percent, title, type }, index) => {
        const isFirstCard = index === 0;
        const isLastCard = index === summaryItems.length - 1;
        return (
          <Card
            key={title}
            aria-label="sequences-stats"
            square
            variant="outlined"
            sx={{
              padding: "0.5rem",
              gap: "0.5rem",
              width: "13rem",
              height: "7.25rem",
              borderTopLeftRadius: isFirstCard ? "0.25rem" : null,
              borderBottomLeftRadius: isFirstCard ? "0.25rem" : null,
              borderTopRightRadius: isLastCard ? "0.25rem" : null,
              borderBottomRightRadius: isLastCard ? "0.25rem" : null,
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography align="center" color="textSecondary" noWrap>
                {title}
              </Typography>
              {percent ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginLeft="0.2rem"
                  onMouseEnter={handleHover}
                  onMouseLeave={handleHover}
                >
                  <InfoOutlineIcon
                    sx={(theme: Theme) => {
                      return {
                        color: theme.palette.text.secondary,
                        fontSize: theme.typography.body2,
                      };
                    }}
                    data-testid="show-engagement-popover"
                  />
                  <Popper open={open} anchorEl={anchorEl}>
                    <Card sx={{ width: "22.5rem" }}>
                      <CardContent>
                        <Typography variant="body1">
                          <strong>Sequence Engagement Rate:</strong>
                          <br />
                          The percentage of contacts that have replied to a
                          message while enrolled in this sequence.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Popper>
                </Box>
              ) : null}
            </Box>
            {isLoading ? (
              <Box
                display="flex"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                paddingBottom="1rem"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Typography
                align="center"
                color="primary"
                noWrap
                sx={{ fontWeight: 400, fontSize: "3rem" }}
              >
                {percent
                  ? `${individualSequence[type].toLocaleString()}%`
                  : individualSequence[type].toLocaleString()}
              </Typography>
            )}
          </Card>
        );
      })}
    </Box>
  );
}
export { SequenceStats };
