import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { FixedSizeTree as Tree } from "react-vtree";
import { withSize } from "react-sizeme";
import { usePrevious } from "react-use";
import Box from "@mui/material/Box";

import AccountNode from "./AccountNode";
import AccountHeaderRow from "./AccountHeaderRow";
import useTreeWalker from "./useTreeWalker";
import { setCurrentAccount as setCurrentAccountCreator } from "features/MainNavigation/state";
import BlankState from "components/BlankState";
import Logo from "components/Logo";
import withRecord from "higherOrderComponents/withRecord";
import breakpoints from "utils/styles/breakpoints";

function AccountTree({
  accountHierarchyId,
  accountHierarchy,
  brandInformation = false,
  fetchAccountCollectionRequest,
  fetchAccountHierarchyRequest,
  setCurrentAccount,
  size: { height, width },
  children,
  staticHeight = 55,
  itemSize = 48,
  ...props
}) {
  const [noResults, setNoResults] = useState(false);
  const previousNoResults = usePrevious(noResults);
  const [loading, setLoading] = useState(true);
  const [nameOnly, setNameOnly] = useState(
    width === 0 ? false : width < breakpoints.medium,
  );

  const [primaryNodes, setPrimaryNodes] = useState([]);

  const treeWalker = useTreeWalker(
    primaryNodes,
    fetchAccountCollectionRequest,
    nameOnly,
    brandInformation,
    setCurrentAccount,
    setPrimaryNodes,
    fetchAccountHierarchyRequest,
    accountHierarchyId,
  );

  useEffect(() => {
    fetchAccountHierarchyRequest(accountHierarchyId, null, {
      successCallback: () => {
        setLoading(false);
      },
    });
  }, [accountHierarchyId, fetchAccountHierarchyRequest]);

  useEffect(() => {
    if (
      accountHierarchyId === accountHierarchy.id &&
      primaryNodes.length === 0 &&
      !noResults
    ) {
      setNoResults(true);
    }
    if (
      accountHierarchyId === accountHierarchy.id &&
      primaryNodes.length > 0 &&
      noResults
    ) {
      setNoResults(false);
    }
  }, [primaryNodes, accountHierarchy, accountHierarchyId, noResults]);

  useEffect(() => {
    const nodes = accountHierarchy?.members ?? [];
    setPrimaryNodes(nodes);
  }, [accountHierarchy, setPrimaryNodes]);

  useEffect(() => {
    if (width === 0) {
      return undefined;
    }
    if (nameOnly && width >= breakpoints.medium) {
      return setNameOnly(false);
    }
    if (!nameOnly && width < breakpoints.medium) {
      return setNameOnly(true);
    }
    return undefined;
  }, [width, nameOnly]);

  const renderContent = () => {
    if (
      noResults ||
      (accountHierarchyId !== accountHierarchy.id && previousNoResults)
    ) {
      return (
        <BlankState
          image={<Logo color="disabled" />}
          title="No accounts found"
          subTitle="Try another search term"
        />
      );
    }
    if (loading || !treeWalker) {
      return (
        <BlankState
          image={<Logo animate dotColor="primary" color="transparent" />}
        />
      );
    }

    return (
      <>
        {children ? undefined : (
          <AccountHeaderRow
            nameOnly={nameOnly}
            brandInformation={brandInformation}
          />
        )}
        <Tree
          treeWalker={treeWalker}
          itemSize={itemSize}
          height={height - staticHeight}
          width={width}
          async
        >
          {children ?? AccountNode}
        </Tree>
      </>
    );
  };

  return (
    <Box position="relative" height="100%" width="100%" {...props}>
      {renderContent()}
    </Box>
  );
}

const withConnect = connect(
  () => {
    return {};
  },
  {
    setCurrentAccount: setCurrentAccountCreator,
  },
);

AccountTree.propTypes = {
  accountHierarchyId: PropTypes.string.isRequired,
  accountHierarchy: PropTypes.object.isRequired,
  brandInformation: PropTypes.bool,
  fetchAccountCollectionRequest: PropTypes.func.isRequired,
  fetchAccountHierarchyRequest: PropTypes.func.isRequired,
  setCurrentAccount: PropTypes.func.isRequired,
  size: PropTypes.object.isRequired,
  staticHeight: PropTypes.number,
  itemSize: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default compose(
  withConnect,
  withSize({
    monitorHeight: true,
    monitorWidth: true,
  }),
  withRecord({
    actions: ["fetch"],
    container: "pages/AccountManagement/accountHierarchy",
    noFetch: true,
    shape: {},
    showLoader: () => {
      return false;
    },
    type: "accountHierarchy",
  }),
  withRecord({
    actions: ["fetch"],
    container: "pages/AccountManagement/accountCollection",
    noFetch: true,
    shape: {},
    showLoader: () => {
      return false;
    },
    type: "accountCollection",
  }),
)(AccountTree);
