import {
  Box,
  Breadcrumbs,
  Divider,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { IntegrationDetailsActions } from "../IntegrationDetailsActions/IntegrationDetailsActions";
import { IntegrationDetailsFooter } from "../IntegrationDetailsFooter";
import { IntegrationDetailsGeneralTab } from "../IntegrationDetailsGeneralTab";
import { IntegrationDetailsMessageLoggingTab } from "../IntegrationDetailsMessageLoggingTab/IntegrationDetailsMessageLoggingTab";
import { IntegrationDetailsContactsTab } from "../IntegrationDetailsContactsTab";
import { IntegrationDetailsProps } from "./types";
import { TabPanel } from "components/TabPanel";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import PageHeader from "components/Page/PageHeader";
import { IntegrationProvider } from "models/IntegrationProvider";

export function IntegrationDetails({
  allIntegrations,
  selectedIntegration,
  setSelectedIntegration,
  toggleSidebar,
}: IntegrationDetailsProps) {
  const [currentTab, setCurrentTab] = useState(0);

  // Represents the selected integration's updated config and settings values
  const [updatedIntegrationData, setUpdatedIntegrationData] =
    useState(selectedIntegration);

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  // Represents the selected integration's initial config and settings values
  const initialIntegrationValues = Object.freeze(selectedIntegration);

  const getIntegrationState = () => {
    if (selectedIntegration?.provider === "webhook") {
      const webhookConfigState = {
        baseUrl: {
          label: "Webhook URL",
          type: "text",
        },
        webhookSecret: {
          label: "Webhook Secret",
          type: "text",
        },
      };
      return {
        provider: "webhook",
        providerName: "Webhook",
        strategy: "custom",
        config: webhookConfigState,
        settings: {},
      };
    }
    return allIntegrations.find((integration: IntegrationProvider) => {
      const matchingIntegration =
        integration.providerName === selectedIntegration?.providerName;
      return Object.freeze(matchingIntegration);
    });
  };

  // Represents the selected integration's config and settings fields, strategy, and formatted name.
  const integrationState = getIntegrationState();

  const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
    setCurrentTab(newTabValue);
  };

  const generateIntegrationDetailsTabIndexes = () => {
    const tabs = [];
    if (integrationState?.strategy === "custom") {
      tabs.push({
        label: "General",
        value: 0,
        component: (
          <IntegrationDetailsGeneralTab
            initialIntegrationValues={initialIntegrationValues}
            integrationState={integrationState}
            setUpdatedIntegrationData={setUpdatedIntegrationData}
            updatedIntegrationData={updatedIntegrationData}
          />
        ),
      });
    }

    if (integrationState?.settings?.individualNoteLogging) {
      tabs.push({
        label: "Message Logging",
        value: tabs.length,
        component: (
          <IntegrationDetailsMessageLoggingTab
            initialIntegrationValues={initialIntegrationValues}
            integrationState={integrationState}
            setUpdatedIntegrationData={setUpdatedIntegrationData}
            updatedIntegrationData={updatedIntegrationData}
          />
        ),
      });
    }

    if (integrationState?.settings?.contactSync) {
      tabs.push({
        label: "Contacts",
        value: tabs.length,
        component: (
          <IntegrationDetailsContactsTab
            initialIntegrationValues={initialIntegrationValues}
            integrationState={integrationState}
            setUpdatedIntegrationData={setUpdatedIntegrationData}
            updatedIntegrationData={updatedIntegrationData}
          />
        ),
      });
    }

    return tabs;
  };

  const availableTabs = generateIntegrationDetailsTabIndexes();

  return (
    <Box flexDirection="column" height="80%">
      <PageHeader
        aria-label="Integration's Detail Page"
        title={
          <Breadcrumbs>
            <Typography
              onClick={() => {
                return setSelectedIntegration(null);
              }}
              sx={{
                color: "text.secondary",
                fontSize: "1rem",
                lineHeight: "1.5rem",
                textDecoration: "none",
                ":hover": { textDecoration: "underline" },
              }}
            >
              Integrations
            </Typography>
            <Typography
              fontSize="1rem"
              lineHeight="1.5rem"
              color="text.hyperlink"
              fontWeight={800}
            >
              {integrationState?.providerName ?? ""}
            </Typography>
          </Breadcrumbs>
        }
        toggleSidebar={toggleSidebar}
      />
      <Box
        display="flex"
        flexDirection={mobileScreen ? "column" : "row"}
        alignContent="center"
        justifyContent="space-between"
        paddingX="16px"
        paddingTop="16px"
        paddingBottom="8px"
      >
        <Typography
          sx={{
            paddingBottom: mobileScreen ? "8px" : "0",
          }}
          variant="h3"
        >
          {integrationState?.providerName ?? ""}
        </Typography>
        <IntegrationDetailsActions
          setSelectedIntegration={setSelectedIntegration}
          setUpdatedIntegrationData={setUpdatedIntegrationData}
          updatedIntegrationData={updatedIntegrationData}
        />
      </Box>
      <Tabs
        aria-label="General, note logging, and contacts tabs"
        data-testid="IntegrationDetailsTabs"
        indicatorColor="primary"
        onChange={handleTabChange}
        textColor="primary"
        scrollButtons={mobileScreen}
        variant="scrollable"
        allowScrollButtonsMobile
        value={currentTab}
      >
        {availableTabs.map((tab) => {
          return (
            <Tab
              key={tab.value}
              aria-label={`${tab.label}-tab`}
              label={tab.label}
              value={tab.value}
            />
          );
        })}
      </Tabs>
      <Divider />
      <SettingsPageContent
        aria-label="Integration Details"
        maxWidth={mobileScreen ? "90%" : "80%"}
      >
        {availableTabs.map((tab) => {
          return (
            <TabPanel key={tab.value} value={currentTab} index={tab.value}>
              {tab.component}
            </TabPanel>
          );
        })}
      </SettingsPageContent>
      <IntegrationDetailsFooter
        initialIntegrationValues={initialIntegrationValues}
        setSelectedIntegration={setSelectedIntegration}
        updatedIntegrationData={updatedIntegrationData}
      />
    </Box>
  );
}
