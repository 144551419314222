/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-underscore-dangle */
import assert from "assert";
import queryString from "query-string";
import { useState, useEffect, useMemo } from "react";
import { compose } from "redux";
import Box from "@mui/material/Box";
import { useLocation, useHistory } from "react-router";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useSnackbar } from "notistack";
import { Node as AccountNode } from "./components/Node";
import { Search } from "./components/Search";
import { Props } from "./types";
import { dispatcher } from "./dispatcher";
import { Dialog, useDialog } from "components/Dialog";
import withRecord from "higherOrderComponents/withRecord";
import Logo from "components/Logo";
import Loader from "components/Loader";
import AccountTree from "features/AccountManagement/AccountTree";

const Wrapper = styled("div")({
  // Remove the nth-child styling
});

const validResponseCodes = new Set([200]);

function _Accounts({ fetchAccountCollectionRequest }: Props) {
  const { ref: confirmNavigationRef, open: confirm } = useDialog<
    string,
    boolean
  >();

  const history = useHistory();
  const snackbar = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const accountHierarchyId = queryString.stringifyUrl({
    url: "/accounts",
    query: {
      ancestors: true,
      ...queryString.parse(location.search),
    },
  });

  const accountId = useMemo(() => {
    return queryString.stringifyUrl({
      url: "/accounts",
      query: { legal: true },
    });
  }, []);

  useEffect(() => {
    fetchAccountCollectionRequest(accountId, null, {
      successCallback: () => {
        setLoading(false);
      },
    });
  }, [accountHierarchyId, accountId, fetchAccountCollectionRequest]);

  useEffect(() => {
    return dispatcher.subscribe("delete", (promise) => {
      setIsSubmitting(true);

      promise
        .then(async (response) => {
          assert(validResponseCodes.has(response.status));

          const account = await response.json();

          snackbar?.enqueueSnackbar(
            `
              ${account.name ?? "Account"} has been discarded.
            `,
            {
              variant: "success",
            },
          );

          setLoading(true);

          fetchAccountCollectionRequest(accountId, null, {
            successCallback: () => {
              setLoading(false);
            },
          });
        })
        .catch(() => {
          snackbar?.enqueueSnackbar("Failed to discard account.", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    });
  }, [accountId, fetchAccountCollectionRequest, history, snackbar]);

  if (loading) {
    return (
      <Loader isLoading={loading} aria-label="Page is Loading">
        <Logo animate dotColor="contrast" />
      </Loader>
    );
  }

  return (
    <Stack>
      <Box my={2}>
        <Search />
      </Box>

      <Box
        borderColor="divider"
        display="flex"
        flexDirection="row"
        fontSize="body2.fontSize"
        fontWeight="500"
        height="56px"
        py={2}
        pl={2}
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Box
          key="name"
          style={{
            flex: "auto",
            flexGrow: 1,
            flexShrink: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Name
        </Box>
        <Box
          key="numbers"
          style={{
            flex: "204px",
            flexGrow: 0,
            flexShrink: 0,
            textAlign: "left",
          }}
        >
          Phone Numbers
        </Box>
        <Box
          key="children"
          style={{
            flex: "112px",
            flexGrow: 0,
            flexShrink: 0,
            textAlign: "left",
          }}
        >
          Accounts
        </Box>
        <Box
          key="users"
          style={{
            flex: "112px",
            flexGrow: 0,
            flexShrink: 0,
            textAlign: "left",
          }}
        >
          Users
        </Box>
        <Box
          key="manage"
          style={{
            flex: "90px",
            flexGrow: 0,
            flexShrink: 0,
            textAlign: "left",
          }}
        >
          Manage
        </Box>
      </Box>
      <Wrapper sx={{ height: "calc(100vh - 409px)", marginBottom: "-32px" }}>
        <AccountTree
          staticHeight={0}
          itemSize={52}
          accountHierarchyId={accountHierarchyId}
        >
          {(
            parameters: Omit<Parameters<typeof AccountNode>[0], "dispatcher">,
          ) => {
            return (
              <AccountNode
                {...parameters}
                dispatcher={dispatcher}
                confirm={confirm}
              />
            );
          }}
        </AccountTree>
      </Wrapper>
      <Dialog<string, boolean>
        ref={confirmNavigationRef}
        defaultCloseValue={false}
        sx={{
          minWidth: "620px",
        }}
      >
        {({ parameters: name, close }) => {
          return (
            <Stack>
              <DialogTitle display="flex" gap={1} alignItems="center">
                <ErrorOutlineIcon color="error" />
                <Grid>Discard Account</Grid>
              </DialogTitle>
              <DialogContent>
                <Typography
                  variant="body1"
                  fontSize="16px"
                  letterSpacing="0.15px"
                >
                  Discarding this account will release the phone number, remove
                  all user access to this account, and permanently delete the
                  account&apos;s message history. <br />
                  <br />
                  <b>Are you sure you want to discard {name}?</b>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={close(false)}>Cancel</Button>
                <Button variant="contained" onClick={close(true)} autoFocus>
                  Discard
                </Button>
              </DialogActions>
            </Stack>
          );
        }}
      </Dialog>
    </Stack>
  );
}

export const Accounts = compose(
  withRecord({
    actions: ["fetch"],
    container: "features/AccountSettings/BrandInformation/accountCollection",
    noFetch: true,
    shape: {},
    showLoader: () => {
      return false;
    },
    type: "accountCollection",
  }),
)(_Accounts);
