import { Grid2 as Grid, Button, Typography } from "@mui/material";
import People from "@mui/icons-material/People";
import { Link } from "react-router-dom";
import { TeamManagement } from "../TeamManagement";
import type { Props } from "./types";
import { Accounts } from "features/Accounts";

function AccountsComponent({ toggleSidebar }: Props) {
  return (
    <TeamManagement toggleSidebar={toggleSidebar}>
      <Grid
        container
        gridTemplateColumns="auto 1fr"
        justifyContent="space-between"
      >
        <Grid>
          <Typography variant="h6" component="h1" mb={1}>
            Accounts
          </Typography>
        </Grid>
        <Grid>
          <Button
            size="large"
            variant="contained"
            component={Link}
            to="./accounts/create"
            startIcon={<People />}
          >
            Add account
          </Button>
        </Grid>
      </Grid>
      <Accounts />
    </TeamManagement>
  );
}

export { AccountsComponent as Accounts };
