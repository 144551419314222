import { useCallback, useEffect, useState } from "react";
import { compose } from "redux";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Theme,
  Tooltip,
  styled,
  useMediaQuery,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { handleResponse } from "@tesseract/core";
import { GridPaginationModel } from "@mui/x-data-grid-pro";
import { addMinutes, isBefore } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import ABCCampaign from "./ABCCampaign";
import RecurringCampaign from "./RecurringCampaign";
import OneTimeCampaign from "./OneTimeCampaign";
import { CampaignCollection, CampaignProps } from "./types";
import { CancelCampaignDialogue } from "./CancelCampaignDialogue";
import { CloudDownloadIcon, EditIcon, EventBusy } from "icons";
import PageHeader from "components/Page/PageHeader";
import withRecord from "higherOrderComponents/withRecord";
import { useCurrentUser, useLoading } from "hooks";
import { useToggle } from "components/hooks";
import { fetchCampaignCollection } from "features/Campaigns/api";
import { campaignTypes } from "features/Campaigns/constants";

const ButtonWrapper = styled("div")(({ theme }) => {
  return {
    display: "flex",
    color: theme.palette.error.main,
    flex: "1 1 auto",
    justifyContent: "flex-end",
  };
});

export function Root({
  campaign,
  cancelCampaignRequest,
  deleteCampaignRequest,
  exportCampaignRequest,
  currentAccount,
  fetchGroupRequest,
  history,
  location,
  match,
  setCompose,
  toggleSidebar,
}: CampaignProps) {
  // ==== HOOKS ==== //
  const [groupName, setGroupName] = useState("");
  const [instanceCollection, setInstanceCollection] =
    useState<CampaignCollection | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 30,
    page: 0,
  });

  const queryClient = useQueryClient();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });
  const [isCancelCampaignDialogOpen, toggleCancelCampaignDialog] = useToggle();

  const getCampaignCollection = useCallback(
    (campaignId: string) => {
      const controller = new AbortController();

      startLoading();

      fetchCampaignCollection(campaignId, undefined, {
        signal: controller.signal,
      })
        .then(handleResponse)
        .then((data) => {
          return setInstanceCollection(data as CampaignCollection);
        })
        .finally(stopLoading)
        .catch((error_) => {
          enqueueSnackbar("Something went wrong, please try again.", {
            variant: "error",
          });
          console.error(error_);
        });

      return () => {
        controller.abort();
      };
    },
    [enqueueSnackbar, startLoading, stopLoading],
  );

  // ==== METHODS ==== //
  useEffect(() => {
    if (campaign.group) {
      fetchGroupRequest(campaign.group, null, {
        successCallback: ({ name }) => {
          setGroupName(name);
        },
      });
    }

    if (campaign.type === "recurring") {
      getCampaignCollection(`${campaign.id}/recurring_instances`);
    }
  }, [campaign, fetchGroupRequest, getCampaignCollection]);

  const handleCancelClick = () => {
    const redirectUrl = location.pathname.split("/", 4).join("/");
    const successCallback = () => {
      queryClient
        .invalidateQueries({ queryKey: ["campaignsDeliveryStats"] })
        .catch((error_) => {
          console.error(error_);
        });
      enqueueSnackbar(`Campaign successfully canceled.`, {
        variant: "info",
      });
      history.push(redirectUrl);
    };

    if (campaign.delete) {
      return deleteCampaignRequest(campaign.delete, null, {
        successCallback,
      });
    }

    return cancelCampaignRequest(campaign.cancel, null, {
      successCallback,
    });
  };

  const errorCallback = () => {
    enqueueSnackbar(
      "Something went wrong trying to export the data. Please try again.",
      {
        variant: "error",
      },
    );
  };

  const successCallback = () => {
    enqueueSnackbar(
      "Data export successful! Data will be emailed to you shortly.",
      {
        variant: "success",
      },
    );
  };

  const exportData = () => {
    return exportCampaignRequest(`${campaign.id}/export`, null, {
      successCallback,
      errorCallback,
    });
  };

  const handlePageChange = (newPaginationModel: GridPaginationModel) => {
    if (!instanceCollection) {
      return null;
    }

    const {
      view: { next, previous, id },
    } = instanceCollection;

    if (next && newPaginationModel.page > paginationModel.page) {
      setPaginationModel(newPaginationModel);
      return getCampaignCollection(next);
    }

    if (previous && newPaginationModel.page < paginationModel.page) {
      setPaginationModel(newPaginationModel);
      return getCampaignCollection(previous);
    }

    return getCampaignCollection(id);
  };

  const getCampaignStats = () => {
    const { group } = campaign;
    const recurringCampaign = campaign.type === "recurring";

    const abcCampaign = campaign?.campaignTemplates.totalItems > 1;

    if (abcCampaign) {
      return (
        <ABCCampaign
          campaign={campaign}
          currentAccount={currentAccount}
          match={match}
          location={location}
          setCompose={setCompose}
        />
      );
    }
    if (recurringCampaign) {
      return (
        <RecurringCampaign
          campaign={campaign}
          currentAccount={currentAccount}
          group={group}
          groupName={groupName}
          instanceCollection={instanceCollection}
          handlePageChange={handlePageChange}
          isLoading={isLoading}
        />
      );
    }
    return (
      <OneTimeCampaign
        campaign={campaign}
        currentAccount={currentAccount}
        location={location}
        match={match}
        setCompose={setCompose}
      />
    );
  };

  const getWithin30Minutes = () => {
    const thirtyMinutesFromNow = addMinutes(new Date(), 30);
    return campaign.scheduledAt === null
      ? false
      : isBefore(new Date(campaign.scheduledAt), thirtyMinutesFromNow);
  };

  const within30Minutes = getWithin30Minutes();

  const isActiveRecurringCampaign =
    campaign.type === campaignTypes.recurring && campaign.state !== "canceled";
  const isDeletedOrCancelledCampaign = campaign.delete || campaign.cancel;

  const currentUserCreatedCampaign =
    typeof campaign.user !== "string" && currentUser?.id === campaign.user.id;

  const effectiveRoleisAdminOrSupport = ["admin", "support"].includes(
    currentAccount.effectiveRole,
  );

  const currentUserCanCancelCampaign =
    currentUserCreatedCampaign || effectiveRoleisAdminOrSupport;

  // ==== RENDER ==== //
  const renderCancelButton = () => {
    if (isActiveRecurringCampaign || isDeletedOrCancelledCampaign) {
      return (
        <Box>
          {mobileScreen ? (
            <Tooltip title="Cancel campaign">
              <IconButton onClick={toggleCancelCampaignDialog}>
                <EventBusy />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Cancel campaign">
              <ButtonWrapper>
                <Button
                  color="inherit"
                  variant="outlined"
                  onClick={toggleCancelCampaignDialog}
                  data-testid="cancel-campaign"
                >
                  Cancel Campaign
                </Button>
              </ButtonWrapper>
            </Tooltip>
          )}
          {/* ==== DIALOG ==== */}
          <CancelCampaignDialogue
            show={isCancelCampaignDialogOpen}
            onConfirm={handleCancelClick}
            onClose={toggleCancelCampaignDialog}
          />
        </Box>
      );
    }

    return null;
  };

  const renderEditButton = () => {
    if (
      campaign.type !== campaignTypes.recurring &&
      !campaign.sendingStartAt &&
      campaign.state !== "sending" &&
      campaign.state !== "sent" &&
      campaign.state !== "canceled" &&
      !!campaign.campaignTemplates
    ) {
      const url = `/${currentAccount.slug}${campaign.edit}/edit`;
      const title = within30Minutes
        ? "Campaigns in process can no longer be edited"
        : "Edit campaign";
      return mobileScreen ? (
        <Tooltip title={title}>
          <IconButton
            component={Link}
            to={url}
            disabled={within30Minutes || !campaign.edit}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={title}>
          <ButtonWrapper data-product-tour="EditCampaign-Button">
            <Button
              color="primary"
              component={Link}
              variant="outlined"
              to={url}
              disabled={within30Minutes || !campaign.edit}
            >
              Edit Campaign
            </Button>
          </ButtonWrapper>
        </Tooltip>
      );
    }
    return null;
  };

  const renderExportButton = () => {
    return mobileScreen ? (
      <Box pr={1}>
        <Tooltip title="Export data">
          <IconButton onClick={exportData}>
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ) : (
      <Box pr={1}>
        <Button color="primary" variant="outlined" onClick={exportData}>
          Export Data
        </Button>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      fontSize="14px"
      height="100%"
      margin="0 auto"
      color="text.primary"
    >
      <PageHeader title={campaign.title} toggleSidebar={toggleSidebar}>
        <Box display="flex" gap={mobileScreen ? 0 : "0.5rem"}>
          {currentUserCanCancelCampaign && renderCancelButton()}
          {renderEditButton()}
          {campaign.state === "sent" ? renderExportButton() : null}
        </Box>
      </PageHeader>
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 auto"
        margin="0 auto"
        minHeight="calc(100% - 55px)"
        width="100%"
        sx={(theme) => {
          return {
            overflowY: "auto",
            [theme.breakpoints.down("md")]: {
              display: "block",
            },
          };
        }}
      >
        {getCampaignStats()}
      </Box>
    </Box>
  );
}

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "ui/app/features/Campaigns/containers/Campaign/components/group",
    shape: { contacts: {} },
    type: "group",
    noFetch: true,
    showLoader: () => {
      return false;
    },
  }),
  withRecord({
    actions: ["export"],
    container: "ui/app/features/Campaigns/containers/Campaign/export",
    type: "campaign",
  }),
)(Root);
