import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose } from "redux";

import { Button, Grid2 as Grid, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Table from "./Table";
import UserCollectionSearch from "./UserCollectionSearch";
import UserModal from "./UserModal";
import withRecord from "higherOrderComponents/withRecord";

import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import PageHeader from "components/Page/PageHeader";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";

const TableContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid lightgray;

  .MuiTablePagination-spacer {
    flex: 0;
  }

  .MuiTablePagination-root {
    .MuiTablePagination-actions {
      button {
        border: ${(props) => {
          return props.theme.mixins.border({
            color: props.theme.colors.divider,
          });
        }};

        &:last-child {
          margin-left: 7px;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  ${SettingsDescription} {
    margin-bottom: 10px;
  }
`;

function UserManagement({
  createUserRequest,
  deleteUserRequest,
  fetchUserCollectionRequest,
  updateUserRequest,
  userCollection,
  userCollectionId,
  account,
  toggleSidebar,
}) {
  const [activeUser, setActiveUser] = useState(null);
  return (
    <SettingsPageWrapper>
      <PageHeader title="Team Management" toggleSidebar={toggleSidebar} />
      <SettingsPageContent maxWidth="1024px" overflow="auto">
        {/* For creating new users */}
        <UserModal
          activeUser={activeUser}
          createUserRequest={createUserRequest}
          fetchUserCollectionRequest={fetchUserCollectionRequest}
          setActiveUser={setActiveUser}
          userCollectionId="/users"
          updateUserRequest={updateUserRequest}
        />
        <Wrapper>
          <SettingsDescription>
            <Grid container justifyContent="space-between" minWidth={400}>
              <Typography variant="h6">Users</Typography>
              <Button
                color="primary"
                variant="contained"
                aria-label="Add User"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  return setActiveUser({});
                }}
              >
                <PersonIcon /> Add User
              </Button>
            </Grid>
          </SettingsDescription>
          <TableContainer>
            <UserCollectionSearch activeAccount={account} />
            <Table
              deleteUserRequest={deleteUserRequest}
              fetchUserCollectionRequest={fetchUserCollectionRequest}
              setActiveUser={setActiveUser}
              userCollection={userCollection}
              userCollectionId={userCollectionId}
              updateUserRequest={updateUserRequest}
            />
          </TableContainer>
        </Wrapper>
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

UserManagement.propTypes = {
  account: PropTypes.object.isRequired,
  createUserRequest: PropTypes.func.isRequired,
  deleteUserRequest: PropTypes.func.isRequired,
  fetchUserCollectionRequest: PropTypes.func.isRequired,
  updateUserRequest: PropTypes.func.isRequired,
  userCollection: PropTypes.object.isRequired,
  userCollectionId: PropTypes.string.isRequired,
  toggleSidebar: PropTypes.func,
};

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "pages/UserManagement/account",
    type: "account",
    showLoader: () => {
      return false;
    },
    shouldFetch: (props) => {
      return Boolean(props.accountId);
    },
  }),
  withRecord({
    actions: ["update", "delete", "create"],
    container: "pages/UserManagement/users",
    type: "user",
  }),
  withRecord({
    actions: ["fetch"],
    container: "pages/UserManagement/userCollection",
    shape: {
      members: [
        {
          memberships: {
            members: [
              {
                account: {},
              },
            ],
          },
        },
      ],
    },
    showLoader: () => {
      return false;
    },
    type: "userCollection",
  }),
)(UserManagement);
