import { Dialog, DialogProps, Slide } from "@mui/material";
import { ReactElement, Ref, forwardRef, useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { SimpleDialogProps } from "./types";
import { DialogHeader } from "components/DialogHeader";

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);
Transition.displayName = "Transition";

function SimpleDialog({
  children,
  open,
  title,
  handleClose,
  ...props
}: SimpleDialogProps & DialogProps) {
  const [inMotion, setInMotion] = useState(true);

  useEffect(() => {
    if (open) {
      setInMotion(false);
    }
  }, [open, inMotion]);

  const handleCloseWithTransition = () => {
    handleClose();
    setTimeout(() => {
      setInMotion(true);
    }, 1000);
  };

  if (inMotion) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseWithTransition}
      PaperProps={{
        sx: {
          height: "auto",
          maxWidth: "31.25rem", // 500px
          minWidth: "18.75rem", // 300px
          minHeight: "5rem",
        },
      }}
      TransitionComponent={Transition}
      {...props}
    >
      <DialogHeader onClose={handleCloseWithTransition} title={title} />
      {children} {inMotion && "fully closed"}
    </Dialog>
  );
}

export { SimpleDialog };
